<template>
    <div id="app" style="width: 100%;position: fixed;top: 0;z-index: 99999999999999;">
        <!--  data-elementor-type="header" -->
            <!-- :class="{'actieheader':isShow}" -->
        <div style="opacity: .8;" data-elementor-id="261" class="elementor elementor-261 elementor-location-header" data-elementor-post-type="elementor_library">
            <section  class="elementor-section elementor-top-section elementor-element elementor-element-968e5d4 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="968e5d4" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;background_motion_fx_motion_fx_scrolling&quot;:&quot;yes&quot;,&quot;background_motion_fx_opacity_range&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:{&quot;start&quot;:0,&quot;end&quot;:1}},&quot;background_motion_fx_opacity_effect&quot;:&quot;yes&quot;,&quot;background_motion_fx_range&quot;:&quot;page&quot;,&quot;sticky&quot;:&quot;top&quot;,&quot;background_motion_fx_opacity_direction&quot;:&quot;out-in&quot;,&quot;background_motion_fx_opacity_level&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:10,&quot;sizes&quot;:[]},&quot;background_motion_fx_devices&quot;:[&quot;desktop&quot;,&quot;tablet&quot;,&quot;mobile&quot;],&quot;sticky_on&quot;:[&quot;desktop&quot;,&quot;tablet&quot;,&quot;mobile&quot;],&quot;sticky_offset&quot;:0,&quot;sticky_effects_offset&quot;:0,&quot;sticky_anchor_link_offset&quot;:0}">
                <div class="elementor-background-overlay"></div>
                <div class="elementor-container elementor-column-gap-narrow">
                    <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-02bcf69" data-id="02bcf69" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-5d9eb79 elementor-widget-mobile__width-initial elementor-widget elementor-widget-image" data-id="5d9eb79" data-element_type="widget" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <a href="/">
                                        <img width="256" height="60" src="../assets/indeximages/nav_common_logo_white_2x.063bde3.png" class="attachment-full size-full wp-image-25" alt=""/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-85a229b" data-id="85a229b" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-2a1183d elementor-widget__width-auto elementor-widget elementor-widget-shortcode" data-id="2a1183d" data-element_type="widget" data-widget_type="shortcode.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-shortcode">
                                        <div data-elementor-type="section" data-elementor-id="33905" class="elementor elementor-33905 elementor-location-header" data-elementor-post-type="elementor_library">
                                            <section class="elementor-section elementor-top-section elementor-element elementor-element-2547e90 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="2547e90" data-element_type="section">
                                                <div class="elementor-container elementor-column-gap-default">
                                                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b9c4a00" data-id="b9c4a00" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-ea97123 elementor-widget elementor-widget-button" data-id="ea97123" data-element_type="widget" data-widget_type="button.default">
                                                                <div id="headerBtnBox" class="elementor-widget-container">
                                                                    <div  class="elementor-button-wrapper">
                                                                        <a  class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="https://rr.top" target="_blank" rel="nofollow">
                                                                            <span class="elementor-button-content-wrapper">
                                                                                <span class="elementor-button-icon">
                                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                </span>
                                                                                <span class="elementor-button-text">2025 抢百万蛇我其谁</span>
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-d34be01 elementor-view-stacked elementor-shape-square elementor-widget__width-initial elementor-widget-mobile__width-initial elementor-widget elementor-widget-icon" data-id="d34be01" data-element_type="widget" data-widget_type="icon.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-icon-wrapper">
                                        <!-- #elementor-action%3Aaction%3Dpopup%3Aopen%26settings%3DeyJpZCI6IjIzNSIsInRvZ2dsZSI6ZmFsc2V9 -->
                                        <!-- <a class="elementor-icon" href="">
                                            
                                        </a> -->
                                        <i style="font-size: 20px;cursor: pointer;color:#fff" @click="showModel" aria-hidden="true" class="fas fa-bars"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <transition name="slide-left">  
                <div v-if="ismodel" id="modelhandleBox" data-elementor-id="235" class="elementor elementor-235 elementor-location-popup" data-elementor-settings="{&quot;entrance_animation&quot;:&quot;slideInRight&quot;,&quot;exit_animation&quot;:&quot;slideInRight&quot;,&quot;avoid_multiple_popups&quot;:&quot;yes&quot;,&quot;entrance_animation_duration&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0.5,&quot;sizes&quot;:[]},&quot;a11y_navigation&quot;:&quot;yes&quot;,&quot;triggers&quot;:[],&quot;timing&quot;:[]}" data-elementor-post-type="elementor_library">
                    <section class="elementor-section elementor-top-section elementor-element elementor-element-16c9f3ef elementor-section-full_width elementor-section-height-min-height elementor-section-items-stretch elementor-section-content-middle elementor-section-height-default" data-id="16c9f3ef" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div class="elementor-container elementor-column-gap-no">
                            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-0222bbd" data-id="0222bbd" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <div class="elementor-background-overlay">
                                        <img @click="ismodel=false" style="width: 25px;height: 25px;margin-left: 20px;margin-top: 20px;cursor: pointer;" src="../assets/indeximages/clear.png" alt="">
                                    </div>
                                    <div class="elementor-element elementor-element-7b86483 elementor-widget elementor-widget-image" data-id="7b86483" data-element_type="widget" data-widget_type="image.default">
                                        <div class="elementor-widget-container">
                                            <img  width="404" height="238" src="../assets/indeximages/PGLOGO2024.png" class="attachment-large size-large wp-image-26901" alt=""/>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-7237f52 elementor-nav-menu--dropdown-none elementor-widget elementor-widget-nav-menu" data-id="7237f52" data-element_type="widget" data-settings="{&quot;layout&quot;:&quot;vertical&quot;,&quot;submenu_icon&quot;:{&quot;value&quot;:&quot;&lt;i class=\&quot;fas fa-caret-down\&quot;&gt;&lt;\/i&gt;&quot;,&quot;library&quot;:&quot;fa-solid&quot;}}" data-widget_type="nav-menu.default">
                                        <div class="elementor-widget-container">
                                            <nav aria-label="Menu" class="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-vertical e--pointer-text e--animation-float">
                                                <ul id="menu-1-7237f52" class="elementor-nav-menu sm-vertical">
                                                    <li @click.prevent="homeHandle(0)" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-33263 current_page_item menu-item-33614">
                                                        <a href="#" aria-current="page" :class="{'elementor-item-active': activeIndex == 0}" class="elementor-item ">首页</a>
                                                    </li>
                                                    <li @click.prevent="xboxoneHandle(1)" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2091">
                                                        <a href="#" class="elementor-item" :class="{'elementor-item-active': activeIndex == 1}">游戏试玩</a>
                                                    </li>
                                                    <li @click.prevent="brandIntroductionHandle(2)" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-26894">
                                                        <a href="#" class="elementor-item" :class="{'elementor-item-active': activeIndex == 2}">品牌介绍</a>
                                                    </li>
                                                    <li @click.prevent="officialActivitiesHandle(3)" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-143">
                                                        <a href="#" class="elementor-item" :class="{'elementor-item-active': activeIndex == 3}">官方活动</a>
                                                    </li>
                                                    <li @click.prevent="cooperativePartnerHandle(4)" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-26898">
                                                        <a href="#" class="elementor-item" :class="{'elementor-item-active': activeIndex == 4}">合作伙伴</a>
                                                    </li>
                                                    <li @click.prevent="topage(5)" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-26938">
                                                        <a href="/" class="elementor-item" :class="{'elementor-item-active': activeIndex == 5}">动态消息</a>
                                                    </li>
                                                    <li @click.prevent="topage2(6)" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2089">
                                                        <a href="/" class="elementor-item" :class="{'elementor-item-active': activeIndex == 6}">PG APP下载</a>
                                                    </li>
                                                </ul>
                                            </nav>
                                            <nav class="elementor-nav-menu--dropdown elementor-nav-menu__container" aria-hidden="true">
                                                <ul id="menu-2-7237f52" class="elementor-nav-menu sm-vertical">
                                                    <li @click.prevent="homeHandle(0)" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-33263 current_page_item menu-item-33614">
                                                        <a href="https://www.pgsoftgaming.com/" aria-current="page" class="elementor-item " :class="{'elementor-item-active': activeIndex == 0}" tabindex="-1">首页</a>
                                                    </li>
                                                    <li  @click.prevent="xboxoneHandle(1)" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2091">
                                                        <a href="https://www.pgsoftgaming.com/pggame/" :class="{'elementor-item-active': activeIndex == 1}"  class="elementor-item" tabindex="-1">游戏试玩</a>
                                                    </li>
                                                    <li @click.prevent="brandIntroductionHandle(2)" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-26894">
                                                        <a href="https://www.pgsoftgaming.com/brand/" :class="{'elementor-item-active': activeIndex == 2}" class="elementor-item" tabindex="-1">品牌介绍</a>
                                                    </li>
                                                    <li @click.prevent="officialActivitiesHandle(3)" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-143">
                                                        <a href="https://www.pgsoftgaming.com/official/" :class="{'elementor-item-active': activeIndex == 3}" class="elementor-item" tabindex="-1">官方活动</a>
                                                    </li>
                                                    <li @click.prevent="cooperativePartnerHandle(4)" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-26898">
                                                        <a href="https://www.pgsoftgaming.com/partner/" :class="{'elementor-item-active': activeIndex == 4}" class="elementor-item" tabindex="-1">合作伙伴</a>
                                                    </li>
                                                    <li @click.prevent="topage(5)" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-26938">
                                                        <a href="https://www.pgsoftgaming.com/pg-news/" :class="{'elementor-item-active': activeIndex == 5}" class="elementor-item" tabindex="-1">动态消息</a>
                                                    </li>
                                                    <li @click.prevent="topage2(6)" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2089">
                                                        <a href="https://www.pgsoftgaming.com/download/" :class="{'elementor-item-active': activeIndex == 6}" class="elementor-item" tabindex="-1">PG APP下载</a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-2ef4bc6 elementor-widget elementor-widget-shortcode" data-id="2ef4bc6" data-element_type="widget" data-widget_type="shortcode.default">
                                        <div class="elementor-widget-container">
                                            <div class="elementor-shortcode">
                                                <div data-elementor-type="section" data-elementor-id="33905" class="elementor elementor-33905 elementor-location-popup" data-elementor-post-type="elementor_library">
                                                    <section class="elementor-section elementor-top-section elementor-element elementor-element-2547e90 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="2547e90" data-element_type="section">
                                                        <div class="elementor-container elementor-column-gap-default">
                                                            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b9c4a00" data-id="b9c4a00" data-element_type="column">
                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                    <div class="elementor-element elementor-element-ea97123 elementor-widget elementor-widget-button" data-id="ea97123" data-element_type="widget" data-widget_type="button.default">
                                                                        <div  class="elementor-widget-container">
                                                                            <div class="elementor-button-wrapper">
                                                                                <a id="headerBtnBox" class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="https://rr.top" target="_blank" rel="nofollow">
                                                                                    <span class="elementor-button-content-wrapper">
                                                                                        <span class="elementor-button-icon">
                                                                                            <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                        </span>
                                                                                        <span class="elementor-button-text">2025 抢百万蛇我其谁</span>
                                                                                    </span>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
        </transition>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeIndex:0,
            ismodel:false
        }
    },
    mounted() {
        this.activeIndex = localStorage.getItem('activeIndex') || 0;
        // 添加滚动事件监听器
        // window.addEventListener("scroll", this.handleScroll);
        // this.modelHand()
        const scripts = [
            'https://www.pgsoftgaming.com/wp-content/plugins/elementor-pro/assets/js/webpack-pro.runtime.min.js?ver=3.27.3',
            "https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/js/webpack.runtime.min.js?ver=3.27.3",
            "https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/js/frontend-modules.min.js?ver=3.27.3",
            "https://www.pgsoftgaming.com/wp-content/plugins/elementor-pro/assets/js/frontend.min.js?ver=3.27.3",
            "https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/js/frontend.min.js?ver=3.27.3",
            "https://www.pgsoftgaming.com/wp-content/plugins/elementor-pro/assets/js/elements-handlers.min.js?ver=3.27.3"
        ];
        this.loadScripts(scripts, () => {
            console.log('所有脚本加载完成');
        });
        this.$bus.$on('closeModelHandle', () => {
            this.ismodel = false;
        })
    },
    methods: {
        homeHandle(val) {
          
            document.title = "PG电子官方商城 | PG电子官方网站";
            this.$router.push('/')
            this.activeIndex = val;
            localStorage.setItem('activeIndex', val);
        },
        officialActivitiesHandle(val) {
           
            document.title = "PG电子 | PG电子官方网站";
            this.$router.push('/officialActivities')
            this.activeIndex = val;
            localStorage.setItem('activeIndex', val);
        },
        xboxoneHandle(val) {
            this.activeIndex = val;
            document.title = "PG游戏试玩 | PG电子官方网站";
            this.$router.push('/xboxOne')
            localStorage.setItem('activeIndex', val);
        },
        cooperativePartnerHandle(val) {
            this.activeIndex = val;
            document.title = "合作伙伴 | PG电子官方网站";
            this.$router.push('/cooperativePartner')
            localStorage.setItem('activeIndex', val);
        },
        brandIntroductionHandle(val) {
            this.activeIndex = val;
            document.title = "品牌介绍 | PG电子官方网站";
            this.$router.push('/brandIntroduction')
            localStorage.setItem('activeIndex', val);
        },
        topage(val) {
            this.activeIndex = val;
            localStorage.setItem('activeIndex', val);
        },
        topage2(val) {
            this.activeIndex = val;
            localStorage.setItem('activeIndex', val);
        },
        showModel() {
            this.ismodel = true;
        },
        loadScripts(scripts, callback) {
            const loadNext = (index) => {
            if (index >= scripts.length) {
                callback(); // 所有脚本加载完成后执行回调
                return;
            }
            const script = document.createElement('script');
            script.src = scripts[index];
            script.onload = () => loadNext(index + 1); // 加载下一个脚本
            script.onerror = () => console.error(`Failed to load script: ${scripts[index]}`);
            document.body.appendChild(script);
            };
            loadNext(0); // 开始加载第一个脚本
        },
    }
}
</script>

<style scoped lang="less">
@import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/css/frontend.min.css?ver=3.27.3';
@import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor-pro/assets/css/modules/motion-fx.min.css?ver=3.27.3';
@import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/lib/animations/styles/fadeInUp.min.css?ver=3.27.3';
@import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/css/widget-divider.min.css?ver=3.27.3';
@import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/css/widget-spacer.min.css?ver=3.27.3';
@import 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-33263.css?ver=1734676771';
@import 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-261.css?ver=1734676771';
@import 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-269.css?ver=1734676771';
/* // 弹窗样式 */
@import 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-5.css?ver=1734676771';
@import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor-pro/assets/css/widget-nav-menu.min.css?ver=3.27.3';
@import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/lib/animations/styles/slideInRight.min.css?ver=3.27.3';
@import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor-pro/assets/css/conditionals/popup.min.css?ver=3.27.3';
@import 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-235.css?ver=1734676771';
@import 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-33905.css?ver=1736761290';


#menu-1-7237f52 {
        li {
            color: #fff;
            &:first-child {
                color: #7EFDFF;
                font-weight: bold;
            }
            &:hover {
                color: #7EFDFF;
                font-weight: bold;
            }
        }
}
#headerBtnBox {
    transition: transform 0.3s ease; /* 添加过渡效果 */
    &:hover {
        transform: scale(1.1); /* 缩放为原来的 1.1 倍 */
    }
}
#swdtBox,#rejestBox,#modelBox,#bwsBox {
    transition: transform 0.3s ease; /* 添加过渡效果 */
    &:hover {
        transform: scale(1.1); /* 缩放为原来的 1.1 倍 */
    }
}
#modelhandleBox {
    width: 380px;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999999999999;
    overflow: hidden;
}

@media only screen and (max-width: 768px) {
    #modelhandleBox {
    width: 100%;
    
}
}

/* 定义进入和离开的过渡效果 */
.slide-left-enter-active,
.slide-left-leave-active {
  transition: transform 0.5s ease; /* 平滑过渡 */
}

/* 定义进入前的状态 */
.slide-left-enter-from {
  transform: translateX(100%); /* 从右侧完全移出 */
}

/* 定义进入后的状态 */
.slide-left-enter-to {
  transform: translateX(0); /* 移动到正常位置 */
}

/* 定义离开前的状态 */
.slide-left-leave-from {
  transform: translateX(0); /* 正常位置 */
}

/* 定义离开后的状态 */
.slide-left-leave-to {
  transform: translateX(100%); /* 移动到右侧完全移出 */
}
</style>