<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2025-02-17 00:25:48
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2025-02-20 20:38:58
 * @FilePath: \sw_project\src\cooperativePartner\cooperativePartner.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div id="app" class="page-template page-template-elementor_header_footer page page-id-324 wp-custom-logo unselectable elementor-default elementor-template-full-width elementor-kit-5 elementor-page elementor-page-324">
        <Header></Header>
        <div data-elementor-type="wp-page" data-elementor-id="324" class="elementor elementor-324" data-elementor-post-type="page">
            <section class="elementor-section elementor-top-section elementor-element elementor-element-3855602 elementor-section-full_width elementor-section-height-min-height elementor-section-height-default elementor-section-items-middle" data-id="3855602" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;video&quot;,&quot;background_video_link&quot;:&quot;http:\/\/pgsoftgaming.com\/wp-content\/uploads\/2022\/08\/2019_1080p.a0d01cf.mp4&quot;,&quot;background_play_on_mobile&quot;:&quot;yes&quot;}">
                <div class="elementor-background-video-container">
                    <video class="elementor-background-video-hosted" autoplay muted playsinline loop></video>
                </div>
                <div class="elementor-background-overlay"></div>
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3998648" data-id="3998648" data-element_type="column">
                        <div class="elementor-widget-wrap"></div>
                    </div>
                </div>
            </section>
            <section class="elementor-section elementor-top-section elementor-element elementor-element-b9b3fc3 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-invisible" data-id="b9b3fc3" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9eaf27b" data-id="9eaf27b" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-f667aba elementor-widget elementor-widget-spacer" data-id="f667aba" data-element_type="widget" data-widget_type="spacer.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-spacer">
                                        <div class="elementor-spacer-inner"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-a9e2798 elementor-widget elementor-widget-heading" data-id="a9e2798" data-element_type="widget" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h2 class="elementor-heading-title elementor-size-default">我們的合作夥伴</h2>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-498481c elementor-widget elementor-widget-text-editor" data-id="498481c" data-element_type="widget" data-widget_type="text-editor.default">
                                <div class="elementor-widget-container">
                                    <div class="company-desc top">
                                        <p class="desc">
                                            <span>作为手游行业的颠覆者，PG SOFT™除了保持创新、高效敏捷的开发外，还将与专业合作伙伴携手合作，为游戏玩家提供有趣、耳目一新、备受期待的游戏。</span>
                                        </p>
                                    </div>
                                    <div class="company-desc top last">
                                        <p class="desc">
                                            <span>我们致力于追求与行业前 100 家公司的合作。目前，我们已与Relax Gaming、Leander Games、英国知名电子游戏解决方案和服务提供商、世界知名运动品牌188BET以及著名电子游戏运营商BBIN等合作伙伴签署协议。我们致力于在全球范围内寻找顶级合作伙伴，同时遵守欧洲许可和监管机构的监管。同时，我们也期待您的加入！</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-016062b elementor-widget elementor-widget-spacer" data-id="016062b" data-element_type="widget" data-widget_type="spacer.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-spacer">
                                        <div class="elementor-spacer-inner"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="elementor-section elementor-top-section elementor-element elementor-element-1b77e34 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-invisible" data-id="1b77e34" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:200}">
                <div class="elementor-container elementor-column-gap-wider">
                    <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-2660cd0" data-id="2660cd0" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-c121f2d elementor-widget elementor-widget-image" data-id="c121f2d" data-element_type="widget" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img fetchpriority="high" decoding="async" width="440" height="160" src="../assets/cooperativePartnerImg/AnyConv.com__2.webp" class="attachment-large size-large wp-image-403" alt="" sizes="(max-width: 440px) 100vw, 440px"/>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-f18f537 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="f18f537" data-element_type="widget" data-widget_type="icon-list.default">
                                <div class="elementor-widget-container">
                                    <ul class="elementor-icon-list-items">
                                        <li class="elementor-icon-list-item">
                                            <span class="elementor-icon-list-icon">
                                                <i aria-hidden="true" class="fas fa-map-marker-alt"></i>
                                            </span>
                                            <span class="elementor-icon-list-text">菲律宾</span>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                            <span class="elementor-icon-list-icon">
                                                <i aria-hidden="true" class="fas fa-certificate"></i>
                                            </span>
                                            <span class="elementor-icon-list-text">亚洲、美国、英国、菲律宾​</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-90cf483" data-id="90cf483" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-f6f5216 elementor-widget elementor-widget-image" data-id="f6f5216" data-element_type="widget" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img decoding="async" width="440" height="160" src="../assets/cooperativePartnerImg/AnyConv.com__1.webp" class="attachment-large size-large wp-image-404" alt=""  sizes="(max-width: 440px) 100vw, 440px"/>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-6705062 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="6705062" data-element_type="widget" data-widget_type="icon-list.default">
                                <div class="elementor-widget-container">
                                    <ul class="elementor-icon-list-items">
                                        <li class="elementor-icon-list-item">
                                            <span class="elementor-icon-list-icon">
                                                <i aria-hidden="true" class="fas fa-map-marker-alt"></i>
                                            </span>
                                            <span class="elementor-icon-list-text">菲律宾</span>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                            <span class="elementor-icon-list-icon">
                                                <i aria-hidden="true" class="fas fa-certificate"></i>
                                            </span>
                                            <span class="elementor-icon-list-text">亚洲、美国、英国、菲律宾</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-a7f357e" data-id="a7f357e" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-fc11ad7 elementor-widget elementor-widget-image" data-id="fc11ad7" data-element_type="widget" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img loading="lazy" decoding="async" width="440" height="160" src="../assets/cooperativePartnerImg/AnyConv.com__partner_logo_Videoslots_2x.290d706.webp" class="attachment-large size-large wp-image-395" alt=""  sizes="(max-width: 440px) 100vw, 440px"/>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-70dbabc elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="70dbabc" data-element_type="widget" data-widget_type="icon-list.default">
                                <div class="elementor-widget-container">
                                    <ul class="elementor-icon-list-items">
                                        <li class="elementor-icon-list-item">
                                            <span class="elementor-icon-list-icon">
                                                <i aria-hidden="true" class="fas fa-map-marker-alt"></i>
                                            </span>
                                            <span class="elementor-icon-list-text">马耳他</span>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                            <span class="elementor-icon-list-icon">
                                                <i aria-hidden="true" class="fas fa-certificate"></i>
                                            </span>
                                            <span class="elementor-icon-list-text">瑞典、MGA、英国</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="elementor-section elementor-top-section elementor-element elementor-element-6e22f1c elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-invisible" data-id="6e22f1c" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:400}">
                <div class="elementor-container elementor-column-gap-wider">
                    <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-7d974eb" data-id="7d974eb" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-84d246c elementor-widget elementor-widget-image" data-id="84d246c" data-element_type="widget" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img loading="lazy" decoding="async" width="440" height="160" src="../assets/cooperativePartnerImg/AnyConv.com__partner_logo_Casumo_2x.de4d7cf.webp" class="attachment-large size-large wp-image-400" alt="" sizes="(max-width: 440px) 100vw, 440px"/>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-29e3ddc elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="29e3ddc" data-element_type="widget" data-widget_type="icon-list.default">
                                <div class="elementor-widget-container">
                                    <ul class="elementor-icon-list-items">
                                        <li class="elementor-icon-list-item">
                                            <span class="elementor-icon-list-icon">
                                                <i aria-hidden="true" class="fas fa-map-marker-alt"></i>
                                            </span>
                                            <span class="elementor-icon-list-text">马耳他</span>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                            <span class="elementor-icon-list-icon">
                                                <i aria-hidden="true" class="fas fa-certificate"></i>
                                            </span>
                                            <span class="elementor-icon-list-text">UKGC、MGA、瑞典、丹麦</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-1039051" data-id="1039051" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-c2a6a08 elementor-widget elementor-widget-image" data-id="c2a6a08" data-element_type="widget" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img loading="lazy" decoding="async" width="440" height="160" src="../assets/cooperativePartnerImg/AnyConv.com__partner_logo_Betsson_2x.484674d.webp" class="attachment-large size-large wp-image-401" alt=""  sizes="(max-width: 440px) 100vw, 440px"/>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-69c8072 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="69c8072" data-element_type="widget" data-widget_type="icon-list.default">
                                <div class="elementor-widget-container">
                                    <ul class="elementor-icon-list-items">
                                        <li class="elementor-icon-list-item">
                                            <span class="elementor-icon-list-icon">
                                                <i aria-hidden="true" class="fas fa-map-marker-alt"></i>
                                            </span>
                                            <span class="elementor-icon-list-text">斯德哥尔摩</span>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                            <span class="elementor-icon-list-icon">
                                                <i aria-hidden="true" class="fas fa-certificate"></i>
                                            </span>
                                            <span class="elementor-icon-list-text">MGA</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-eff956d" data-id="eff956d" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-f5195d1 elementor-widget elementor-widget-image" data-id="f5195d1" data-element_type="widget" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img loading="lazy" decoding="async" width="440" height="160" src="../assets/cooperativePartnerImg/AnyConv.com__partner_logo_GVC_2x.a20b0e8.webp" class="attachment-large size-large wp-image-397" alt=""  sizes="(max-width: 440px) 100vw, 440px"/>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-13add18 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="13add18" data-element_type="widget" data-widget_type="icon-list.default">
                                <div class="elementor-widget-container">
                                    <ul class="elementor-icon-list-items">
                                        <li class="elementor-icon-list-item">
                                            <span class="elementor-icon-list-icon">
                                                <i aria-hidden="true" class="fas fa-map-marker-alt"></i>
                                            </span>
                                            <span class="elementor-icon-list-text">马恩岛</span>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                            <span class="elementor-icon-list-icon">
                                                <i aria-hidden="true" class="fas fa-certificate"></i>
                                            </span>
                                            <span class="elementor-icon-list-text">UKGC</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="elementor-section elementor-top-section elementor-element elementor-element-ef4eb2e elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-invisible" data-id="ef4eb2e" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:200}">
                <div class="elementor-container elementor-column-gap-wider">
                    <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-76f0d36" data-id="76f0d36" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-5af34c2 elementor-widget elementor-widget-image" data-id="5af34c2" data-element_type="widget" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img loading="lazy" decoding="async" width="440" height="160" src="../assets/cooperativePartnerImg/AnyConv.com__partner_logo_leovegas_2x.4f4a5f7.webp" class="attachment-large size-large wp-image-393" alt=""  sizes="(max-width: 440px) 100vw, 440px"/>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-d8d1629 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="d8d1629" data-element_type="widget" data-widget_type="icon-list.default">
                                <div class="elementor-widget-container">
                                    <ul class="elementor-icon-list-items">
                                        <li class="elementor-icon-list-item">
                                            <span class="elementor-icon-list-icon">
                                                <i aria-hidden="true" class="fas fa-map-marker-alt"></i>
                                            </span>
                                            <span class="elementor-icon-list-text">斯德哥尔摩</span>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                            <span class="elementor-icon-list-icon">
                                                <i aria-hidden="true" class="fas fa-certificate"></i>
                                            </span>
                                            <span class="elementor-icon-list-text">UK-SERIES、MGA</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-0d57086" data-id="0d57086" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-b89f90b elementor-widget elementor-widget-image" data-id="b89f90b" data-element_type="widget" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img loading="lazy" decoding="async" width="440" height="160" src="../assets/cooperativePartnerImg/AnyConv.com__partner_logo_Interwetten_2x.eb5ebcc.webp" class="attachment-large size-large wp-image-396" alt=""  sizes="(max-width: 440px) 100vw, 440px"/>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-5b7077e elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="5b7077e" data-element_type="widget" data-widget_type="icon-list.default">
                                <div class="elementor-widget-container">
                                    <ul class="elementor-icon-list-items">
                                        <li class="elementor-icon-list-item">
                                            <span class="elementor-icon-list-icon">
                                                <i aria-hidden="true" class="fas fa-map-marker-alt"></i>
                                            </span>
                                            <span class="elementor-icon-list-text">马耳他</span>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                            <span class="elementor-icon-list-icon">
                                                <i aria-hidden="true" class="fas fa-certificate"></i>
                                            </span>
                                            <span class="elementor-icon-list-text">瑞典、丹麦、罗马尼亚</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-95ac20d" data-id="95ac20d" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-40d2eca elementor-widget elementor-widget-image" data-id="40d2eca" data-element_type="widget" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img loading="lazy" decoding="async" width="440" height="160" src="../assets/cooperativePartnerImg/AnyConv.com__partner_logo_bethard_2x.b2a14ad.webp" class="attachment-large size-large wp-image-402" alt=""  sizes="(max-width: 440px) 100vw, 440px"/>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-b415148 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="b415148" data-element_type="widget" data-widget_type="icon-list.default">
                                <div class="elementor-widget-container">
                                    <ul class="elementor-icon-list-items">
                                        <li class="elementor-icon-list-item">
                                            <span class="elementor-icon-list-icon">
                                                <i aria-hidden="true" class="fas fa-map-marker-alt"></i>
                                            </span>
                                            <span class="elementor-icon-list-text">马耳他</span>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                            <span class="elementor-icon-list-icon">
                                                <i aria-hidden="true" class="fas fa-certificate"></i>
                                            </span>
                                            <span class="elementor-icon-list-text">瑞典、西班牙、英国、丹麦</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="elementor-section elementor-top-section elementor-element elementor-element-b844ebd elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-invisible" data-id="b844ebd" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:200}">
                <div class="elementor-container elementor-column-gap-wider">
                    <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-653e141" data-id="653e141" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-b73dcc2 elementor-widget elementor-widget-image" data-id="b73dcc2" data-element_type="widget" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img loading="lazy" decoding="async" width="440" height="160" src="../assets/cooperativePartnerImg/AnyConv.com__partner_logo_comeon_2x.6eae9a5.webp" class="attachment-large size-large wp-image-399" alt=""  sizes="(max-width: 440px) 100vw, 440px"/>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-c612671 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="c612671" data-element_type="widget" data-widget_type="icon-list.default">
                                <div class="elementor-widget-container">
                                    <ul class="elementor-icon-list-items">
                                        <li class="elementor-icon-list-item">
                                            <span class="elementor-icon-list-icon">
                                                <i aria-hidden="true" class="fas fa-map-marker-alt"></i>
                                            </span>
                                            <span class="elementor-icon-list-text">马耳他</span>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                            <span class="elementor-icon-list-icon">
                                                <i aria-hidden="true" class="fas fa-certificate"></i>
                                            </span>
                                            <span class="elementor-icon-list-text">马耳他、瑞典、丹麦</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-0e2823c" data-id="0e2823c" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-0d88784 elementor-widget elementor-widget-image" data-id="0d88784" data-element_type="widget" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img loading="lazy" decoding="async" width="440" height="160" src="../assets/cooperativePartnerImg/AnyConv.com__partner_logo_RelaxGamingGroup_2x.6a9a806.webp" class="attachment-large size-large wp-image-392" alt="" sizes="(max-width: 440px) 100vw, 440px"/>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-4b2ff7f elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="4b2ff7f" data-element_type="widget" data-widget_type="icon-list.default">
                                <div class="elementor-widget-container">
                                    <ul class="elementor-icon-list-items">
                                        <li class="elementor-icon-list-item">
                                            <span class="elementor-icon-list-icon">
                                                <i aria-hidden="true" class="fas fa-map-marker-alt"></i>
                                            </span>
                                            <span class="elementor-icon-list-text">马耳他</span>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                            <span class="elementor-icon-list-icon">
                                                <i aria-hidden="true" class="fas fa-certificate"></i>
                                            </span>
                                            <span class="elementor-icon-list-text">英国、马耳他、奥尼德尼</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-4b106f7" data-id="4b106f7" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-519066e elementor-widget elementor-widget-image" data-id="519066e" data-element_type="widget" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img loading="lazy" decoding="async" width="440" height="160" src="../assets/cooperativePartnerImg/AnyConv.com__partner_logo_Leander_2x.80d0f0d.webp" class="attachment-large size-large wp-image-394" alt=""  sizes="(max-width: 440px) 100vw, 440px"/>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-25a0546 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="25a0546" data-element_type="widget" data-widget_type="icon-list.default">
                                <div class="elementor-widget-container">
                                    <ul class="elementor-icon-list-items">
                                        <li class="elementor-icon-list-item">
                                            <span class="elementor-icon-list-icon">
                                                <i aria-hidden="true" class="fas fa-map-marker-alt"></i>
                                            </span>
                                            <span class="elementor-icon-list-text">英国</span>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                            <span class="elementor-icon-list-icon">
                                                <i aria-hidden="true" class="fas fa-certificate"></i>
                                            </span>
                                            <span class="elementor-icon-list-text">英国、奥尼德尼</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="elementor-section elementor-top-section elementor-element elementor-element-42d90be elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-invisible" data-id="42d90be" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:400}">
                <div class="elementor-container elementor-column-gap-wider">
                    <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-f48b319" data-id="f48b319" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-e141e44 elementor-widget elementor-widget-image" data-id="e141e44" data-element_type="widget" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img loading="lazy" decoding="async" width="440" height="160" src="../assets/cooperativePartnerImg/AnyConv.com__partner_logo_softgamings_2x.ba7bc64.webp" class="attachment-large size-large wp-image-391" alt=""  sizes="(max-width: 440px) 100vw, 440px"/>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-5a3ace5 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="5a3ace5" data-element_type="widget" data-widget_type="icon-list.default">
                                <div class="elementor-widget-container">
                                    <ul class="elementor-icon-list-items">
                                        <li class="elementor-icon-list-item">
                                            <span class="elementor-icon-list-icon">
                                                <i aria-hidden="true" class="fas fa-map-marker-alt"></i>
                                            </span>
                                            <span class="elementor-icon-list-text">马耳他</span>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                            <span class="elementor-icon-list-icon">
                                                <i aria-hidden="true" class="fas fa-certificate"></i>
                                            </span>
                                            <span class="elementor-icon-list-text">MGA</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-8a9cf85" data-id="8a9cf85" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-5ea9ae6 elementor-widget elementor-widget-image" data-id="5ea9ae6" data-element_type="widget" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img loading="lazy" decoding="async" width="440" height="160" src="../assets/cooperativePartnerImg/AnyConv.com__partner_logo_everymatrix_2x.de121ab.webp" class="attachment-large size-large wp-image-398" alt=""  sizes="(max-width: 440px) 100vw, 440px"/>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-8ecfde5 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="8ecfde5" data-element_type="widget" data-widget_type="icon-list.default">
                                <div class="elementor-widget-container">
                                    <ul class="elementor-icon-list-items">
                                        <li class="elementor-icon-list-item">
                                            <span class="elementor-icon-list-icon">
                                                <i aria-hidden="true" class="fas fa-map-marker-alt"></i>
                                            </span>
                                            <span class="elementor-icon-list-text">马耳他</span>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                            <span class="elementor-icon-list-icon">
                                                <i aria-hidden="true" class="fas fa-certificate"></i>
                                            </span>
                                            <span class="elementor-icon-list-text">英国、MGA</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-eb0f789" data-id="eb0f789" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-b43af79 elementor-widget elementor-widget-image" data-id="b43af79" data-element_type="widget" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img loading="lazy" decoding="async" width="440" height="160" src="../assets/cooperativePartnerImg/AnyConv.com__partner_logo_softswiss_2x.78d6a9f.webp" class="attachment-large size-large wp-image-390" alt=""  sizes="(max-width: 440px) 100vw, 440px"/>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-deb69b0 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="deb69b0" data-element_type="widget" data-widget_type="icon-list.default">
                                <div class="elementor-widget-container">
                                    <ul class="elementor-icon-list-items">
                                        <li class="elementor-icon-list-item">
                                            <span class="elementor-icon-list-icon">
                                                <i aria-hidden="true" class="fas fa-map-marker-alt"></i>
                                            </span>
                                            <span class="elementor-icon-list-text">威廉斯塔德</span>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                            <span class="elementor-icon-list-icon">
                                                <i aria-hidden="true" class="fas fa-certificate"></i>
                                            </span>
                                            <span class="elementor-icon-list-text">库拉索、马耳他、爱沙尼亚</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Footer from "../components/footer.vue";
import Header from "../components/header.vue";
var elementorFrontendConfig = {
                "environmentMode": {
                    "edit": false,
                    "wpPreview": false,
                    "isScriptDebug": false
                },
                "i18n": {
                    "shareOnFacebook": "\u5728\u8138\u4e66\u4e0a\u5206\u4eab",
                    "shareOnTwitter": "\u5206\u4eab\u5230Twitter",
                    "pinIt": "\u9489\u4f4f",
                    "download": "\u4e0b\u8f7d",
                    "downloadImage": "\u4e0b\u8f7d\u56fe\u7247",
                    "fullscreen": "\u5168\u5c4f",
                    "zoom": "\u7f29\u653e",
                    "share": "\u5206\u4eab",
                    "playVideo": "\u64ad\u653e\u89c6\u9891",
                    "previous": "\u4e0a\u4e00\u9875",
                    "next": "\u4e0b\u4e00\u9875",
                    "close": "\u5173\u95ed",
                    "a11yCarouselPrevSlideMessage": "\u4e0a\u4e00\u5f20\u5e7b\u706f\u7247",
                    "a11yCarouselNextSlideMessage": "\u4e0b\u4e00\u5f20\u5e7b\u706f\u7247",
                    "a11yCarouselFirstSlideMessage": "\u8fd9\u662f\u7b2c\u4e00\u5f20\u5e7b\u706f\u7247",
                    "a11yCarouselLastSlideMessage": "\u8fd9\u662f\u6700\u540e\u4e00\u5f20\u5e7b\u706f\u7247",
                    "a11yCarouselPaginationBulletMessage": "\u8f6c\u5230\u5e7b\u706f\u7247"
                },
                "is_rtl": false,
                "breakpoints": {
                    "xs": 0,
                    "sm": 480,
                    "md": 768,
                    "lg": 1025,
                    "xl": 1440,
                    "xxl": 1600
                },
                "responsive": {
                    "breakpoints": {
                        "mobile": {
                            "label": "\u624b\u673a\u7eb5\u5411",
                            "value": 767,
                            "default_value": 767,
                            "direction": "max",
                            "is_enabled": true
                        },
                        "mobile_extra": {
                            "label": "\u624b\u673a\u6a2a\u5411",
                            "value": 880,
                            "default_value": 880,
                            "direction": "max",
                            "is_enabled": false
                        },
                        "tablet": {
                            "label": "\u5e73\u677f\u7535\u8111\u7eb5\u5411",
                            "value": 1024,
                            "default_value": 1024,
                            "direction": "max",
                            "is_enabled": true
                        },
                        "tablet_extra": {
                            "label": "\u5e73\u677f\u7535\u8111\u6a2a\u5411",
                            "value": 1200,
                            "default_value": 1200,
                            "direction": "max",
                            "is_enabled": false
                        },
                        "laptop": {
                            "label": "\u7b14\u8bb0\u672c\u7535\u8111",
                            "value": 1366,
                            "default_value": 1366,
                            "direction": "max",
                            "is_enabled": false
                        },
                        "widescreen": {
                            "label": "\u5168\u5bbd\u5c4f",
                            "value": 2400,
                            "default_value": 2400,
                            "direction": "min",
                            "is_enabled": false
                        }
                    },
                    "hasCustomBreakpoints": false
                },
                "version": "3.27.4",
                "is_static": false,
                "experimentalFeatures": {
                    "additional_custom_breakpoints": true,
                    "e_swiper_latest": true,
                    "e_onboarding": true,
                    "theme_builder_v2": true,
                    "hello-theme-header-footer": true,
                    "home_screen": true,
                    "landing-pages": true,
                    "editor_v2": true,
                    "link-in-bio": true,
                    "floating-buttons": true
                },
                "urls": {
                    "assets": "https:\/\/www.pgsoftgaming.com\/wp-content\/plugins\/elementor\/assets\/",
                    "ajaxurl": "https:\/\/www.pgsoftgaming.com\/wp-admin\/admin-ajax.php",
                    "uploadUrl": "http:\/\/www.pgsoftgaming.com\/wp-content\/uploads"
                },
                "nonces": {
                    "floatingButtonsClickTracking": "315042b429"
                },
                "swiperClass": "swiper",
                "settings": {
                    "page": [],
                    "editorPreferences": []
                },
                "kit": {
                    "body_background_background": "classic",
                    "active_breakpoints": ["viewport_mobile", "viewport_tablet"],
                    "global_image_lightbox": "yes",
                    "lightbox_enable_counter": "yes",
                    "lightbox_enable_fullscreen": "yes",
                    "lightbox_enable_zoom": "yes",
                    "lightbox_enable_share": "yes",
                    "lightbox_title_src": "title",
                    "lightbox_description_src": "description",
                    "hello_header_logo_type": "logo",
                    "hello_header_menu_layout": "horizontal",
                    "hello_footer_logo_type": "logo"
                },
                "post": {
                    "id": 324,
                    "title": "%E5%90%88%E4%BD%9C%E4%BC%99%E4%BC%B4%20%7C%20PG%E7%94%B5%E5%AD%90%E5%AE%98%E6%96%B9%E7%BD%91%E7%AB%99",
                    "excerpt": "",
                    "featuredImage": false
                }
            };
       
export default {
    data() {
        return {

        }
    },
    components: {
        Footer,
        Header
    },
    mounted() {
        const scripts = [
            // 'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/js/webpack.runtime.min.js?ver=3.27.4',
            "./js/webpack.runtime.min.js",
            // "https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/js/frontend-modules.min.js?ver=3.27.4",
            "./js/frontend-modules.min.js",
            // "https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/js/frontend.min.js?ver=3.27.4",
            "./js/frontend.min.js"
        ];
        this.loadScripts(scripts, () => {
            console.log('所有脚本加载完成');
            this.loadCSS('./css/post-33905.css',false)
        });

    },
    methods: {
        loadScripts(scripts, callback) {
            const loadNext = (index) => {
            if (index >= scripts.length) {
                callback(); // 所有脚本加载完成后执行回调
                return;
            }
            const script = document.createElement('script');
            script.src = scripts[index];
            script.onload = () => loadNext(index + 1); // 加载下一个脚本
            script.onerror = () => console.error(`Failed to load script: ${scripts[index]}`);
            document.body.appendChild(script);
            };
            loadNext(0); // 开始加载第一个脚本
        }
    }    
}
</script>

<style lang="less" scoped>
// @import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/css/frontend.min.css?ver=3.27.4';
@import "./css/frontend.min.css";
// @import 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-5.css?ver=1734676771';
@import "./css/post-5.css";
// @import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/lib/animations/styles/fadeInUp.min.css?ver=3.27.4';
@import "./css/fadeInUp.min.css";
// @import 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-324.css?ver=1734677737';
@import "./css/post-324.css";

</style>
