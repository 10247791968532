<!--
 * @Author: 亦云 yiyundev@163.com
 * @Date: 2025-02-18 09:50:39
 * @LastEditTime: 2025-02-26 00:32:30
 * @FilePath: \sw_project\src\xboxOne\xboxOne.vue
 * @Description:                                 
-->
<template>
    <div id="app" class="page-template page-template-elementor_header_footer page page-id-349 wp-custom-logo unselectable elementor-default elementor-template-full-width elementor-kit-5 elementor-page elementor-page-349">
        <Header></Header>
        <div data-elementor-type="wp-page" data-elementor-id="349" class="elementor elementor-349" data-elementor-post-type="page">
            <section style="position:relative;" class="elementor-section elementor-top-section elementor-element elementor-element-228cd5c3 elementor-section-height-min-height elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-items-middle" data-id="228cd5c3" data-element_type="section" >
                <div style="position:absolute;top:0;left:0;" class="elementor-background-overlay" >
                    <div style="position:absolute;top:0;left:0;width:100%;height:83vh;background-color: transparent;
    background-image: linear-gradient(rgba(140, 0, 197, 0.61) 0%, rgb(35, 0, 70) 100%);
    opacity: 100;z-index:99"></div>
                    <el-carousel :interval="7000" indicator-position="none" :loop="true" height="83vh" direction="vertical" :autoplay="true">
                        <el-carousel-item>
                            <img id="imgBox" style="width:100%;height:100%;"  src="../assets/xboxOne/sweper-1.jpg" />
                        </el-carousel-item>
                        <el-carousel-item>
                            <img id="imgBox" style="width:100%;height:100%;" src="../assets/xboxOne/sweper-2.webp" />
                        </el-carousel-item>
                        <el-carousel-item>
                            <img id="imgBox" style="width:100%;height:100%;" src="../assets/xboxOne/sweper-3.jpg" />
                        </el-carousel-item>
                        <el-carousel-item>
                            <img id="imgBox" style="width:100%;height:100%;" src="../assets/xboxOne/sweper-4.jpg" />
                        </el-carousel-item>
                        <el-carousel-item>
                            <img id="imgBox" style="width:100%;height:100%;" src="../assets/xboxOne/sweper-5.jpg" />
                        </el-carousel-item>
                        <el-carousel-item>
                            <img id="imgBox" style="width:100%;height:100%;" src="../assets/xboxOne/sweper-6.jpg" />
                        </el-carousel-item>
                        <el-carousel-item>
                            <img id="imgBox" style="width:100%;height:100%;" src="../assets/xboxOne/sweper-7.jpg" />
                        </el-carousel-item>
                        <el-carousel-item>
                            <img id="imgBox" style="width:100%;height:100%;" src="../assets/xboxOne/sweper-8.jpg" />
                        </el-carousel-item>
                        <el-carousel-item>
                            <img id="imgBox" style="width:100%;height:100%;" src="../assets/xboxOne/sweper-9.webp" />
                        </el-carousel-item>
                        <el-carousel-item>
                            <img id="imgBox" style="width:100%;height:100%;" src="../assets/xboxOne/sweper-1.jpg" />
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="elementor-container elementor-column-gap-default" style="z-index:999999;">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-66d2cb4d" data-id="66d2cb4d" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-1a464ce elementor-invisible elementor-widget elementor-widget-image" data-id="1a464ce" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;}" data-widget_type="image.default">
                                    <div class="elementor-widget-container">
                                        <img decoding="async" width="301" height="26" src="../assets/xboxOne/pg遊戲試玩pgapp.cc.png" class="attachment-large size-large wp-image-34819" alt="pg遊戲試玩pgapp.cc"/>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-688aaeeb elementor-widget__width-auto elementor-invisible elementor-widget elementor-widget-heading" data-id="688aaeeb" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;,&quot;_animation_delay&quot;:200}" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <h2 class="elementor-heading-title elementor-size-default">PG电子游戏试玩大厅</h2>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-51801fe3 elementor-widget__width-initial elementor-invisible elementor-widget elementor-widget-text-editor" data-id="51801fe3" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;,&quot;_animation_delay&quot;:400}" data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">欢迎进入2024最新PG电子游戏试玩大厅，尽请体验Pocket Games所精心设计的视听效果，来场充满惊喜的享宴！								</div>
                                </div>
                                <div class="elementor-element elementor-element-03dd2d5 elementor-widget elementor-widget-shortcode" data-id="03dd2d5" data-element_type="widget" data-widget_type="shortcode.default">
                                    <div class="elementor-widget-container">
                                        <div class="elementor-shortcode">
                                            <div data-elementor-type="section" data-elementor-id="33905" class="elementor elementor-33905" data-elementor-post-type="elementor_library">
                                                <section class="elementor-section elementor-top-section elementor-element elementor-element-2547e90 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="2547e90" data-element_type="section">
                                                    <div class="elementor-container elementor-column-gap-default">
                                                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b9c4a00" data-id="b9c4a00" data-element_type="column">
                                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                                <div class="elementor-element elementor-element-ea97123 elementor-widget elementor-widget-button" data-id="ea97123" data-element_type="widget" data-widget_type="button.default">
                                                                    <div class="elementor-widget-container">
                                                                        <div class="elementor-button-wrapper">
                                                                            <a class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="https://rr.top" target="_blank" rel="nofollow">
                                                                                <span class="elementor-button-content-wrapper">
                                                                                    <span class="elementor-button-icon">
                                                                                        <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                    </span>
                                                                                    <span class="elementor-button-text">2025 抢百万蛇我其谁</span>
                                                                                </span>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                
            </section>
            <section class="elementor-section elementor-top-section elementor-element elementor-element-e7e4d07 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="e7e4d07" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-background-overlay"></div>
                <div class="elementor-container elementor-column-gap-no">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-83c605a" data-id="83c605a" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-f359ec1 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="f359ec1" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-559fac1" data-id="559fac1" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-6370318 elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="6370318" data-element_type="widget" data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h4 class="elementor-heading-title elementor-size-default">Games Demo</h4>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-4d78faf elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="4d78faf" data-element_type="widget" data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">热门游戏</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-a409c71" data-id="a409c71" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-ce760a4 elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="ce760a4" data-element_type="widget" data-settings="{&quot;motion_fx_motion_fx_scrolling&quot;:&quot;yes&quot;,&quot;motion_fx_scale_effect&quot;:&quot;yes&quot;,&quot;motion_fx_scale_direction&quot;:&quot;in-out&quot;,&quot;motion_fx_scale_speed&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;-10&quot;,&quot;sizes&quot;:[]},&quot;motion_fx_scale_range&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:{&quot;start&quot;:&quot;0&quot;,&quot;end&quot;:&quot;50&quot;}},&quot;motion_fx_devices&quot;:[&quot;desktop&quot;,&quot;tablet&quot;,&quot;mobile&quot;]}" data-widget_type="divider.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-divider">
                                                        <span class="elementor-divider-separator"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-ec5f723 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="ec5f723" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-wide">
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-d251879 elementor-invisible" data-id="d251879" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-a211ab2 elementor-widget elementor-widget-spacer" data-id="a211ab2" data-element_type="widget" data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-89e261e elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="89e261e" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-27897c1" data-id="27897c1" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-3587853 elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="3587853" data-element_type="widget" data-widget_type="image.default">
                                                                <div class="elementor-widget-container">
                                                                    <img decoding="async" width="186" height="186" src="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x.webp" class="attachment-full size-full wp-image-101" alt="" srcset="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x.webp 186w, https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-150x150.webp 150w" sizes="(max-width: 186px) 100vw, 186px"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-8e30653" data-id="8e30653" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-9eb5d64 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="9eb5d64" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p>
                                                                        <span style="vertical-align: inherit;">
                                                                            <span style="vertical-align: inherit;">
                                                                                <strong>麻将胡了</strong>
                                                                                <br/>
                                                                                麻将三缺一，等您来开杠！<br/>
                                                                            </span>
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <div class="elementor-element elementor-element-34bbf2b elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="34bbf2b" data-element_type="widget" data-widget_type="button.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-button-wrapper">
                                                        <a @click.prevent="ismj=true" class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="#">
                                                            <span class="elementor-button-content-wrapper">
                                                                <span class="elementor-button-icon">
                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                </span>
                                                                <span class="elementor-button-text">麻将胡了 试玩</span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-c2fc2fa elementor-invisible" data-id="c2fc2fa" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:300}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-7bd5491 elementor-widget elementor-widget-spacer" data-id="7bd5491" data-element_type="widget" data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-521931d elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="521931d" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-ac05e10" data-id="ac05e10" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-6d97ab2 elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="6d97ab2" data-element_type="widget" data-widget_type="image.default">
                                                                <div class="elementor-widget-container">
                                                                    <img loading="lazy" decoding="async" width="186" height="186" src="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__麻将方式-2.webp" class="attachment-full size-full wp-image-112" alt="" srcset="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__麻将方式-2.webp 186w, https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__麻将方式-2-150x150.webp 150w" sizes="(max-width: 186px) 100vw, 186px"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-27c3e9c" data-id="27c3e9c" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-ca85ef0 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="ca85ef0" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p>
                                                                        <span style="vertical-align: inherit;">
                                                                            <span style="vertical-align: inherit;">
                                                                                <strong>麻将胡了2</strong>
                                                                                <br/>
                                                                                人气《麻将胡了2》火热来袭！<br/>
                                                                            </span>
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <div class="elementor-element elementor-element-d8a6c04 elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="d8a6c04" data-element_type="widget" data-widget_type="button.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-button-wrapper">
                                                        <a @click.prevent="ismj2=true" class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="#">
                                                            <span class="elementor-button-content-wrapper">
                                                                <span class="elementor-button-icon">
                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                </span>
                                                                <span class="elementor-button-text">麻将胡了2 试玩</span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-6107663 elementor-invisible" data-id="6107663" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:600}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-5bf64fc elementor-widget elementor-widget-spacer" data-id="5bf64fc" data-element_type="widget" data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-38c0ba0 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="38c0ba0" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-ae527d9" data-id="ae527d9" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-177c0b0 elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="177c0b0" data-element_type="widget" data-widget_type="image.default">
                                                                <div class="elementor-widget-container">
                                                                    <img loading="lazy" decoding="async" width="186" height="186" src="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-39.webp" class="attachment-full size-full wp-image-73" alt="AnyConv.com_赏金女王" srcset="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-39.webp 186w, https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-39-150x150.webp 150w" sizes="(max-width: 186px) 100vw, 186px"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-6f4ad7a" data-id="6f4ad7a" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-8fbaabe elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="8fbaabe" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p>
                                                                        <span style="vertical-align: inherit;">
                                                                            <span style="vertical-align: inherit;">
                                                                                <strong>赏金女王</strong>
                                                                                <br/>
                                                                            </span>
                                                                        </span>
                                                                        <span style="vertical-align: inherit;">
                                                                            <span style="vertical-align: inherit;">
                                                                                夺走藏宝图，寻找尘封的宝藏!<br/>
                                                                            </span>
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <div class="elementor-element elementor-element-bbd95da elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="bbd95da" data-element_type="widget" data-widget_type="button.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-button-wrapper">
                                                        <a @click.prevent="issjnw=true" class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="#">
                                                            <span class="elementor-button-content-wrapper">
                                                                <span class="elementor-button-icon">
                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                </span>
                                                                <span class="elementor-button-text">赏金女王 试玩</span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-78131bc elementor-invisible" data-id="78131bc" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:600}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-7f9783c elementor-widget elementor-widget-spacer" data-id="7f9783c" data-element_type="widget" data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-cbb5399 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="cbb5399" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-0170136" data-id="0170136" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-9847f8a elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="9847f8a" data-element_type="widget" data-widget_type="image.default">
                                                                <div class="elementor-widget-container">
                                                                    <img loading="lazy" decoding="async" width="156" height="156" src="https://www.pgsoftgaming.com/wp-content/uploads/黄金摇钱树-1.webp" class="attachment-full size-full wp-image-1779" alt="黄金摇钱树"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-f2ba80d" data-id="f2ba80d" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-b54401b elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="b54401b" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p>
                                                                        <span style="vertical-align: inherit;">
                                                                            <strong>黄金摇钱树</strong>
                                                                            <br/>金树摇一摇，黄金装满袋！
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <div class="elementor-element elementor-element-39558f9 elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="39558f9" data-element_type="widget" data-widget_type="button.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-button-wrapper">
                                                        <a @click.prevent="ishjyqs=true" class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="#">
                                                            <span class="elementor-button-content-wrapper">
                                                                <span class="elementor-button-icon">
                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                </span>
                                                                <span class="elementor-button-text">黄金摇钱树 试玩</span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            
                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-c855f4c elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="c855f4c" data-element_type="section" data-settings="{&quot;animation_mobile&quot;:&quot;fadeInUp&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-4bceeda" data-id="4bceeda" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-8c45a19 elementor-widget elementor-widget-image" data-id="8c45a19" data-element_type="widget" data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <a href="#" target="_blank" rel="nofollow">
                                                        <img loading="lazy" decoding="async" width="1280" height="420" src="../assets/xboxOne/CQ9-拷貝.gif" class="attachment-large size-large wp-image-34826" alt=""  sizes="(max-width: 1280px) 100vw, 1280px"/>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-da2415b" data-id="da2415b" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-c318bb9 elementor-widget elementor-widget-image" data-id="c318bb9" data-element_type="widget" data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <a href="#" target="_blank" rel="nofollow">
                                                        <img loading="lazy" decoding="async" width="1280" height="420" src="../assets/xboxOne/BBIN123.gif" class="attachment-large size-large wp-image-34852" alt=""  sizes="(max-width: 1280px) 100vw, 1280px"/>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-95ff291 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="95ff291" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-wide">
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-a0444c3 elementor-invisible" data-id="a0444c3" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-ffac497 elementor-widget elementor-widget-spacer" data-id="ffac497" data-element_type="widget" data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-4fad2c2 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="4fad2c2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-3dc2df1" data-id="3dc2df1" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-8b0bb5f elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="8b0bb5f" data-element_type="widget" data-widget_type="image.default">
                                                                <div class="elementor-widget-container">
                                                                    <img loading="lazy" decoding="async" width="186" height="186" src="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-6.webp" class="attachment-full size-full wp-image-40" alt="" srcset="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-6.webp 186w, https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-6-150x150.webp 150w" sizes="(max-width: 186px) 100vw, 186px"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-0f9eb17" data-id="0f9eb17" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-453d9d8 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="453d9d8" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p>
                                                                        <span style="vertical-align: inherit;">
                                                                            <span style="vertical-align: inherit;">
                                                                                <strong>忍者vs武侍</strong>
                                                                                <br/>
                                                                                高达15,000倍投注翻倍<br/>
                                                                            </span>
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <div class="elementor-element elementor-element-bd434ef elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="bd434ef" data-element_type="widget" data-widget_type="button.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-button-wrapper">
                                                        <a @click.prevent="isrz=true" class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="#">
                                                            <span class="elementor-button-content-wrapper">
                                                                <span class="elementor-button-icon">
                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                </span>
                                                                <span class="elementor-button-text">忍者vs武侍 试玩</span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-a96f5a0 elementor-invisible" data-id="a96f5a0" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:300}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-93e0f18 elementor-widget elementor-widget-spacer" data-id="93e0f18" data-element_type="widget" data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-efb441a elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="efb441a" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-5959104" data-id="5959104" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-78ce619 elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="78ce619" data-element_type="widget" data-widget_type="image.default">
                                                                <div class="elementor-widget-container">
                                                                    <img loading="lazy" decoding="async" width="186" height="186" src="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-1.webp" class="attachment-full size-full wp-image-35" alt="" srcset="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-1.webp 186w, https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-1-150x150.webp 150w" sizes="(max-width: 186px) 100vw, 186px"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-70b9d8a" data-id="70b9d8a" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-6d7152c elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="6d7152c" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p>
                                                                        <span style="vertical-align: inherit;">
                                                                            <span style="vertical-align: inherit;">
                                                                                <strong>亡灵大盗</strong>
                                                                                <br/>
                                                                                擒拿头号强盗团以获取千万赏金！<br/>
                                                                            </span>
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <div class="elementor-element elementor-element-0c52d7e elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="0c52d7e" data-element_type="widget" data-widget_type="button.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-button-wrapper">
                                                        <a @click.prevent="iswldd=true"  class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="#">
                                                            <span class="elementor-button-content-wrapper">
                                                                <span class="elementor-button-icon">
                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                </span>
                                                                <span class="elementor-button-text">点击试玩</span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-4dc0cce elementor-invisible" data-id="4dc0cce" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:600}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-49e0fbc elementor-widget elementor-widget-spacer" data-id="49e0fbc" data-element_type="widget" data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-0381800 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="0381800" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-c9bdd70" data-id="c9bdd70" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-5443fd3 elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="5443fd3" data-element_type="widget" data-widget_type="image.default">
                                                                <div class="elementor-widget-container">
                                                                    <img loading="lazy" decoding="async" width="186" height="186" src="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-47.webp" class="attachment-full size-full wp-image-81" alt="少林足球" srcset="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-47.webp 186w, https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-47-150x150.webp 150w" sizes="(max-width: 186px) 100vw, 186px"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-79bee9c" data-id="79bee9c" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-29f0d80 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="29f0d80" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p>
                                                                        <span style="vertical-align: inherit;">
                                                                            <strong>少林足球</strong>
                                                                            <br/>“少林队”，一支前所未见的足球队
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <div class="elementor-element elementor-element-d3d95e8 elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="d3d95e8" data-element_type="widget" data-widget_type="button.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-button-wrapper">
                                                        <a @click.prevent="isslzq=true"  class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="#">
                                                            <span class="elementor-button-content-wrapper">
                                                                <span class="elementor-button-icon">
                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                </span>
                                                                <span class="elementor-button-text">点击试玩</span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-6b950cf elementor-invisible" data-id="6b950cf" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:900}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-9321178 elementor-widget elementor-widget-spacer" data-id="9321178" data-element_type="widget" data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-7975cd0 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="7975cd0" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-a3c3ac9" data-id="a3c3ac9" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-9e36288 elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="9e36288" data-element_type="widget" data-widget_type="image.default">
                                                                <div class="elementor-widget-container">
                                                                    <img loading="lazy" decoding="async" width="186" height="186" src="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-32.webp" class="attachment-full size-full wp-image-66" alt="美猴王传奇" srcset="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-32.webp 186w, https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-32-150x150.webp 150w" sizes="(max-width: 186px) 100vw, 186px"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-9d99894" data-id="9d99894" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-5021a8a elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="5021a8a" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p>
                                                                        <span style="vertical-align: inherit;">
                                                                            <span style="vertical-align: inherit;">
                                                                                <strong>美猴王传奇</strong>
                                                                                <br/>
                                                                                天宫里捣乱、征服天下！<br/>
                                                                            </span>
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <div class="elementor-element elementor-element-e72794f elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="e72794f" data-element_type="widget" data-widget_type="button.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-button-wrapper">
                                                        <a class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="https://www.pgsoftgaming.com/legendary-monkey-king/">
                                                            <span class="elementor-button-content-wrapper">
                                                                <span class="elementor-button-icon">
                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                </span>
                                                                <span class="elementor-button-text">点击试玩</span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div class="elementor-element elementor-element-c3b9ee6 elementor-widget elementor-widget-shortcode" data-id="c3b9ee6" data-element_type="widget" data-widget_type="shortcode.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-shortcode">
                                        <div data-elementor-type="section" data-elementor-id="34162" class="elementor elementor-34162" data-elementor-post-type="elementor_library">
                                            <section class="elementor-section elementor-top-section elementor-element elementor-element-4bb59c9 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="4bb59c9" data-element_type="section">
                                                <div class="elementor-container elementor-column-gap-default">
                                                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b3adb0e" data-id="b3adb0e" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-561f099 elementor-widget elementor-widget-image" data-id="561f099" data-element_type="widget" data-widget_type="image.default">
                                                                <div class="elementor-widget-container">
                                                                    <a href="#" target="_blank" rel="nofollow">
                                                                        <img loading="lazy" decoding="async" width="1280" height="420" src="../assets/xboxOne/1-3.jpg" class="elementor-animation-shrink attachment-large size-large wp-image-34895" alt="" sizes="(max-width: 1280px) 100vw, 1280px"/>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-9825409 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="9825409" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-1c2807e" data-id="1c2807e" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-99028a3 elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="99028a3" data-element_type="widget" data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h4 class="elementor-heading-title elementor-size-default">High RTP Games</h4>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-b5b3dc6 elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="b5b3dc6" data-element_type="widget" data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">高爆倍率</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-0648ac5" data-id="0648ac5" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-30b2b10 elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="30b2b10" data-element_type="widget" data-settings="{&quot;motion_fx_motion_fx_scrolling&quot;:&quot;yes&quot;,&quot;motion_fx_scale_effect&quot;:&quot;yes&quot;,&quot;motion_fx_scale_direction&quot;:&quot;in-out&quot;,&quot;motion_fx_scale_speed&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;-10&quot;,&quot;sizes&quot;:[]},&quot;motion_fx_scale_range&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:{&quot;start&quot;:&quot;0&quot;,&quot;end&quot;:&quot;50&quot;}},&quot;motion_fx_devices&quot;:[&quot;desktop&quot;,&quot;tablet&quot;,&quot;mobile&quot;]}" data-widget_type="divider.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-divider">
                                                        <span class="elementor-divider-separator"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-e02df73 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="e02df73" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-wide">
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-47de5d3 elementor-invisible" data-id="47de5d3" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:300}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-47871f7 elementor-widget elementor-widget-spacer" data-id="47871f7" data-element_type="widget" data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-50afa4a elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="50afa4a" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-9cbc451" data-id="9cbc451" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-99043aa elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="99043aa" data-element_type="widget" data-widget_type="image.default">
                                                                <div class="elementor-widget-container">
                                                                    <img loading="lazy" decoding="async" width="186" height="186" src="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-7.webp" class="attachment-full size-full wp-image-41" alt="拳霸" srcset="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-7.webp 186w, https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-7-150x150.webp 150w" sizes="(max-width: 186px) 100vw, 186px"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-dcc3e3b" data-id="dcc3e3b" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-f98dd77 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="f98dd77" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p>
                                                                        <span style="vertical-align: inherit;">
                                                                            <span style="vertical-align: inherit;">
                                                                                <strong>拳霸</strong>
                                                                                <br/>
                                                                                借助泰拳的力量擊敗對手<br/>
                                                                            </span>
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <div class="elementor-element elementor-element-b5ffe5d elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="b5ffe5d" data-element_type="widget" data-widget_type="button.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-button-wrapper">
                                                        <a class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="https://www.pgsoftgaming.com/muay-thai-champion/">
                                                            <span class="elementor-button-content-wrapper">
                                                                <span class="elementor-button-icon">
                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                </span>
                                                                <span class="elementor-button-text">点击试玩</span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-991df66 elementor-invisible" data-id="991df66" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-79cc3ee elementor-widget elementor-widget-spacer" data-id="79cc3ee" data-element_type="widget" data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-d1864a6 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="d1864a6" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-db141ae" data-id="db141ae" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-57a220b elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="57a220b" data-element_type="widget" data-widget_type="image.default">
                                                                <div class="elementor-widget-container">
                                                                    <a href="#">
                                                                        <img loading="lazy" decoding="async" width="156" height="156" src="../assets/xboxOne/942a8ad7-2501-41dd-9b03-d0cad60b9699.png" class="attachment-full size-full wp-image-25811" alt="金钱兔APP图示"/>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-500733b" data-id="500733b" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-9af7bd3 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="9af7bd3" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p>
                                                                        <span style="vertical-align: inherit;">
                                                                            <span style="vertical-align: inherit;">
                                                                                <strong>金钱兔</strong>
                                                                                <br/>
                                                                                金钱兔赐予您无限好运及财富！<br/>
                                                                            </span>
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <div class="elementor-element elementor-element-fecbada elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="fecbada" data-element_type="widget" data-widget_type="button.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-button-wrapper">
                                                        <a class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="https://pgsoftgaming.com/fortune-rabbit/">
                                                            <span class="elementor-button-content-wrapper">
                                                                <span class="elementor-button-icon">
                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                </span>
                                                                <span class="elementor-button-text">点击试玩</span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-0d1d87b elementor-invisible" data-id="0d1d87b" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:600}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-71ffe0a elementor-widget elementor-widget-spacer" data-id="71ffe0a" data-element_type="widget" data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-14907cd elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="14907cd" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-37f13aa" data-id="37f13aa" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-9c5ff37 elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="9c5ff37" data-element_type="widget" data-widget_type="image.default">
                                                                <div class="elementor-widget-container">
                                                                    <img loading="lazy" decoding="async" width="186" height="186" src="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-61.webp" class="attachment-full size-full wp-image-95" alt="双囍临门" srcset="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-61.webp 186w, https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-61-150x150.webp 150w" sizes="(max-width: 186px) 100vw, 186px"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-8481185" data-id="8481185" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-85ef7f3 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="85ef7f3" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p>
                                                                        <span style="vertical-align: inherit;">
                                                                            <span style="vertical-align: inherit;">
                                                                                <strong>双囍临门</strong>
                                                                                <br/>双重符号让您财富双至！
                                                                            </span>
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <div class="elementor-element elementor-element-4e43c51 elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="4e43c51" data-element_type="widget" data-widget_type="button.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-button-wrapper">
                                                        <a class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="https://www.pgsoftgaming.com/doublefortune/">
                                                            <span class="elementor-button-content-wrapper">
                                                                <span class="elementor-button-icon">
                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                </span>
                                                                <span class="elementor-button-text">点击试玩</span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-73cacd3 elementor-invisible" data-id="73cacd3" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:900}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-47233b6 elementor-widget elementor-widget-spacer" data-id="47233b6" data-element_type="widget" data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-af17c93 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="af17c93" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-ddf2c06" data-id="ddf2c06" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-ca234d7 elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="ca234d7" data-element_type="widget" data-widget_type="image.default">
                                                                <div class="elementor-widget-container">
                                                                    <a href="#">
                                                                        <img loading="lazy" decoding="async" width="1024" height="1024" src="https://www.pgsoftgaming.com/wp-content/uploads/pggames-25.webp" class="attachment-full size-full wp-image-2644" alt="" srcset="https://www.pgsoftgaming.com/wp-content/uploads/pggames-25.webp 1024w, https://www.pgsoftgaming.com/wp-content/uploads/pggames-25-768x768.webp 768w" sizes="(max-width: 1024px) 100vw, 1024px"/>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-1cd3641" data-id="1cd3641" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-5768770 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="5768770" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p>
                                                                        <span style="vertical-align: inherit;">
                                                                            <span style="vertical-align: inherit;">
                                                                                <b>赢财神</b>
                                                                                <br/>
                                                                                经典再现，连赢连送福气旺！<br/>
                                                                            </span>
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <div class="elementor-element elementor-element-16d2723 elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="16d2723" data-element_type="widget" data-widget_type="button.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-button-wrapper">
                                                        <a class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="https://pgsoftgaming.com/fortunegods/">
                                                            <span class="elementor-button-content-wrapper">
                                                                <span class="elementor-button-icon">
                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                </span>
                                                                <span class="elementor-button-text">点击试玩</span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-992f817 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="992f817" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-wide">
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-df6f08b elementor-invisible" data-id="df6f08b" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-0543fa8 elementor-widget elementor-widget-spacer" data-id="0543fa8" data-element_type="widget" data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-1b57278 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="1b57278" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-12f3dc1" data-id="12f3dc1" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-8bca610 elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="8bca610" data-element_type="widget" data-widget_type="image.default">
                                                                <div class="elementor-widget-container">
                                                                    <a href="#">
                                                                        <img loading="lazy" decoding="async" width="186" height="186" src="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-36.webp" class="attachment-full size-full wp-image-70" alt="" srcset="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-36.webp 186w, https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-36-150x150.webp 150w" sizes="(max-width: 186px) 100vw, 186px"/>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-1787851" data-id="1787851" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-f9d4bec elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="f9d4bec" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p>
                                                                        <span style="vertical-align: inherit;">
                                                                            <span style="vertical-align: inherit;">
                                                                                <strong>星旅淘金</strong>
                                                                                <br/>
                                                                                一趟星际之旅，带您收获奇珍异宝！<br/>
                                                                            </span>
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <div class="elementor-element elementor-element-cbf5a2d elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="cbf5a2d" data-element_type="widget" data-widget_type="button.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-button-wrapper">
                                                        <a class="elementor-button elementor-button-link elementor-size-sm" href="https://www.pgsoftgaming.com/galacticgems/">
                                                            <span class="elementor-button-content-wrapper">
                                                                <span class="elementor-button-icon">
                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                </span>
                                                                <span class="elementor-button-text">点击试玩</span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-fa7687c elementor-invisible" data-id="fa7687c" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:300}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-40d606e elementor-widget elementor-widget-spacer" data-id="40d606e" data-element_type="widget" data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-6fe8fba elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="6fe8fba" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-06911a5" data-id="06911a5" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-50fcb07 elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="50fcb07" data-element_type="widget" data-widget_type="image.default">
                                                                <div class="elementor-widget-container">
                                                                    <a href="#">
                                                                        <img loading="lazy" decoding="async" width="186" height="186" src="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-31.webp" class="attachment-full size-full wp-image-65" alt="美洲野牛" srcset="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-31.webp 186w, https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-31-150x150.webp 150w" sizes="(max-width: 186px) 100vw, 186px"/>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-5168020" data-id="5168020" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-0d33fc1 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="0d33fc1" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p>
                                                                        <span style="vertical-align: inherit;">
                                                                            <span style="vertical-align: inherit;">
                                                                                <strong>美洲野牛</strong>
                                                                                <br/>
                                                                                北境之王的一场惊天之战！<br/>
                                                                            </span>
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <div class="elementor-element elementor-element-f06782c elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="f06782c" data-element_type="widget" data-widget_type="button.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-button-wrapper">
                                                        <a class="elementor-button elementor-button-link elementor-size-sm" href="https://www.pgsoftgaming.com/buffalowin/">
                                                            <span class="elementor-button-content-wrapper">
                                                                <span class="elementor-button-icon">
                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                </span>
                                                                <span class="elementor-button-text">点击试玩</span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-0a6e401 elementor-invisible" data-id="0a6e401" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:600}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-69b1672 elementor-widget elementor-widget-spacer" data-id="69b1672" data-element_type="widget" data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-2e68bc9 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="2e68bc9" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-99bc4bb" data-id="99bc4bb" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-d04486c elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="d04486c" data-element_type="widget" data-widget_type="image.default">
                                                                <div class="elementor-widget-container">
                                                                    <a href="#">
                                                                        <img loading="lazy" decoding="async" width="186" height="186" src="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-59.webp" class="attachment-full size-full wp-image-93" alt="" srcset="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-59.webp 186w, https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-59-150x150.webp 150w" sizes="(max-width: 186px) 100vw, 186px"/>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-68930e4" data-id="68930e4" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-4b30c7e elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="4b30c7e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p>
                                                                        <span style="vertical-align: inherit;">
                                                                            <strong>冰雪大冲关</strong>
                                                                            <br/>击碎巨冰石，获取奖金重转！
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <div class="elementor-element elementor-element-d60e9f3 elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="d60e9f3" data-element_type="widget" data-widget_type="button.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-button-wrapper">
                                                        <a class="elementor-button elementor-button-link elementor-size-sm" href="https://www.pgsoftgaming.com/thegreaticescape/">
                                                            <span class="elementor-button-content-wrapper">
                                                                <span class="elementor-button-icon">
                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                </span>
                                                                <span class="elementor-button-text">点击试玩</span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-f568436 elementor-invisible" data-id="f568436" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:900}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-a1df190 elementor-widget elementor-widget-spacer" data-id="a1df190" data-element_type="widget" data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-ed47854 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="ed47854" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-d037aab" data-id="d037aab" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-eb44dba elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="eb44dba" data-element_type="widget" data-widget_type="image.default">
                                                                <div class="elementor-widget-container">
                                                                    <a href="#">
                                                                        <img loading="lazy" decoding="async" width="156" height="156" src="../assets/xboxOne/下載.jpg" class="attachment-full size-full wp-image-4771" alt="赏金大对决"/>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-0fbf926" data-id="0fbf926" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-795e333 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="795e333" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p>
                                                                        <span style="vertical-align: inherit;">
                                                                            <span style="vertical-align: inherit;">
                                                                                <strong>赏金大对决</strong>
                                                                                <br/>
                                                                                超酷超燃！击败强盗获取双倍奖励！<br/>
                                                                            </span>
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <div class="elementor-element elementor-element-10c7696 elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="10c7696" data-element_type="widget" data-widget_type="button.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-button-wrapper">
                                                        <a class="elementor-button elementor-button-link elementor-size-sm" href="https://www.pgsoftgaming.com/wild-bounty-showdown/">
                                                            <span class="elementor-button-content-wrapper">
                                                                <span class="elementor-button-icon">
                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                </span>
                                                                <span class="elementor-button-text">点击试玩</span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <section class="elementor-section elementor-top-section elementor-element elementor-element-e113b0c elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="e113b0c" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-container elementor-column-gap-no">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-fd91d0a" data-id="fd91d0a" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-fc89ac5 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="fc89ac5" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-9e25e87" data-id="9e25e87" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-c187174 elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="c187174" data-element_type="widget" data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h4 class="elementor-heading-title elementor-size-default">Play More Games</h4>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-fc2dad4 elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="fc2dad4" data-element_type="widget" data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">试玩更多</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-38b3740" data-id="38b3740" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-33f77be elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="33f77be" data-element_type="widget" data-settings="{&quot;motion_fx_motion_fx_scrolling&quot;:&quot;yes&quot;,&quot;motion_fx_scale_effect&quot;:&quot;yes&quot;,&quot;motion_fx_scale_direction&quot;:&quot;in-out&quot;,&quot;motion_fx_scale_speed&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;-10&quot;,&quot;sizes&quot;:[]},&quot;motion_fx_scale_range&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:{&quot;start&quot;:&quot;0&quot;,&quot;end&quot;:&quot;50&quot;}},&quot;motion_fx_devices&quot;:[&quot;desktop&quot;,&quot;tablet&quot;,&quot;mobile&quot;]}" data-widget_type="divider.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-divider">
                                                        <span class="elementor-divider-separator"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-ac46371 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="ac46371" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-wide">
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-a0f66da elementor-invisible" data-id="a0f66da" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-668f5ed elementor-widget elementor-widget-spacer" data-id="668f5ed" data-element_type="widget" data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-dbe2123 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="dbe2123" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-7137cb9" data-id="7137cb9" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-15e95b7 elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="15e95b7" data-element_type="widget" data-widget_type="image.default">
                                                                <div class="elementor-widget-container">
                                                                    <img loading="lazy" decoding="async" width="1024" height="1024" src="https://www.pgsoftgaming.com/wp-content/uploads/pggames-38.webp" class="attachment-full size-full wp-image-2657" alt="" srcset="https://www.pgsoftgaming.com/wp-content/uploads/pggames-38.webp 1024w, https://www.pgsoftgaming.com/wp-content/uploads/pggames-38-768x768.webp 768w" sizes="(max-width: 1024px) 100vw, 1024px"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-27d5e48" data-id="27d5e48" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-245cb02 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="245cb02" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p>
                                                                        <span style="vertical-align: inherit;">
                                                                            <span style="vertical-align: inherit;">
                                                                                <strong>太阳神传说</strong>
                                                                                <br/>
                                                                                太阳神的祝福，获得无限好运！<br/>
                                                                            </span>
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-3b6b4a7 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="3b6b4a7" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-b26e275" data-id="b26e275" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-531401b elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="531401b" data-element_type="widget" data-widget_type="button.default">
                                                                <div class="elementor-widget-container">
                                                                    <div class="elementor-button-wrapper">
                                                                        <a @click.prevent="istys=true" class="elementor-button elementor-button-link elementor-size-sm" href="#">
                                                                            <span class="elementor-button-content-wrapper">
                                                                                <span class="elementor-button-icon">
                                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                </span>
                                                                                <span class="elementor-button-text">点击试玩</span>
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-679bfd0 elementor-invisible" data-id="679bfd0" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:300}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-d4cbe3c elementor-widget elementor-widget-spacer" data-id="d4cbe3c" data-element_type="widget" data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-1ad4415 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="1ad4415" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-dea8a82" data-id="dea8a82" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-56f36b2 elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="56f36b2" data-element_type="widget" data-widget_type="image.default">
                                                                <div class="elementor-widget-container">
                                                                    <img loading="lazy" decoding="async" width="186" height="186" src="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-25.webp" class="attachment-full size-full wp-image-59" alt="宝石传奇" srcset="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-25.webp 186w, https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-25-150x150.webp 150w" sizes="(max-width: 186px) 100vw, 186px"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-d75168b" data-id="d75168b" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-a4053e4 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="a4053e4" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p>
                                                                        <span style="vertical-align: inherit;">
                                                                            <span style="vertical-align: inherit;">
                                                                                <strong>宝石传奇</strong>
                                                                                <br/>
                                                                                世界上最有价值、最闪亮的宝石<br/>
                                                                            </span>
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-ba39079 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="ba39079" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-dbb1f98" data-id="dbb1f98" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-26822c8 elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="26822c8" data-element_type="widget" data-widget_type="button.default">
                                                                <div class="elementor-widget-container">
                                                                    <div class="elementor-button-wrapper">
                                                                        <a @click.prevent="isbscq=true" class="elementor-button elementor-button-link elementor-size-sm" href="#">
                                                                            <span class="elementor-button-content-wrapper">
                                                                                <span class="elementor-button-icon">
                                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                </span>
                                                                                <span class="elementor-button-text">点击试玩</span>
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-f2d7910 elementor-invisible" data-id="f2d7910" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:900}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-fc7e7a4 elementor-widget elementor-widget-spacer" data-id="fc7e7a4" data-element_type="widget" data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-d46f15b elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="d46f15b" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-c44b83d" data-id="c44b83d" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-07993c2 elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="07993c2" data-element_type="widget" data-widget_type="image.default">
                                                                <div class="elementor-widget-container">
                                                                    <a href="#">
                                                                        <img loading="lazy" decoding="async" width="186" height="186" src="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-14.webp" class="attachment-full size-full wp-image-48" alt="AnyConv.com_发财鱼虾蟹" srcset="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-14.webp 186w, https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-14-150x150.webp 150w" sizes="(max-width: 186px) 100vw, 186px"/>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-dc76243" data-id="dc76243" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-f04e72f elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="f04e72f" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p>
                                                                        <strong>发财鱼虾蟹</strong>
                                                                        <br/>鱼虾蟹经典再现，连赢连送福气旺！
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-5868727 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="5868727" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4e8927c" data-id="4e8927c" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-80a12b2 elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="80a12b2" data-element_type="widget" data-widget_type="button.default">
                                                                <div class="elementor-widget-container">
                                                                    <div class="elementor-button-wrapper">
                                                                        <a class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="https://pgsoftgaming.com/winwinfishprawncrab/">
                                                                            <span class="elementor-button-content-wrapper">
                                                                                <span class="elementor-button-icon">
                                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                </span>
                                                                                <span class="elementor-button-text">点击试玩</span>
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-a3680a5 elementor-invisible" data-id="a3680a5" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:900}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-6f3f969 elementor-widget elementor-widget-spacer" data-id="6f3f969" data-element_type="widget" data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-7da24d6 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="7da24d6" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-326d164" data-id="326d164" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-d5459f3 elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="d5459f3" data-element_type="widget" data-widget_type="image.default">
                                                                <div class="elementor-widget-container">
                                                                    <img loading="lazy" decoding="async" width="512" height="512" src="../assets/xboxOne/恐龙帝国.png" class="attachment-full size-full wp-image-34052" alt=""/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-48459b5" data-id="48459b5" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-50efc59 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="50efc59" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p>
                                                                        <span style="vertical-align: inherit;">
                                                                            <span style="vertical-align: inherit;">
                                                                                <strong>恐龙帝国</strong>
                                                                                <br/>
                                                                                成为他们的一员，建立侏罗纪王国<br/>
                                                                            </span>
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <div class="elementor-element elementor-element-27bf483 elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="27bf483" data-element_type="widget" data-widget_type="button.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-button-wrapper">
                                                        <a @click.prevent="iskl=true" class="elementor-button elementor-button-link elementor-size-sm" href="#">
                                                            <span class="elementor-button-content-wrapper">
                                                                <span class="elementor-button-icon">
                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                </span>
                                                                <span class="elementor-button-text">点击试玩</span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-fd6c310 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="fd6c310" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-wide">
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-9612ce3 elementor-invisible" data-id="9612ce3" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-bd214fa elementor-widget elementor-widget-spacer" data-id="bd214fa" data-element_type="widget" data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-3d055e2 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="3d055e2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-9e3b123" data-id="9e3b123" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-9cbd5c7 elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="9cbd5c7" data-element_type="widget" data-widget_type="image.default">
                                                                <div class="elementor-widget-container">
                                                                    <img loading="lazy" decoding="async" width="512" height="512" src="../assets/xboxOne/招财喵.png" class="attachment-full size-full wp-image-34061" alt="招财喵"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-8bde6f1" data-id="8bde6f1" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-b3a8eb4 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="b3a8eb4" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p>
                                                                        <span style="vertical-align: inherit;">
                                                                            <span style="vertical-align: inherit;">
                                                                                <strong>招财喵</strong>
                                                                                <br/>
                                                                                招财猫助您财运亨通！<br/>
                                                                            </span>
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-fbf5658 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="fbf5658" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-961adfb" data-id="961adfb" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-8f21a5c elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="8f21a5c" data-element_type="widget" data-widget_type="button.default">
                                                                <div class="elementor-widget-container">
                                                                    <div class="elementor-button-wrapper">
                                                                        <a @click.prevent="iszcm=true" class="elementor-button elementor-button-link elementor-size-sm" href="#">
                                                                            <span class="elementor-button-content-wrapper">
                                                                                <span class="elementor-button-icon">
                                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                </span>
                                                                                <span class="elementor-button-text">点击试玩</span>
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-edd5afe elementor-invisible" data-id="edd5afe" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:300}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-8eae25d elementor-widget elementor-widget-spacer" data-id="8eae25d" data-element_type="widget" data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-c715934 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="c715934" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-e6adc95" data-id="e6adc95" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-40fdfcc elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="40fdfcc" data-element_type="widget" data-widget_type="image.default">
                                                                <div class="elementor-widget-container">
                                                                    <img loading="lazy" decoding="async" width="186" height="186" src="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__财富之树.webp" class="attachment-full size-full wp-image-106" alt="摇钱树" srcset="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__财富之树.webp 186w, https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__财富之树-150x150.webp 150w" sizes="(max-width: 186px) 100vw, 186px"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-248d5d0" data-id="248d5d0" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-fac07a5 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="fac07a5" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p>
                                                                        <span style="vertical-align: inherit;">
                                                                            <span style="vertical-align: inherit;">
                                                                                <strong>摇钱树</strong>
                                                                                <br/>
                                                                                世界上最有价值、最闪亮的宝石<br/>
                                                                            </span>
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-5eb49b5 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="5eb49b5" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-72f413a" data-id="72f413a" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-5dc8fae elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="5dc8fae" data-element_type="widget" data-widget_type="button.default">
                                                                <div class="elementor-widget-container">
                                                                    <div class="elementor-button-wrapper">
                                                                        <a @click.prevent="isyqs=true" class="elementor-button elementor-button-link elementor-size-sm" href="#">
                                                                            <span class="elementor-button-content-wrapper">
                                                                                <span class="elementor-button-icon">
                                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                </span>
                                                                                <span class="elementor-button-text">点击试玩</span>
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-50b7d4f elementor-invisible" data-id="50b7d4f" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:600}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-e1ccb1d elementor-widget elementor-widget-spacer" data-id="e1ccb1d" data-element_type="widget" data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-a5d9302 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="a5d9302" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-add265d" data-id="add265d" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-f8ad23e elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="f8ad23e" data-element_type="widget" data-widget_type="image.default">
                                                                <div class="elementor-widget-container">
                                                                    <img loading="lazy" decoding="async" width="186" height="186" src="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-16.webp" class="attachment-full size-full wp-image-50" alt="斗鸡" srcset="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-16.webp 186w, https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-16-150x150.webp 150w" sizes="(max-width: 186px) 100vw, 186px"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-43a3902" data-id="43a3902" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-ace4c42 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="ace4c42" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p>
                                                                        <span style="vertical-align: inherit;">
                                                                            <strong>斗鸡</strong>
                                                                            <br/>观一场热血激情的斗鸡擂台之战
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-455f4f5 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="455f4f5" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-7bebbd2" data-id="7bebbd2" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-d3f939d elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="d3f939d" data-element_type="widget" data-widget_type="button.default">
                                                                <div class="elementor-widget-container">
                                                                    <div class="elementor-button-wrapper">
                                                                        <a @click.prevent="isdj=true" class="elementor-button elementor-button-link elementor-size-sm" href="#">
                                                                            <span class="elementor-button-content-wrapper">
                                                                                <span class="elementor-button-icon">
                                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                </span>
                                                                                <span class="elementor-button-text">点击试玩</span>
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-1f78516 elementor-invisible" data-id="1f78516" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:900}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-60e0ad5 elementor-widget elementor-widget-spacer" data-id="60e0ad5" data-element_type="widget" data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-11968cd elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="11968cd" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-1d73aad" data-id="1d73aad" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-cc1a358 elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="cc1a358" data-element_type="widget" data-widget_type="image.default">
                                                                <div class="elementor-widget-container">
                                                                    <img loading="lazy" decoding="async" width="186" height="186" src="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-18.webp" class="attachment-full size-full wp-image-52" alt="日月星辰" srcset="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-18.webp 186w, https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-18-150x150.webp 150w" sizes="(max-width: 186px) 100vw, 186px"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-aebba0f" data-id="aebba0f" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-a3c1d38 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="a3c1d38" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p>
                                                                        <span style="vertical-align: inherit;">
                                                                            <span style="vertical-align: inherit;">
                                                                                <strong>日月星辰</strong>
                                                                                <br/>
                                                                                接受日月的祝福，获得永恒的爱情！ <br/>
                                                                            </span>
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <div class="elementor-element elementor-element-87fc151 elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="87fc151" data-element_type="widget" data-widget_type="button.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-button-wrapper">
                                                        <a @click.prevent="isryxc=true" class="elementor-button elementor-button-link elementor-size-sm" href="#">
                                                            <span class="elementor-button-content-wrapper">
                                                                <span class="elementor-button-icon">
                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                </span>
                                                                <span class="elementor-button-text">点击试玩</span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <section class="elementor-section elementor-top-section elementor-element elementor-element-fdf88ac elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="fdf88ac" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-85d76d5" data-id="85d76d5" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-e0e1433 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial elementor-widget elementor-widget-image" data-id="e0e1433" data-element_type="widget" data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;motion_fx_motion_fx_scrolling&quot;:&quot;yes&quot;,&quot;motion_fx_translateY_effect&quot;:&quot;yes&quot;,&quot;motion_fx_translateY_direction&quot;:&quot;negative&quot;,&quot;motion_fx_translateY_speed&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;1&quot;,&quot;sizes&quot;:[]},&quot;motion_fx_translateY_affectedRange&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:{&quot;start&quot;:0,&quot;end&quot;:100}},&quot;motion_fx_devices&quot;:[&quot;desktop&quot;,&quot;tablet&quot;,&quot;mobile&quot;]}" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img loading="lazy" decoding="async" width="696" height="666" src="../assets/xboxOne/a73639a79025fc1a7d5c7a35e45fd31b.png" class="attachment-large size-large wp-image-2315" alt="PG电子游戏试玩平台"/>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-4ce2725 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial elementor-widget elementor-widget-image" data-id="4ce2725" data-element_type="widget" data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;motion_fx_motion_fx_scrolling&quot;:&quot;yes&quot;,&quot;motion_fx_translateY_effect&quot;:&quot;yes&quot;,&quot;motion_fx_translateY_speed&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;1&quot;,&quot;sizes&quot;:[]},&quot;motion_fx_translateY_affectedRange&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:{&quot;start&quot;:0,&quot;end&quot;:100}},&quot;motion_fx_devices&quot;:[&quot;desktop&quot;,&quot;tablet&quot;,&quot;mobile&quot;]}" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img loading="lazy" decoding="async" width="2201" height="1140" src="../assets/xboxOne/PG-14.png" class="attachment-large size-large wp-image-33469" alt=""  sizes="(max-width: 2201px) 100vw, 2201px"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-230c431" data-id="230c431" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-36a5a8b elementor-widget elementor-widget-heading" data-id="36a5a8b" data-element_type="widget" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h4 class="elementor-heading-title elementor-size-default">2024 PG电子试玩大厅</h4>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-a950c37 elementor-widget elementor-widget-heading" data-id="a950c37" data-element_type="widget" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h2 class="elementor-heading-title elementor-size-default">新手专属签到礼包</h2>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-d7beb69 elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="d7beb69" data-element_type="widget" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h2 class="elementor-heading-title elementor-size-default">最高 8,384！</h2>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-0272827 elementor-widget elementor-widget-shortcode" data-id="0272827" data-element_type="widget" data-widget_type="shortcode.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-shortcode">
                                        <div data-elementor-type="section" data-elementor-id="33905" class="elementor elementor-33905" data-elementor-post-type="elementor_library">
                                            <section class="elementor-section elementor-top-section elementor-element elementor-element-2547e90 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="2547e90" data-element_type="section">
                                                <div class="elementor-container elementor-column-gap-default">
                                                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b9c4a00" data-id="b9c4a00" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-ea97123 elementor-widget elementor-widget-button" data-id="ea97123" data-element_type="widget" data-widget_type="button.default">
                                                                <div class="elementor-widget-container">
                                                                    <div class="elementor-button-wrapper">
                                                                        <a class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="https://rr.top" target="_blank" rel="nofollow">
                                                                            <span class="elementor-button-content-wrapper">
                                                                                <span class="elementor-button-icon">
                                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                </span>
                                                                                <span class="elementor-button-text">2025 抢百万蛇我其谁</span>
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="elementor-section elementor-top-section elementor-element elementor-element-a5cc36f elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="a5cc36f" data-element_type="section">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-f8ae770" data-id="f8ae770" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-31fd716 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="31fd716" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-wide">
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-e177d6b elementor-invisible" data-id="e177d6b" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-159ac98 elementor-widget elementor-widget-spacer" data-id="159ac98" data-element_type="widget" data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-cb02d87 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="cb02d87" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-f29b3c5" data-id="f29b3c5" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-9286d55 elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="9286d55" data-element_type="widget" data-widget_type="image.default">
                                                                <div class="elementor-widget-container">
                                                                    <img loading="lazy" decoding="async" width="186" height="186" src="../assets/xboxOne/images丽影奇兵探秘埃及.jpg" class="attachment-full size-full wp-image-33981" alt=""/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-80a665c" data-id="80a665c" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-c87b0db elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="c87b0db" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p>
                                                                        <span style="vertical-align: inherit;">
                                                                            <span style="vertical-align: inherit;">
                                                                                <strong>丽影奇兵探秘埃及</strong>
                                                                                <br/>
                                                                                来一趟探秘地穴致富之旅！<br/>
                                                                            </span>
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-d3766f3 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="d3766f3" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-c70af9b" data-id="c70af9b" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-1ef1561 elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="1ef1561" data-element_type="widget" data-widget_type="button.default">
                                                                <div class="elementor-widget-container">
                                                                    <div class="elementor-button-wrapper">
                                                                        <a @click.prevent="islyqb=true" class="elementor-button elementor-button-link elementor-size-sm" href="#">
                                                                            <span class="elementor-button-content-wrapper">
                                                                                <span class="elementor-button-icon">
                                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                </span>
                                                                                <span class="elementor-button-text">点击试玩</span>
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-f403205 elementor-invisible" data-id="f403205" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:300}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-fbe00c2 elementor-widget elementor-widget-spacer" data-id="fbe00c2" data-element_type="widget" data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-d3a6abb elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="d3a6abb" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-65d1ca9" data-id="65d1ca9" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-baf2fa1 elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="baf2fa1" data-element_type="widget" data-widget_type="image.default">
                                                                <div class="elementor-widget-container">
                                                                    <img loading="lazy" decoding="async" width="186" height="186" src="../assets/xboxOne/公主download.jpg" class="attachment-full size-full wp-image-33997" alt=""/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-c1d9f63" data-id="c1d9f63" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-7633861 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="7633861" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p>
                                                                        <span style="vertical-align: inherit;">
                                                                            <span style="vertical-align: inherit;">
                                                                                <strong>人鱼公主</strong>
                                                                                <br/>
                                                                                邂逅人鱼公主，探索神秘的海底！ <br/>
                                                                            </span>
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-97b1f64 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="97b1f64" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-1d873bf" data-id="1d873bf" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-9035203 elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="9035203" data-element_type="widget" data-widget_type="button.default">
                                                                <div class="elementor-widget-container">
                                                                    <div class="elementor-button-wrapper">
                                                                        <a  @click.prevent="isrygz=true" class="elementor-button elementor-button-link elementor-size-sm" href="#">
                                                                            <span class="elementor-button-content-wrapper">
                                                                                <span class="elementor-button-icon">
                                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                </span>
                                                                                <span class="elementor-button-text">点击试玩</span>
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-d8c466a elementor-invisible" data-id="d8c466a" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:600}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-c2ae091 elementor-widget elementor-widget-spacer" data-id="c2ae091" data-element_type="widget" data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-8041dd3 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="8041dd3" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-efe2c75" data-id="efe2c75" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-8d3d19c elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="8d3d19c" data-element_type="widget" data-widget_type="image.default">
                                                                <div class="elementor-widget-container">
                                                                    <img loading="lazy" decoding="async" width="186" height="186" src="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-21.webp" class="attachment-full size-full wp-image-55" alt="假面嘉年华" srcset="https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-21.webp 186w, https://www.pgsoftgaming.com/wp-content/uploads/2022/08/AnyConv.com__app_icon_small_3x-21-150x150.webp 150w" sizes="(max-width: 186px) 100vw, 186px"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-52943e6" data-id="52943e6" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-4720b33 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="4720b33" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p>
                                                                        <span style="vertical-align: inherit;">
                                                                            <strong>假面嘉年华</strong>
                                                                            <br/>金树摇一摇，黄金装满袋！
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-d98cdd9 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="d98cdd9" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-2ad2696" data-id="2ad2696" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-3575078 elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="3575078" data-element_type="widget" data-widget_type="button.default">
                                                                <div class="elementor-widget-container">
                                                                    <div class="elementor-button-wrapper">
                                                                        <a  @click.prevent="isjnh=true" class="elementor-button elementor-button-link elementor-size-sm" href="#">
                                                                            <span class="elementor-button-content-wrapper">
                                                                                <span class="elementor-button-icon">
                                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                </span>
                                                                                <span class="elementor-button-text">点击试玩</span>
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-8ece446 elementor-invisible" data-id="8ece446" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:900}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-2124e14 elementor-widget elementor-widget-spacer" data-id="2124e14" data-element_type="widget" data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-3e135b2 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="3e135b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-367b825" data-id="367b825" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-b208e17 elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="b208e17" data-element_type="widget" data-widget_type="image.default">
                                                                <div class="elementor-widget-container">
                                                                    <img loading="lazy" decoding="async" width="156" height="156" src="https://www.pgsoftgaming.com/wp-content/uploads/图腾奇迹-1.webp" class="attachment-full size-full wp-image-1780" alt=""/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-a9843a5" data-id="a9843a5" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-115bc61 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="115bc61" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div class="elementor-widget-container">
                                                                    <p>
                                                                        <span style="vertical-align: inherit;">
                                                                            <span style="vertical-align: inherit;">
                                                                                <strong>图腾奇迹</strong>
                                                                                <br/>
                                                                                三根神秘的图腾柱拥有巨大的好运！<br/>
                                                                            </span>
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-acafda2 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="acafda2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-container elementor-column-gap-narrow">
                                                    <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-8c04e86" data-id="8c04e86" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-4b2ce0d elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="4b2ce0d" data-element_type="widget" data-widget_type="button.default">
                                                                <div class="elementor-widget-container">
                                                                    <div class="elementor-button-wrapper">
                                                                        <a  @click.prevent="isttqj=true" class="elementor-button elementor-button-link elementor-size-sm" href="#">
                                                                            <span class="elementor-button-content-wrapper">
                                                                                <span class="elementor-button-icon">
                                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                </span>
                                                                                <span class="elementor-button-text">点击试玩</span>
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-d4d6fcb elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="d4d6fcb" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-wide">
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-5f1f1c2" data-id="5f1f1c2" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-4bde852 elementor-widget elementor-widget-image" data-id="4bde852" data-element_type="widget" data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <a href="#" target="_blank" rel="nofollow" pg="">
                                                        <img loading="lazy" decoding="async" width="1280" height="420" src="../assets/xboxOne/esball-最高日獎5088.gif" class="attachment-large size-large wp-image-11419" alt="esball-最高日獎5088"  sizes="(max-width: 1280px) 100vw, 1280px"/>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-9c6e29a" data-id="9c6e29a" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-be93089 elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="be93089" data-element_type="widget" data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">最新活动 ★电子推荐★</h2>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-604ca44 elementor-mobile-align-center elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="604ca44" data-element_type="widget" data-widget_type="icon-list.default">
                                                <div class="elementor-widget-container">
                                                    <ul class="elementor-icon-list-items">
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <i aria-hidden="true" class="fas fa-chess-queen"></i>
                                                            </span>
                                                            <span class="elementor-icon-list-text">新会员首存35% 再享二,三存</span>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <i aria-hidden="true" class="fas fa-chess-queen"></i>
                                                            </span>
                                                            <span class="elementor-icon-list-text">电子打码 最高日奖金5088</span>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <i aria-hidden="true" class="fas fa-chess-queen"></i>
                                                            </span>
                                                            <span class="elementor-icon-list-text">多种便捷支付模式,满足玩家需求</span>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <i aria-hidden="true" class="fas fa-chess-queen"></i>
                                                            </span>
                                                            <span class="elementor-icon-list-text">注册试玩更多游戏！</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-3d879a9 elementor-widget elementor-widget-shortcode" data-id="3d879a9" data-element_type="widget" data-widget_type="shortcode.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-shortcode">
                                                        <div data-elementor-type="section" data-elementor-id="33905" class="elementor elementor-33905" data-elementor-post-type="elementor_library">
                                                            <section class="elementor-section elementor-top-section elementor-element elementor-element-2547e90 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="2547e90" data-element_type="section">
                                                                <div class="elementor-container elementor-column-gap-default">
                                                                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b9c4a00" data-id="b9c4a00" data-element_type="column">
                                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                                            <div class="elementor-element elementor-element-ea97123 elementor-widget elementor-widget-button" data-id="ea97123" data-element_type="widget" data-widget_type="button.default">
                                                                                <div class="elementor-widget-container">
                                                                                    <div class="elementor-button-wrapper">
                                                                                        <a class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="https://rr.top" target="_blank" rel="nofollow">
                                                                                            <span class="elementor-button-content-wrapper">
                                                                                                <span class="elementor-button-icon">
                                                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                                </span>
                                                                                                <span class="elementor-button-text">2025 抢百万蛇我其谁</span>
                                                                                            </span>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        
        <!-- data-elementor-type="popup" data-elementor-settings="{&quot;a11y_navigation&quot;:&quot;yes&quot;,&quot;timing&quot;:[]}" data-elementor-post-type="elementor_library"-->
        <div style="position:relative; width: 96%;
        height:96%;
        ">
            
            <div v-if="ismj"  data-elementor-id="34147" class="elementor elementor-34147 elementor-location-popup">
                <section style="position: relative;" class="elementor-section elementor-top-section elementor-element elementor-element-c00f95f elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="c00f95f" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                    <div class="elementor-container elementor-column-gap-wider">
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-36b04b5" data-id="36b04b5" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-f7ae7af elementor-widget elementor-widget-image" data-id="f7ae7af" data-element_type="widget" data-widget_type="image.default">
                                    <div class="elementor-widget-container">
                                        <img width="576" height="576" src="https://www.pgsoftgaming.com/wp-content/uploads/AnyConv.com__50c6fa4a-4661-4ac6-8c41-938c8eea873f.webp" class="attachment-large size-large wp-image-3068" alt=""/>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-4edc45b elementor-widget elementor-widget-text-editor" data-id="4edc45b" data-element_type="widget" data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">
                                        <p>PG电子推出了他们的第一款受麻将启发的老虎机游戏——“麻将胡了”！今年持续爆火，玩家每日在线人数不段上升，赶紧免费体验PG电子麻将胡了，将继续燃爆整个2023年。</p>
                                        <p>麻将胡了是一款强调视听特效的5轴、4行的电子老虎机游戏，具有Wild百搭符号转换和随乘数增加的免费旋转。</p>
                                        <p>
                                            当3个 &#8221;胡牌 &#8221;符号出现在任意位置，将触发免费旋转，赢得12次免费旋转。<span style="color: var( --e-global-color-accent ); font-family: var( --e-global-typography-text-font-family ), Sans-serif; font-weight: var( --e-global-typography-text-font-weight ); background-color: rgba(255, 255, 255, 0); font-size: 1rem;">立即免费试玩麻将胡了！您将</span>
                                            <span style="color: var( --e-global-color-accent ); font-family: var( --e-global-typography-text-font-family ), Sans-serif; font-weight: var( --e-global-typography-text-font-weight ); font-size: 1rem; background-color: rgba(255, 255, 255, 0);">体验分秒胡牌的快感！</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-3a6048c elementor-widget elementor-widget-shortcode" data-id="3a6048c" data-element_type="widget" data-widget_type="shortcode.default">
                                    <div class="elementor-widget-container">
                                        <div class="elementor-shortcode">
                                            <div data-elementor-type="section" data-elementor-id="34162" class="elementor elementor-34162 elementor-location-popup" data-elementor-post-type="elementor_library">
                                                <section class="elementor-section elementor-top-section elementor-element elementor-element-4bb59c9 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="4bb59c9" data-element_type="section">
                                                    <div class="elementor-container elementor-column-gap-default">
                                                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b3adb0e" data-id="b3adb0e" data-element_type="column">
                                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                                <div class="elementor-element elementor-element-561f099 elementor-widget elementor-widget-image" data-id="561f099" data-element_type="widget" data-widget_type="image.default">
                                                                    <div class="elementor-widget-container">
                                                                        <a href="https://e88553.com/qbgyh7" target="_blank" rel="nofollow">
                                                                            <img style="border-radius:5px" width="1280" height="420" src="../assets/xboxOne/1-3.jpg" class="elementor-animation-shrink attachment-large size-large wp-image-34895" alt=""  sizes="(max-width: 1280px) 100vw, 1280px"/>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-890df26" data-id="890df26" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div  class="elementor-element elementor-element-6711cf4 elementor-widget elementor-widget-html" data-id="6711cf4" data-element_type="widget" data-widget_type="html.default">
                                    <div class="elementor-widget-container">
                                        <!-- iframe plugin v.5.2 wordpress.org/plugins/iframe/ -->
                                        <iframe src="https://m.pgsoft-games.com/65/index.html?language=zh&bet_type=1&operator_token=ca7094186b309ee149c55c8822e7ecf2&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com" width="100%" height="800" scrolling="no" frameborder="0" marginheight="0" allowfullscreen="true" class="iframe-class"></iframe>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-a01cd93 elementor-widget elementor-widget-shortcode" data-id="a01cd93" data-element_type="widget" data-widget_type="shortcode.default">
                                    <div class="elementor-widget-container">
                                        <div class="elementor-shortcode">
                                            <div data-elementor-type="section" data-elementor-id="33905" class="elementor elementor-33905 elementor-location-popup" data-elementor-post-type="elementor_library">
                                                <section class="elementor-section elementor-top-section elementor-element elementor-element-2547e90 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="2547e90" data-element_type="section">
                                                    <div class="elementor-container elementor-column-gap-default">
                                                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b9c4a00" data-id="b9c4a00" data-element_type="column">
                                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                                <div class="elementor-element elementor-element-ea97123 elementor-widget elementor-widget-button" data-id="ea97123" data-element_type="widget" data-widget_type="button.default">
                                                                    <div class="elementor-widget-container">
                                                                        <div class="elementor-button-wrapper">
                                                                            <a class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="https://rr.top" target="_blank" rel="nofollow">
                                                                                <span class="elementor-button-content-wrapper">
                                                                                    <span class="elementor-button-icon">
                                                                                        <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                    </span>
                                                                                    <span class="elementor-button-text">2025 抢百万蛇我其谁</span>
                                                                                </span>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div v-if="ismj2" data-elementor-id="34153" class="elementor elementor-34153 elementor-location-popup"  data-elementor-post-type="elementor_library">
                <section class="elementor-section elementor-top-section elementor-element elementor-element-5fad7a6 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="5fad7a6" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                    <!-- <div @click="ismj2=false" style="z-index:9999999;cursor: pointer;position: absolute;;right:0;width:20px;height:20px;color:#230046;background:#F374DE;font-size:15px;font-weight:bold;text-align:center;line-height:20px;" >X</div> -->
                    <div class="elementor-container elementor-column-gap-wider">
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-dfc617f" data-id="dfc617f" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-11a035b elementor-widget elementor-widget-image" data-id="11a035b" data-element_type="widget" data-widget_type="image.default">
                                    <div class="elementor-widget-container">
                                        <img width="576" height="576" src="https://www.pgsoftgaming.com/wp-content/uploads/AnyConv.com__5ccb820b-44a0-4d93-96ad-6ca241b13127.webp" class="attachment-large size-large wp-image-3152" alt=""/>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-3362371 elementor-widget elementor-widget-text-editor" data-id="3362371" data-element_type="widget" data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">
                                        <p>PG电子继 &#8221;麻将胡了 &#8221;持续爆火，推出备受瞩目的“麻将胡了2”“麻将胡了2”是一款五轴四行的麻将游戏老虎机，本作加强了上代优点，期待在2023年能让喜爱麻将胡了的玩家更爱不释手，把把胡牌！</p>
                                        <p>游戏有百搭符号转换和免费旋转，并随着旋转次数增加而增加乘数。在免费旋转功能中，最高红利乘数可增至10倍，并且第三个轴上除百搭符号和散布符号之外的所有符号都会变成金色麻将符号。</p>
                                        <p>在得奖的旋转中，金色麻将符号也会转变成万能符号，让您轻松赢取奖励！现在就来免费体验“麻将胡了2”带来的无穷魅力，感受胡牌的快感吧！</p>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-643ce37 elementor-widget elementor-widget-shortcode" data-id="643ce37" data-element_type="widget" data-widget_type="shortcode.default">
                                    <div class="elementor-widget-container">
                                        <div class="elementor-shortcode">
                                            <div data-elementor-type="section" data-elementor-id="34162" class="elementor elementor-34162 elementor-location-popup" data-elementor-post-type="elementor_library">
                                                <section class="elementor-section elementor-top-section elementor-element elementor-element-4bb59c9 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="4bb59c9" data-element_type="section">
                                                    <div class="elementor-container elementor-column-gap-default">
                                                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b3adb0e" data-id="b3adb0e" data-element_type="column">
                                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                                <div class="elementor-element elementor-element-561f099 elementor-widget elementor-widget-image" data-id="561f099" data-element_type="widget" data-widget_type="image.default">
                                                                    <div class="elementor-widget-container">
                                                                        <a href="#" target="_blank" rel="nofollow">
                                                                            <img style="border-radius:5px" width="1280" height="420" src="../assets/xboxOne/1-3.jpg" class="elementor-animation-shrink attachment-large size-large wp-image-34895" alt=""  sizes="(max-width: 1280px) 100vw, 1280px"/>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div  class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-06cfa47" data-id="06cfa47" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-e92139b elementor-widget elementor-widget-html" data-id="e92139b" data-element_type="widget" data-widget_type="html.default">
                                    <div class="elementor-widget-container">
                                        <iframe src="https://m.pgsoft-games.com/74/index.html?language=zh&bet_type=1&operator_token=ca7094186b309ee149c55c8822e7ecf2&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com" width="100%" height="800" scrolling="no" frameborder="0" marginheight="0" allowfullscreen="true"></iframe>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-ae81a5a elementor-widget elementor-widget-shortcode" data-id="ae81a5a" data-element_type="widget" data-widget_type="shortcode.default">
                                    <div class="elementor-widget-container">
                                        <div class="elementor-shortcode">
                                            <div data-elementor-type="section" data-elementor-id="33905" class="elementor elementor-33905 elementor-location-popup" data-elementor-post-type="elementor_library">
                                                <section class="elementor-section elementor-top-section elementor-element elementor-element-2547e90 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="2547e90" data-element_type="section">
                                                    <div class="elementor-container elementor-column-gap-default">
                                                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b9c4a00" data-id="b9c4a00" data-element_type="column">
                                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                                <div class="elementor-element elementor-element-ea97123 elementor-widget elementor-widget-button" data-id="ea97123" data-element_type="widget" data-widget_type="button.default">
                                                                    <div class="elementor-widget-container">
                                                                        <div class="elementor-button-wrapper">
                                                                            <a class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="https://rr.top" target="_blank" rel="nofollow">
                                                                                <span class="elementor-button-content-wrapper">
                                                                                    <span class="elementor-button-icon">
                                                                                        <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                    </span>
                                                                                    <span class="elementor-button-text">2025 抢百万蛇我其谁</span>
                                                                                </span>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div v-if="issjnw" data-elementor-id="34170" class="elementor elementor-34170 elementor-location-popup" data-elementor-settings="{&quot;a11y_navigation&quot;:&quot;yes&quot;,&quot;timing&quot;:[]}" data-elementor-post-type="elementor_library">
                <section class="elementor-section elementor-top-section elementor-element elementor-element-88f23a6 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="88f23a6" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                    <!-- <div @click="issjnw=false" style="z-index:9999999;cursor: pointer;position: absolute;;right:0;width:20px;height:20px;color:#230046;background:#F374DE;font-size:15px;font-weight:bold;text-align:center;line-height:20px;" >X</div> -->
                    <div class="elementor-container elementor-column-gap-wider">
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-9be19b8" data-id="9be19b8" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-8d8a4d7 elementor-widget elementor-widget-image" data-id="8d8a4d7" data-element_type="widget" data-widget_type="image.default">
                                    <div class="elementor-widget-container">
                                        <img width="576" height="576" src="../assets/xboxOne/37bb5b16-15ca-487b-818f-071d9c998f10.png" class="attachment-large size-large wp-image-3910" alt=""/>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-fcf4627 elementor-widget elementor-widget-text-editor" data-id="fcf4627" data-element_type="widget" data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">
                                        <p>臭名昭著的海盗黑胡子在一次劫船事件中不幸身亡，他的妻子格蕾丝继承了他的全部财产。格蕾丝是七海中最强大的海洋家族的女儿。所有通过其领海的船只都需要向其纳税。这就是她被授予“七海女王”称号的原因！</p>
                                        <p>根据传说，格蕾丝还继承了黑胡子的藏宝图。多年来，许多人都觊觎着这张珍贵的地图，寻找黑胡子生前藏起来的无数奇珍异宝！然而，要从女王那里获得地图可不是一件容易的事！接受挑战，成为宝藏的新主人！</p>
                                        <p>“赏金女王”是一个5卷、3排的视频老虎机，具有级联符号、增加的乘数和免费旋转选择。所有的胜利都会增加乘数！当出现3个或更多散射符号时，触发自由旋转功能！</p>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-c86334f elementor-widget elementor-widget-shortcode" data-id="c86334f" data-element_type="widget" data-widget_type="shortcode.default">
                                    <div class="elementor-widget-container">
                                        <div class="elementor-shortcode">
                                            <div data-elementor-type="section" data-elementor-id="34162" class="elementor elementor-34162 elementor-location-popup" data-elementor-post-type="elementor_library">
                                                <section class="elementor-section elementor-top-section elementor-element elementor-element-4bb59c9 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="4bb59c9" data-element_type="section">
                                                    <div class="elementor-container elementor-column-gap-default">
                                                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b3adb0e" data-id="b3adb0e" data-element_type="column">
                                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                                <div class="elementor-element elementor-element-561f099 elementor-widget elementor-widget-image" data-id="561f099" data-element_type="widget" data-widget_type="image.default">
                                                                    <div class="elementor-widget-container">
                                                                        <a href="#" target="_blank" rel="nofollow">
                                                                            <img style="border-radius:5px" width="1280" height="420" src="../assets/xboxOne/1-3.jpg" class="elementor-animation-shrink attachment-large size-large wp-image-34895" alt=""  sizes="(max-width: 1280px) 100vw, 1280px"/>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-25dfaf0" data-id="25dfaf0" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div  class="elementor-element elementor-element-7e8a154 elementor-widget elementor-widget-html" data-id="7e8a154" data-element_type="widget" data-widget_type="html.default">
                                    <div class="elementor-widget-container">
                                        <!-- iframe plugin v.5.2 wordpress.org/plugins/iframe/ -->
                                        <iframe src="https://m.pgsoft-games.com/84/index.html?language=zh&bet_type=1&operator_token=ca7094186b309ee149c55c8822e7ecf2&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com" width="100%" height="800" scrolling="no" frameborder="0" marginheight="0" allowfullscreen="true" class="iframe-class"></iframe>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-bc92b45 elementor-widget elementor-widget-shortcode" data-id="bc92b45" data-element_type="widget" data-widget_type="shortcode.default">
                                    <div class="elementor-widget-container">
                                        <div class="elementor-shortcode">
                                            <div data-elementor-type="section" data-elementor-id="33905" class="elementor elementor-33905 elementor-location-popup" data-elementor-post-type="elementor_library">
                                                <section class="elementor-section elementor-top-section elementor-element elementor-element-2547e90 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="2547e90" data-element_type="section">
                                                    <div class="elementor-container elementor-column-gap-default">
                                                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b9c4a00" data-id="b9c4a00" data-element_type="column">
                                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                                <div class="elementor-element elementor-element-ea97123 elementor-widget elementor-widget-button" data-id="ea97123" data-element_type="widget" data-widget_type="button.default">
                                                                    <div class="elementor-widget-container">
                                                                        <div class="elementor-button-wrapper">
                                                                            <a class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="https://rr.top" target="_blank" rel="nofollow">
                                                                                <span class="elementor-button-content-wrapper">
                                                                                    <span class="elementor-button-icon">
                                                                                        <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                    </span>
                                                                                    <span class="elementor-button-text">2025 抢百万蛇我其谁</span>
                                                                                </span>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div v-if="ishjyqs" data-elementor-id="34176" class="elementor elementor-34176 elementor-location-popup" data-elementor-settings="{&quot;a11y_navigation&quot;:&quot;yes&quot;,&quot;timing&quot;:[]}" data-elementor-post-type="elementor_library">
                <section class="elementor-section elementor-top-section elementor-element elementor-element-cc95bcd elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="cc95bcd" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                    <!-- <div @click="ishjyqs=false" style="z-index:9999999;cursor: pointer;position: absolute;;right:0;width:20px;height:20px;color:#230046;background:#F374DE;font-size:15px;font-weight:bold;text-align:center;line-height:20px;" >X</div> -->
                    <div class="elementor-container elementor-column-gap-wider">
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-5a45115" data-id="5a45115" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-45b6ced elementor-widget elementor-widget-image" data-id="45b6ced" data-element_type="widget" data-widget_type="image.default">
                                    <div class="elementor-widget-container">
                                        <img width="2084" height="1360" src="https://www.pgsoftgaming.com/wp-content/uploads/AnyConv.com__Game-Banner-BG-1_2x27a7fdc.webp" class="attachment-large size-large wp-image-3462" alt="" srcset="https://www.pgsoftgaming.com/wp-content/uploads/AnyConv.com__Game-Banner-BG-1_2x27a7fdc.webp 2084w, https://www.pgsoftgaming.com/wp-content/uploads/AnyConv.com__Game-Banner-BG-1_2x27a7fdc-768x501.webp 768w, https://www.pgsoftgaming.com/wp-content/uploads/AnyConv.com__Game-Banner-BG-1_2x27a7fdc-1536x1002.webp 1536w, https://www.pgsoftgaming.com/wp-content/uploads/AnyConv.com__Game-Banner-BG-1_2x27a7fdc-2048x1337.webp 2048w" sizes="(max-width: 2084px) 100vw, 2084px"/>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-d8fa0cc elementor-widget elementor-widget-text-editor" data-id="d8fa0cc" data-element_type="widget" data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">
                                        <p>
                                            最热门的游戏“摇钱树”的续集——“黄金摇钱树”终于上线了！<br/>很久以前，一位农夫在神灵的指引下种下了一颗神奇的种子。这颗种子长成了一棵高耸入云的财源树，内含无尽的财富金元宝，让农夫获得了无限的财富。然而，随着时间的流逝，人们已经失去了这棵树的具体位置。经过岁月的沉淀，这棵树变得更加高大，财富也更加丰厚。现在赶快打开“黄金摇钱树”寻找这棵财源树，让无限的财富降临于你吧！
                                        </p>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-a3c26b9 elementor-widget elementor-widget-shortcode" data-id="a3c26b9" data-element_type="widget" data-widget_type="shortcode.default">
                                    <div class="elementor-widget-container">
                                        <div class="elementor-shortcode">
                                            <div data-elementor-type="section" data-elementor-id="34162" class="elementor elementor-34162 elementor-location-popup" data-elementor-post-type="elementor_library">
                                                <section class="elementor-section elementor-top-section elementor-element elementor-element-4bb59c9 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="4bb59c9" data-element_type="section">
                                                    <div class="elementor-container elementor-column-gap-default">
                                                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b3adb0e" data-id="b3adb0e" data-element_type="column">
                                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                                <div class="elementor-element elementor-element-561f099 elementor-widget elementor-widget-image" data-id="561f099" data-element_type="widget" data-widget_type="image.default">
                                                                    <div class="elementor-widget-container">
                                                                        <a href="https://e88553.com/qbgyh7" target="_blank" rel="nofollow">
                                                                            <img style="border-radius:5px" width="1280" height="420" src="../assets/xboxOne/1-3.jpg" class="elementor-animation-shrink attachment-large size-large wp-image-34895" alt=""  sizes="(max-width: 1280px) 100vw, 1280px"/>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-956a8f2" data-id="956a8f2" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-3eb9b4e elementor-widget elementor-widget-html" data-id="3eb9b4e" data-element_type="widget" data-widget_type="html.default">
                                    <div class="elementor-widget-container">
                                        <iframe src="https://m.pgsoft-games.com/1312883/index.html?language=zh&bet_type=1&operator_token=ca7094186b309ee149c55c8822e7ecf2&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com" width="100%" height="800" scrolling="no" frameborder="0" marginheight="0" allowfullscreen="true"></iframe>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-f323d06 elementor-widget elementor-widget-shortcode" data-id="f323d06" data-element_type="widget" data-widget_type="shortcode.default">
                                    <div class="elementor-widget-container">
                                        <div class="elementor-shortcode">
                                            <div data-elementor-type="section" data-elementor-id="33905" class="elementor elementor-33905 elementor-location-popup" data-elementor-post-type="elementor_library">
                                                <section class="elementor-section elementor-top-section elementor-element elementor-element-2547e90 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="2547e90" data-element_type="section">
                                                    <div class="elementor-container elementor-column-gap-default">
                                                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b9c4a00" data-id="b9c4a00" data-element_type="column">
                                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                                <div class="elementor-element elementor-element-ea97123 elementor-widget elementor-widget-button" data-id="ea97123" data-element_type="widget" data-widget_type="button.default">
                                                                    <div class="elementor-widget-container">
                                                                        <div class="elementor-button-wrapper">
                                                                            <a class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="https://rr.top" target="_blank" rel="nofollow">
                                                                                <span class="elementor-button-content-wrapper">
                                                                                    <span class="elementor-button-icon">
                                                                                        <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                    </span>
                                                                                    <span class="elementor-button-text">2025 抢百万蛇我其谁</span>
                                                                                </span>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div v-if="isrz" data-elementor-id="34209" class="elementor elementor-34209 elementor-location-popup" data-elementor-settings="{&quot;a11y_navigation&quot;:&quot;yes&quot;,&quot;timing&quot;:[]}" data-elementor-post-type="elementor_library">
                <section class="elementor-section elementor-top-section elementor-element elementor-element-98cff0d elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="98cff0d" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                    <!-- <div @click="isrz=false" style="z-index:9999999;cursor: pointer;position: absolute;;right:0;width:20px;height:20px;color:#230046;background:#F374DE;font-size:15px;font-weight:bold;text-align:center;line-height:20px;" >X</div> -->
                    <div class="elementor-container elementor-column-gap-wider">
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-8fdc295" data-id="8fdc295" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-1e3e8a6 elementor-widget elementor-widget-image" data-id="1e3e8a6" data-element_type="widget" data-widget_type="image.default">
                                    <div class="elementor-widget-container">
                                        <img width="576" height="576" src="https://www.pgsoftgaming.com/wp-content/uploads/AnyConv.com__3c77c29a-9afa-4a9d-b28d-bfe74171374d.webp" class="attachment-large size-large wp-image-3150" alt=""/>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-7d4674e elementor-widget elementor-widget-text-editor" data-id="7d4674e" data-element_type="widget" data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">
                                        <p>在日本战国时期，有能力负担的富裕家庭雇佣雇佣兵以自保为常见做法。武士和忍者都希望被选中，为他们的家族赢得荣耀、尊重和财富。由于两个氏族之间的不断争执，武士和忍者每天都在战斗，展示他们的力量和技能。武士以剑术和蛮力自豪，而忍者则在最意想不到的地方提供秘密和隐蔽的保护。</p>
                                        <p>“忍者vs武士”是一款5轴3行的视频老虎机，具有带乘数和Wild符号的免费旋转功能。当3个Ninja Scatter符号和/或Samurai Scatter符号出现在转轴2、3和4的任意位置时，将触发9次免费旋转。进入Ninja Samurai Feature免费旋转时，您的赢奖将成倍增加，转轴1至5将被选择填充Wild符号。</p>
                                        <p>武士能否战胜忍者？或者忍者能否在睡梦中暗杀他们？</p>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-c553274 elementor-widget elementor-widget-shortcode" data-id="c553274" data-element_type="widget" data-widget_type="shortcode.default">
                                    <div class="elementor-widget-container">
                                        <div class="elementor-shortcode">
                                            <div data-elementor-type="section" data-elementor-id="34162" class="elementor elementor-34162 elementor-location-popup" data-elementor-post-type="elementor_library">
                                                <section class="elementor-section elementor-top-section elementor-element elementor-element-4bb59c9 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="4bb59c9" data-element_type="section">
                                                    <div class="elementor-container elementor-column-gap-default">
                                                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b3adb0e" data-id="b3adb0e" data-element_type="column">
                                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                                <div class="elementor-element elementor-element-561f099 elementor-widget elementor-widget-image" data-id="561f099" data-element_type="widget" data-widget_type="image.default">
                                                                    <div class="elementor-widget-container">
                                                                        <a href="#" target="_blank" rel="nofollow">
                                                                            <img style="border-radius:5px" width="1280" height="420" src="../assets/xboxOne/1-3.jpg" class="elementor-animation-shrink attachment-large size-large wp-image-34895" alt=""  sizes="(max-width: 1280px) 100vw, 1280px"/>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-fc41e0e" data-id="fc41e0e" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-8727020 elementor-widget elementor-widget-html" data-id="8727020" data-element_type="widget" data-widget_type="html.default">
                                    <div class="elementor-widget-container">
                                        <iframe src="https://m.pgsoft-games.com/59/index.html?language=zh&bet_type=1&operator_token=ca7094186b309ee149c55c8822e7ecf2&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com" width="100%" height="800" scrolling="no" frameborder="0" marginheight="0" allowfullscreen="true"></iframe>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-f1226bb elementor-widget elementor-widget-shortcode" data-id="f1226bb" data-element_type="widget" data-widget_type="shortcode.default">
                                    <div class="elementor-widget-container">
                                        <div class="elementor-shortcode">
                                            <div data-elementor-type="section" data-elementor-id="33905" class="elementor elementor-33905 elementor-location-popup" data-elementor-post-type="elementor_library">
                                                <section class="elementor-section elementor-top-section elementor-element elementor-element-2547e90 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="2547e90" data-element_type="section">
                                                    <div class="elementor-container elementor-column-gap-default">
                                                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b9c4a00" data-id="b9c4a00" data-element_type="column">
                                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                                <div class="elementor-element elementor-element-ea97123 elementor-widget elementor-widget-button" data-id="ea97123" data-element_type="widget" data-widget_type="button.default">
                                                                    <div class="elementor-widget-container">
                                                                        <div class="elementor-button-wrapper">
                                                                            <a class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="https://rr.top" target="_blank" rel="nofollow">
                                                                                <span class="elementor-button-content-wrapper">
                                                                                    <span class="elementor-button-icon">
                                                                                        <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                    </span>
                                                                                    <span class="elementor-button-text">2025 抢百万蛇我其谁</span>
                                                                                </span>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div v-if="iswldd" data-elementor-id="34216" class="elementor elementor-34216 elementor-location-popup" data-elementor-settings="{&quot;a11y_navigation&quot;:&quot;yes&quot;,&quot;timing&quot;:[]}" data-elementor-post-type="elementor_library">
                <section class="elementor-section elementor-top-section elementor-element elementor-element-2be5024 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="2be5024" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                    <!-- <div @click="iswldd=false" style="z-index:9999999;cursor: pointer;position: absolute;;right:0;width:20px;height:20px;color:#230046;background:#F374DE;font-size:15px;font-weight:bold;text-align:center;line-height:20px;" >X</div> -->
                    <div class="elementor-container elementor-column-gap-wider">
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-0f9f483" data-id="0f9f483" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-b26ab26 elementor-widget elementor-widget-image" data-id="b26ab26" data-element_type="widget" data-widget_type="image.default">
                                    <div class="elementor-widget-container">
                                        <img width="576" height="576" src="https://www.pgsoftgaming.com/wp-content/uploads/AnyConv.com__261f9206-3198-4b16-9a1e-cf6bc3280062.webp" class="attachment-large size-large wp-image-3156" alt=""/>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-9970caa elementor-widget elementor-widget-text-editor" data-id="9970caa" data-element_type="widget" data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">
                                        <p>“Águila”意为“鹰”，是一支由三个墨西哥强盗组成的团队，活跃于墨西哥边境地区。他们假扮成一支巡回的Mariachi乐队，在白天从城市到城市表演。到了晚上，他们就会抢劫金矿并卖掉黄金，赚取大量的钱。</p>
                                        <p>据说他们像一种灵魂，动作如此之快，如此之静，没有人能够看到他们的动作，而且在眨眼之间所有黄金就会消失。墨西哥州长已经悬赏3000万现金捉拿“Águila”，不论死活。然而，这并不能阻止他们，因为没有人知道他们藏身之处。</p>
                                        <p>镇上的人曾经说过，当你在晚上听到guitarróni和vihuela的音乐时，就意味着“Águila”在行动了。音乐将引导你找到他们！如果你能够将他们全部捉拿归案，那么这3000万现金奖励将属于你！</p>
                                        <p>亡灵大盗是一个5卷、4行的视频老虎机，具有金框符号和递增倍数的免费旋转。在免费旋转功能中，第3卷中的所有符号（除了野外符号和散布符号）将出现为金框符号，增加了你获得高赔率的机会！当卷轴上有一个或多个获胜符号时，赢得的乘数将增加1！</p>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-8f77fe0 elementor-widget elementor-widget-shortcode" data-id="8f77fe0" data-element_type="widget" data-widget_type="shortcode.default">
                                    <div class="elementor-widget-container">
                                        <div class="elementor-shortcode">
                                            <div data-elementor-type="section" data-elementor-id="34162" class="elementor elementor-34162 elementor-location-popup" data-elementor-post-type="elementor_library">
                                                <section class="elementor-section elementor-top-section elementor-element elementor-element-4bb59c9 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="4bb59c9" data-element_type="section">
                                                    <div class="elementor-container elementor-column-gap-default">
                                                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b3adb0e" data-id="b3adb0e" data-element_type="column">
                                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                                <div class="elementor-element elementor-element-561f099 elementor-widget elementor-widget-image" data-id="561f099" data-element_type="widget" data-widget_type="image.default">
                                                                    <div class="elementor-widget-container">
                                                                        <a href="https://e88553.com/qbgyh7" target="_blank" rel="nofollow">
                                                                            <img style="border-radius:5px" width="1280" height="420" src="../assets/xboxOne/1-3.jpg" class="elementor-animation-shrink attachment-large size-large wp-image-34895" alt=""  sizes="(max-width: 1280px) 100vw, 1280px"/>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-6ca6103" data-id="6ca6103" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-6f23893 elementor-widget elementor-widget-html" data-id="6f23893" data-element_type="widget" data-widget_type="html.default">
                                    <div class="elementor-widget-container">
                                        <iframe src="https://m.pgsoft-games.com/104/index.html?language=zh&bet_type=1&operator_token=ca7094186b309ee149c55c8822e7ecf2&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com" width="100%" height="800" scrolling="no" frameborder="0" marginheight="0" allowfullscreen="true"></iframe>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-b29fe88 elementor-widget elementor-widget-shortcode" data-id="b29fe88" data-element_type="widget" data-widget_type="shortcode.default">
                                    <div class="elementor-widget-container">
                                        <div class="elementor-shortcode">
                                            <div data-elementor-type="section" data-elementor-id="33905" class="elementor elementor-33905 elementor-location-popup" data-elementor-post-type="elementor_library">
                                                <section class="elementor-section elementor-top-section elementor-element elementor-element-2547e90 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="2547e90" data-element_type="section">
                                                    <div class="elementor-container elementor-column-gap-default">
                                                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b9c4a00" data-id="b9c4a00" data-element_type="column">
                                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                                <div class="elementor-element elementor-element-ea97123 elementor-widget elementor-widget-button" data-id="ea97123" data-element_type="widget" data-widget_type="button.default">
                                                                    <div class="elementor-widget-container">
                                                                        <div class="elementor-button-wrapper">
                                                                            <a class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="https://rr.top" target="_blank" rel="nofollow">
                                                                                <span class="elementor-button-content-wrapper">
                                                                                    <span class="elementor-button-icon">
                                                                                        <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                    </span>
                                                                                    <span class="elementor-button-text">2025 抢百万蛇我其谁</span>
                                                                                </span>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div v-if="isslzq" data-elementor-id="34233" class="elementor elementor-34233 elementor-location-popup" data-elementor-settings="{&quot;a11y_navigation&quot;:&quot;yes&quot;,&quot;timing&quot;:[]}" data-elementor-post-type="elementor_library">
                <section class="elementor-section elementor-top-section elementor-element elementor-element-8ba40fc elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="8ba40fc" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                    <!-- <div @click="isslzq=false" style="z-index:9999999;cursor: pointer;position: absolute;;right:0;width:20px;height:20px;color:#230046;background:#F374DE;font-size:15px;font-weight:bold;text-align:center;line-height:20px;" >X</div> -->
                    <div class="elementor-container elementor-column-gap-wider">
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b80eb75" data-id="b80eb75" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-25a7082 elementor-widget elementor-widget-image" data-id="25a7082" data-element_type="widget" data-widget_type="image.default">
                                    <div class="elementor-widget-container">
                                        <img width="576" height="576" src="https://www.pgsoftgaming.com/wp-content/uploads/少林足球-1.png" class="attachment-large size-large wp-image-34236" alt=""/>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-d775169 elementor-widget elementor-widget-text-editor" data-id="d775169" data-element_type="widget" data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">
                                        <p>“少林队”，一支前所未见的足球队，是一群武林少年在失去斗志后，决定将功夫与足球结合起来而诞生的。 “威武钢腿”将带领“闪电鬼手”门将、“铁头”头球、“失重”边锋，征战全国足坛争冠！</p>
                                        <p>受中国著名同名电影的启发，“少林足球”是一个 5 轴、4 行的视频老虎机，具有符号转换和免费旋转功能中的 5 种符号选择。 当 4 个相同的玩家符号出现在第三个卷轴上时，在玩家转换功能中将所有玩家符号转换为相同的玩家符号。 此外，从 5 个球员符号中选择您最喜欢的球员，在免费旋转功能中玩免费旋转！</p>
                                        <p>“没有梦想，你和咸鱼没什么区别。” 快来加入“少林队”，与最有激情的球员一起体验少林足球梦！</p>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-f8c2721 elementor-widget elementor-widget-shortcode" data-id="f8c2721" data-element_type="widget" data-widget_type="shortcode.default">
                                    <div class="elementor-widget-container">
                                        <div class="elementor-shortcode">
                                            <div data-elementor-type="section" data-elementor-id="34162" class="elementor elementor-34162 elementor-location-popup" data-elementor-post-type="elementor_library">
                                                <section class="elementor-section elementor-top-section elementor-element elementor-element-4bb59c9 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="4bb59c9" data-element_type="section">
                                                    <div class="elementor-container elementor-column-gap-default">
                                                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b3adb0e" data-id="b3adb0e" data-element_type="column">
                                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                                <div class="elementor-element elementor-element-561f099 elementor-widget elementor-widget-image" data-id="561f099" data-element_type="widget" data-widget_type="image.default">
                                                                    <div class="elementor-widget-container">
                                                                        <a href="https://e88553.com/qbgyh7" target="_blank" rel="nofollow">
                                                                            <img style="border-radius:5px" width="1280" height="420" src="../assets/xboxOne/1-3.jpg" class="elementor-animation-shrink attachment-large size-large wp-image-34895" alt=""  sizes="(max-width: 1280px) 100vw, 1280px"/>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-5f5696e" data-id="5f5696e" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-6a5bdf9 elementor-widget elementor-widget-html" data-id="6a5bdf9" data-element_type="widget" data-widget_type="html.default">
                                    <div class="elementor-widget-container">
                                        <iframe src="https://m.pgsoft-games.com/67/index.html?language=zh&bet_type=1&operator_token=ca7094186b309ee149c55c8822e7ecf2&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com" width="100%" height="800" scrolling="no" frameborder="0" marginheight="0" allowfullscreen="true" />
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-68d65cb elementor-widget elementor-widget-shortcode" data-id="68d65cb" data-element_type="widget" data-widget_type="shortcode.default">
                                    <div class="elementor-widget-container">
                                        <div class="elementor-shortcode">
                                            <div data-elementor-type="section" data-elementor-id="33905" class="elementor elementor-33905 elementor-location-popup" data-elementor-post-type="elementor_library">
                                                <section class="elementor-section elementor-top-section elementor-element elementor-element-2547e90 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="2547e90" data-element_type="section">
                                                    <div class="elementor-container elementor-column-gap-default">
                                                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b9c4a00" data-id="b9c4a00" data-element_type="column">
                                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                                <div class="elementor-element elementor-element-ea97123 elementor-widget elementor-widget-button" data-id="ea97123" data-element_type="widget" data-widget_type="button.default">
                                                                    <div class="elementor-widget-container">
                                                                        <div class="elementor-button-wrapper">
                                                                            <a class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="https://rr.top" target="_blank" rel="nofollow">
                                                                                <span class="elementor-button-content-wrapper">
                                                                                    <span class="elementor-button-icon">
                                                                                        <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                    </span>
                                                                                    <span class="elementor-button-text">2025 抢百万蛇我其谁</span>
                                                                                </span>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div v-if="istys" data-elementor-id="34023" class="elementor elementor-34023 elementor-location-popup" data-elementor-settings="{&quot;a11y_navigation&quot;:&quot;yes&quot;,&quot;timing&quot;:[]}" data-elementor-post-type="elementor_library">
                <section class="elementor-section elementor-top-section elementor-element elementor-element-5193cd6 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="5193cd6" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                    <!-- <div @click="istys=false" style="z-index:9999999;cursor: pointer;position: absolute;;right:0;width:20px;height:20px;color:#230046;background:#F374DE;font-size:15px;font-weight:bold;text-align:center;line-height:20px;" >X</div> -->
                    <div class="elementor-container elementor-column-gap-wider">
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-da5962b" data-id="da5962b" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-8cb2a00 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="8cb2a00" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-b6e075c" data-id="b6e075c" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-5226a2d elementor-widget elementor-widget-image" data-id="5226a2d" data-element_type="widget" data-widget_type="image.default">
                                                    <div class="elementor-widget-container">
                                                        <img width="1626" height="2075" src="../assets/xboxOne/rise-of-apollo_character.png" class="attachment-large size-large wp-image-3677" alt=""  sizes="(max-width: 1626px) 100vw, 1626px"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-85c09ec elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="85c09ec" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-1388658" data-id="1388658" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-40126fa elementor-widget elementor-widget-text-editor" data-id="40126fa" data-element_type="widget" data-widget_type="text-editor.default">
                                                    <div class="elementor-widget-container">
                                                        <p>《太阳神传说》是一款由PG Soft开发的富有创意的在线游戏。在这个游戏中，玩家将进入一个神秘的古埃及世界，追寻太阳神阿波罗的神秘之旅。</p>
                                                        <p>游戏以华丽的图形和引人入胜的音效为特点，让玩家沉浸在古埃及的神秘氛围中。在游戏中，玩家将与太阳神阿波罗一同探险，解锁珍贵的文物和神秘的宝藏。玩家需要在卷轴上旋转古埃及图案的组合，以获得丰厚的奖励。</p>
                                                        <p>《太阳神传说》还具有多个特殊功能，如免费旋转、扩展符号和随机奖励，为玩家带来更多的刺激和惊喜。此外，游戏还支持在移动设备上进行游戏，方便玩家在任何时间和地点都能够享受游戏的乐趣。</p>
                                                        <p>在《太阳神传说》中，玩家将体验到一个充满神秘和刺激的冒险，解锁古埃及的宝藏，追寻太阳神的足迹，成为真正的探险家。</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-bd26fdd" data-id="bd26fdd" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div style="height:92%" class="elementor-element elementor-element-2d8c03a elementor-widget elementor-widget-html" data-id="2d8c03a" data-element_type="widget" data-widget_type="html.default">
                                    <div class="elementor-widget-container">
                                        <!-- iframe plugin v.5.2 wordpress.org/plugins/iframe/ -->
                                        <!-- <iframe src="https://m.pgsoft-games.com/101/index.html?language=zh&#038;bet_type=1&#038;operator_token=ca7094186b309ee149c55c8822e7ecf2&#038;btt=2&#038;__refer=m.pg-redirect.net&#038;or=static.pgsoft-games.com&#038;btt=2&#038;__refer=m.pg-redirect.net&#038;or=static.pgsoft-games.com" width="100%" height="800" scrolling="no" frameborder="0" marginheight="0" allowfullscreen="true" class="iframe-class"></iframe> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div v-if="isbscq" data-elementor-id="34033" class="elementor elementor-34033 elementor-location-popup" data-elementor-settings="{&quot;a11y_navigation&quot;:&quot;yes&quot;,&quot;timing&quot;:[]}" data-elementor-post-type="elementor_library">
                <section class="elementor-section elementor-top-section elementor-element elementor-element-27d3fad elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="27d3fad" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                    <!-- <div @click="isbscq=false" style="z-index:9999999;cursor: pointer;position: absolute;;right:0;width:20px;height:20px;color:#230046;background:#F374DE;font-size:15px;font-weight:bold;text-align:center;line-height:20px;" >X</div> -->
                    <div class="elementor-container elementor-column-gap-wider">
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-d73f39d" data-id="d73f39d" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-f282ce0 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="f282ce0" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-508cd8e" data-id="508cd8e" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-2da16b1 elementor-widget elementor-widget-image" data-id="2da16b1" data-element_type="widget" data-widget_type="image.default">
                                                    <div class="elementor-widget-container">
                                                        <img width="576" height="576" src="../assets/xboxOne/d316c201-c5d4-406d-811a-cdd4c9f9846f.png" class="attachment-large size-large wp-image-3856" alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-f47a6b1 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="f47a6b1" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-e11f9c7" data-id="e11f9c7" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-7e23e2d elementor-widget elementor-widget-text-editor" data-id="7e23e2d" data-element_type="widget" data-widget_type="text-editor.default">
                                                    <div class="elementor-widget-container">
                                                        <p>卡其布王国以其唯一的产品——钻石而闻名，钻石是世界上最珍贵、最闪闪发光的宝石。任何经过这个王国的人都会被他们覆盖着浅海蓝宝石的奢华宫殿所吸引。数千名技艺精湛的工匠在上面工作了数百天，制作出了令人惊叹的宫殿墙壁。在宫殿内部，从长走廊的楼梯到餐厅的餐具，都用钻石装饰。这样的财富展示是为了向所有人表明，卡其斯王国比这片土地上的任何其他王国都要富有！然而，有传言称，宫殿深处隐藏着一个秘密金库。隐藏在里面的是世界上最昂贵的钻石！当然，这颗珍贵的钻石已经被陷阱包围，以保护它！</p>
                                                        <p></p>
                                                        <p>宝石传奇是一款5乘5符号的组合视频老虎机，具有狂野符号转换、扑克符号删除和免费旋转乘数功能。水平或垂直接触的4个或更多符号的任何组合都将在每次旋转时产生获胜组合。在自由旋转功能期间，胜利将根据在自由旋转中收集的获胜符号数量相乘。你赢得越多，你的获胜倍数就越高！</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3a496b4" data-id="3a496b4" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-7e82ea7 elementor-widget elementor-widget-html" data-id="7e82ea7" data-element_type="widget" data-widget_type="html.default">
                                    <div class="elementor-widget-container">
                                        <!-- iframe plugin v.5.2 wordpress.org/plugins/iframe/ -->
                                        <iframe src="https://m.pgsoft-games.com/95/index.html?language=zh&bet_type=1&operator_token=ca7094186b309ee149c55c8822e7ecf2&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com" width="100%" height="800" scrolling="no" frameborder="0" marginheight="0" allowfullscreen="true" class="iframe-class"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div v-if="iskl" data-elementor-id="34046" class="elementor elementor-34046 elementor-location-popup" data-elementor-settings="{&quot;a11y_navigation&quot;:&quot;yes&quot;,&quot;timing&quot;:[]}" data-elementor-post-type="elementor_library">
                <section class="elementor-section elementor-top-section elementor-element elementor-element-f1fa18e elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="f1fa18e" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                    <!-- <div @click="iskl=false" style="z-index:9999999;cursor: pointer;position: absolute;;right:0;width:20px;height:20px;color:#230046;background:#F374DE;font-size:15px;font-weight:bold;text-align:center;line-height:20px;" >X</div> -->
                    <div class="elementor-container elementor-column-gap-wider">
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-89c3339" data-id="89c3339" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-6d88cc2 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="6d88cc2" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-31d9b3a" data-id="31d9b3a" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-82012bb elementor-widget elementor-widget-image" data-id="82012bb" data-element_type="widget" data-widget_type="image.default">
                                                    <div class="elementor-widget-container">
                                                        <img width="600" height="976" src="../assets/xboxOne/jurassic-kingdom_t-rex5.png" class="attachment-large size-large wp-image-3663" alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-9245d64 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="9245d64" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-66d65ce" data-id="66d65ce" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-c93f7a7 elementor-widget elementor-widget-text-editor" data-id="c93f7a7" data-element_type="widget" data-widget_type="text-editor.default">
                                                    <div class="elementor-widget-container">
                                                        <p>《恐龙帝国》是一款充满创意和刺激的在线游戏。在这个游戏中，玩家将被带回到史前时代，与各种各样的恐龙互动。游戏拥有令人印象深刻的高质量图形和音效，为玩家营造了一个逼真的恐龙世界。</p>
                                                        <p>《恐龙帝国》提供了丰富多样的游戏玩法，包括充满想象力的特殊功能。例如，玩家可以通过触发恐龙的特殊技能来获得额外的奖励，或者在充满悬念的猎人和猎物游戏中与其他玩家竞争。此外，游戏还有多个不同类型的免费游戏轮，让玩家享受更多的赢取机会。</p>
                                                        <p>游戏界面简洁明了，容易上手，同时也支持多种语言，让全球玩家都能轻松畅玩。此外，游戏还采用了先进的随机数生成器，确保公正和公平的游戏体验。</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-79d0a9a" data-id="79d0a9a" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-1af6a35 elementor-widget elementor-widget-html" data-id="1af6a35" data-element_type="widget" data-widget_type="html.default">
                                    <div class="elementor-widget-container">
                                        <!-- iframe plugin v.5.2 wordpress.org/plugins/iframe/ -->
                                        <iframe src="https://m.pgsoft-games.com/110/index.html?language=zh&bet_type=1&operator_token=ca7094186b309ee149c55c8822e7ecf2&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com" width="100%" height="800" scrolling="no" frameborder="0" marginheight="0" allowfullscreen="true" class="iframe-class"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div v-if="iszcm" data-elementor-id="34064" class="elementor elementor-34064 elementor-location-popup" data-elementor-settings="{&quot;a11y_navigation&quot;:&quot;yes&quot;,&quot;timing&quot;:[]}" data-elementor-post-type="elementor_library">
                <section class="elementor-section elementor-top-section elementor-element elementor-element-96bb57e elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="96bb57e" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                    <!-- <div @click="iszcm=false" style="z-index:9999999;cursor: pointer;position: absolute;;right:0;width:20px;height:20px;color:#230046;background:#F374DE;font-size:15px;font-weight:bold;text-align:center;line-height:20px;" >X</div> -->
                    <div class="elementor-container elementor-column-gap-wider">
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-c7d61fa" data-id="c7d61fa" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-28d87ef elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="28d87ef" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-1028b5f" data-id="1028b5f" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-7876a08 elementor-widget elementor-widget-image" data-id="7876a08" data-element_type="widget" data-widget_type="image.default">
                                                    <div class="elementor-widget-container">
                                                        <img width="1080" height="1080" src="../assets/xboxOne/lucky-neko_logo_zh.png" class="attachment-large size-large wp-image-3883" alt=""  sizes="(max-width: 1080px) 100vw, 1080px"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-6251721 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="6251721" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-6c65b2e" data-id="6c65b2e" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-f44ef1f elementor-widget elementor-widget-text-editor" data-id="f44ef1f" data-element_type="widget" data-widget_type="text-editor.default">
                                                    <div class="elementor-widget-container">
                                                        <p>《招财猫》游戏，以中国传统文化为主题，充满了东方神秘色彩。游戏中，招财猫是主角，赠送丰厚奖励。游戏设计精致，充满了吉祥元素，例如鞭炮、红包和金元宝等，让玩家倍感喜庆。此外，游戏还有免费旋转和额外游戏等特色功能。《招财猫》是一款有趣、又好玩的游戏，无论是游戏体验还是收益都让人满意。</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-bc78864" data-id="bc78864" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-5eb69e2 elementor-widget elementor-widget-html" data-id="5eb69e2" data-element_type="widget" data-widget_type="html.default">
                                    <div class="elementor-widget-container">
                                        <!-- iframe plugin v.5.2 wordpress.org/plugins/iframe/ -->
                                        <iframe src="https://m.pgsoft-games.com/89/index.html?language=zh&bet_type=1&operator_token=ca7094186b309ee149c55c8822e7ecf2&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com" width="100%" height="800" scrolling="no" frameborder="0" marginheight="0" allowfullscreen="true" class="iframe-class"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div v-if="isyqs" data-elementor-id="34073" class="elementor elementor-34073 elementor-location-popup" data-elementor-settings="{&quot;a11y_navigation&quot;:&quot;yes&quot;,&quot;timing&quot;:[]}" data-elementor-post-type="elementor_library">
                <section class="elementor-section elementor-top-section elementor-element elementor-element-3704aad elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="3704aad" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                    <!-- <div @click="isyqs=false" style="z-index:9999999;cursor: pointer;position: absolute;;right:0;width:20px;height:20px;color:#230046;background:#F374DE;font-size:15px;font-weight:bold;text-align:center;line-height:20px;" >X</div> -->
                    <div class="elementor-container elementor-column-gap-wider">
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-eacb4a2" data-id="eacb4a2" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-496d292 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="496d292" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-2c6a791" data-id="2c6a791" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-08b96ed elementor-widget elementor-widget-spacer" data-id="08b96ed" data-element_type="widget" data-widget_type="spacer.default">
                                                    <div class="elementor-widget-container">
                                                        <div class="elementor-spacer">
                                                            <div class="elementor-spacer-inner"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-2a4fc41 elementor-widget elementor-widget-image" data-id="2a4fc41" data-element_type="widget" data-widget_type="image.default">
                                                    <div class="elementor-widget-container">
                                                        <img width="576" height="576" src="../assets/xboxOne/AnyConv.com__ea671b33-cb48-4d6e-a271-be134af70600.webp" class="attachment-large size-large wp-image-2990" alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-a22b230 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="a22b230" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-724c5ff" data-id="724c5ff" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-3e31cdd elementor-widget elementor-widget-text-editor" data-id="3e31cdd" data-element_type="widget" data-widget_type="text-editor.default">
                                                    <div class="elementor-widget-container">
                                                        <p>相传有个农夫从神明那里得到一颗种子，被指示必须用自己的汗水连续浇灌四十九天，才能让树开花结果。农夫听从指示，不辞辛苦地浇灌，很快这棵树就开出了满树的花和金币。这个故事传颂着“勤劳、诚实、耐心”的美德，以及对财富和吉祥的渴望和祈愿。农历新年期间，人们会将红包挂在橘树上，表示祈求幸福。</p>
                                                        <p>当摇钱树上的红包全部开花时，您将会得到免费的旋转奖励。摇钱树保证每次旋转都会带来丰厚回报，您有机会在奖励中赢得大奖。每个金色包裹背后都有一个秘密，也许会改变您的运势！</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <div class="elementor-element elementor-element-520fee3 elementor-widget elementor-widget-spacer" data-id="520fee3" data-element_type="widget" data-widget_type="spacer.default">
                                    <div class="elementor-widget-container">
                                        <div class="elementor-spacer">
                                            <div class="elementor-spacer-inner"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-939911f" data-id="939911f" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-d0ee682 elementor-widget elementor-widget-html" data-id="d0ee682" data-element_type="widget" data-widget_type="html.default">
                                    <div class="elementor-widget-container">
                                        <!-- iframe plugin v.5.2 wordpress.org/plugins/iframe/ -->
                                        <iframe src="https://m.pgsoft-games.com/26/index.html?language=zh&bet_type=1&operator_token=ca7094186b309ee149c55c8822e7ecf2&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com" width="100%" height="800" scrolling="no" frameborder="0" marginheight="0" allowfullscreen="true" class="iframe-class"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div v-if="isdj" data-elementor-id="34078" class="elementor elementor-34078 elementor-location-popup" data-elementor-settings="{&quot;a11y_navigation&quot;:&quot;yes&quot;,&quot;timing&quot;:[]}" data-elementor-post-type="elementor_library">
                <section class="elementor-section elementor-top-section elementor-element elementor-element-ad907f5 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="ad907f5" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                    <!-- <div @click="isdj=false" style="z-index:9999999;cursor: pointer;position: absolute;;right:0;width:20px;height:20px;color:#230046;background:#F374DE;font-size:15px;font-weight:bold;text-align:center;line-height:20px;" >X</div> -->
                    <div class="elementor-container elementor-column-gap-wider">
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-c444fdb" data-id="c444fdb" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-24fe7e9 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="24fe7e9" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-9c6deef" data-id="9c6deef" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-678967b elementor-widget elementor-widget-image" data-id="678967b" data-element_type="widget" data-widget_type="image.default">
                                                    <div class="elementor-widget-container">
                                                        <img width="576" height="576" src="../assets/xboxOne/0aaaccbe-3760-4858-a173-11c2157ddb2e.png" class="attachment-large size-large wp-image-3586" alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-48fd856 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="48fd856" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-f5fa43b" data-id="f5fa43b" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-848a8c7 elementor-widget elementor-widget-text-editor" data-id="848a8c7" data-element_type="widget" data-widget_type="text-editor.default">
                                                    <div class="elementor-widget-container">
                                                        <p>战国时期，斗鸡在齐朝成为一项非常流行的社区运动。齐皇帝非常喜欢这项运动，所以他每两年组织一次盛大的斗鸡比赛，让每个人都参加。在两年的时间里，每个部落的斗鸡都经过了训练，为比赛做好了准备。武世勋和黄达这两个最强的斗鸡家族已经准备好了他们的杀手级斗鸡，以捍卫并赢得比赛的荣耀！在比赛的最后一场比赛中，观众们设置了一个博彩游戏，在他们支持的部落上下注。赌注如此之大，如果下注的部落赢得了比赛，他们的支持者也将赢得丰厚的奖金！有了一些直觉，你也可以从斗鸡活动中致富，过上充实的生活！</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-9fcb53d" data-id="9fcb53d" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-52aec29 elementor-widget elementor-widget-html" data-id="52aec29" data-element_type="widget" data-widget_type="html.default">
                                    <div class="elementor-widget-container">
                                        <!-- iframe plugin v.5.2 wordpress.org/plugins/iframe/ -->
                                        <iframe src="https://m.pgsoft-games.com/123/index.html?language=zh&bet_type=1&operator_token=ca7094186b309ee149c55c8822e7ecf2&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com" width="100%" height="800" scrolling="no" frameborder="0" marginheight="0" allowfullscreen="true" class="iframe-class"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div v-if="isryxc" data-elementor-id="34077" class="elementor elementor-34077 elementor-location-popup" data-elementor-settings="{&quot;a11y_navigation&quot;:&quot;yes&quot;,&quot;timing&quot;:[]}" data-elementor-post-type="elementor_library">
                <section class="elementor-section elementor-top-section elementor-element elementor-element-508b7d2 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="508b7d2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                    <!-- <div @click="isryxc=false" style="z-index:9999999;cursor: pointer;position: absolute;;right:0;width:20px;height:20px;color:#230046;background:#F374DE;font-size:15px;font-weight:bold;text-align:center;line-height:20px;" >X</div> -->
                    <div class="elementor-container elementor-column-gap-wider">
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3cab380" data-id="3cab380" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-8c77f3c elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="8c77f3c" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-ade9a23" data-id="ade9a23" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-ff7703b elementor-widget elementor-widget-image" data-id="ff7703b" data-element_type="widget" data-widget_type="image.default">
                                                    <div class="elementor-widget-container">
                                                        <img width="576" height="576" src="../assets/xboxOne/026d7d34-20e4-450e-b2d1-dc5d54c49db9.png" class="attachment-large size-large wp-image-3619" alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-7b71665 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="7b71665" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-635c5bc" data-id="635c5bc" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-450f0cb elementor-widget elementor-widget-text-editor" data-id="450f0cb" data-element_type="widget" data-widget_type="text-editor.default">
                                                    <div class="elementor-widget-container">
                                                        <p>天空布满了星星，而满月则光芒四射。一名宇航员坐在高高的悬崖上，他的望远镜指向天空。他突然想起了他小时候母亲告诉他的一个老故事。</p>
                                                        <p>“有一对普通的夫妇住在一个小村庄里。丈夫是一个善良的人，总是帮助有需要的人。他的妻子是一位皮肤白皙如雪的美丽女士。她有很多崇拜者，其中一些甚至来自邻近的村庄。”</p>
                                                        <p>“有一天，一个巫师路过村庄，被她的美丽所吸引。他不顾她已经结婚的事实，要求她成为他的妻子。巫师被拒绝和愤怒，把他们变成了太阳和月亮，这样他们就再也不会见面了。然而，每年一次，他们两个都会同时出现在天空中，并短暂地结合在一起。Anyo目睹这一美丽场景的人将得到永恒的爱。“</p>
                                                        <p>《太阳与月亮的命运》是一个6卷（1卷和6卷中有2行，2卷和5卷中有3行，3卷和4卷中有4行）的视频老虎机，具有双向获胜和双向获胜乘数的功能。获胜组合可以从左到右或从右到左形成，让您有更高的获胜机会！在自由旋转功能期间，每个太阳或月亮符号都将使获胜乘数增加2！</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-c3d837c" data-id="c3d837c" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-fb497af elementor-widget elementor-widget-html" data-id="fb497af" data-element_type="widget" data-widget_type="html.default">
                                    <div class="elementor-widget-container">
                                        <!-- iframe plugin v.5.2 wordpress.org/plugins/iframe/ -->
                                        <iframe src="https://m.pgsoft-games.com/121/index.html?language=zh&bet_type=1&operator_token=ca7094186b309ee149c55c8822e7ecf2&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com" width="100%" height="800" scrolling="no" frameborder="0" marginheight="0" allowfullscreen="true" class="iframe-class"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div v-if="islyqb" data-elementor-id="33967" class="elementor elementor-33967 elementor-location-popup" data-elementor-settings="{&quot;a11y_navigation&quot;:&quot;yes&quot;,&quot;timing&quot;:[]}" data-elementor-post-type="elementor_library">
                <section class="elementor-section elementor-top-section elementor-element elementor-element-e7a198f elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="e7a198f" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                    <!-- <div @click="islyqb=false" style="z-index:9999999;cursor: pointer;position: absolute;;right:0;width:20px;height:20px;color:#230046;background:#F374DE;font-size:15px;font-weight:bold;text-align:center;line-height:20px;" >X</div> -->
                    <div class="elementor-container elementor-column-gap-wider">
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-58f005b" data-id="58f005b" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-431d07f elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="431d07f" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-38632c4" data-id="38632c4" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-1a4796c elementor-widget elementor-widget-image" data-id="1a4796c" data-element_type="widget" data-widget_type="image.default">
                                                    <div class="elementor-widget-container">
                                                        <img width="576" height="576" src="../assets/xboxOne/d242bb11-47e5-4c67-850d-6f09e577cfd1.png" class="attachment-large size-large wp-image-3630" alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-20a81e0 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="20a81e0" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-bb86cd0" data-id="bb86cd0" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-4fe2c5a elementor-widget elementor-widget-text-editor" data-id="4fe2c5a" data-element_type="widget" data-widget_type="text-editor.default">
                                                    <div class="elementor-widget-container">
                                                        <p>突袭者简是一位世界著名的冒险家，以其寻找失踪父母的惊人探险而闻名。她写了无数本畅销书，详细描述了她在世界各地冒险中令人兴奋和危险的部分。她最受欢迎的书告诉了她父母寻找神秘埃及墓穴的故事。据说这个地下室隐藏在广阔的撒哈拉沙漠中，你必须解开隐藏的线索才能揭开隐藏在地下室深处的巨大宝藏。</p>
                                                        <p></p>
                                                        <p>简的父母痴迷于这个埃及地下室，他们把所有的时间都花在寻找和解决隐藏在撒哈拉沙漠中的所有线索上。然而，一次这样的探险导致她的父母双双失踪。现在，简的任务是找到神秘的埃及墓穴，希望也能找到她失踪的父母。</p>
                                                        <p></p>
                                                        <p>Raider Jane‘s Crypt of Fortune是一款6卷3排（第2、3、4和5卷各增加一排）的视频老虎机，具有粘性的野生符号和获胜乘数。粘性狂野符号将增加乘数，并停留在卷轴上，直到它摧毁自己。用3个散射符号触发10次免费旋转！</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-6c148b3" data-id="6c148b3" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-50e132e elementor-widget elementor-widget-html" data-id="50e132e" data-element_type="widget" data-widget_type="html.default">
                                    <div class="elementor-widget-container">
                                        <!-- iframe plugin v.5.2 wordpress.org/plugins/iframe/ -->
                                        <iframe src="https://m.pgsoft-games.com/113/index.html?language=zh&bet_type=1&operator_token=ca7094186b309ee149c55c8822e7ecf2&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com" width="100%" height="800" scrolling="no" frameborder="0" marginheight="0" allowfullscreen="true" class="iframe-class"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div v-if="isrygz" data-elementor-id="33989" class="elementor elementor-33989 elementor-location-popup" data-elementor-settings="{&quot;a11y_navigation&quot;:&quot;yes&quot;,&quot;timing&quot;:[]}" data-elementor-post-type="elementor_library">
                <section class="elementor-section elementor-top-section elementor-element elementor-element-e415043 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="e415043" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                    <!-- <div @click="isrygz=false" style="z-index:9999999;cursor: pointer;position: absolute;;right:0;width:20px;height:20px;color:#230046;background:#F374DE;font-size:15px;font-weight:bold;text-align:center;line-height:20px;" >X</div> -->
                    <div class="elementor-container elementor-column-gap-wider">
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-0352422" data-id="0352422" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-c223484 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="c223484" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-deb9aa3" data-id="deb9aa3" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-dc3d278 elementor-widget elementor-widget-image" data-id="dc3d278" data-element_type="widget" data-widget_type="image.default">
                                                    <div class="elementor-widget-container">
                                                        <img width="576" height="576" src="../assets/xboxOne/da60c653-f3d1-4614-99dc-8817d15fe675.png" class="attachment-large size-large wp-image-3652" alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-c3c1518 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="c3c1518" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-23aa767" data-id="23aa767" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-f6d5ba2 elementor-widget elementor-widget-text-editor" data-id="f6d5ba2" data-element_type="widget" data-widget_type="text-editor.default">
                                                    <div class="elementor-widget-container">
                                                        <p>美人鱼住在深蓝色大海下的碧玉城堡里。这座城堡被一道无形的屏障所保护，只有美人鱼才能通过。然而，传说这道屏障不仅保护了美人鱼，而且还保护了数百年前从他们的祖先那里传下来的一件罕见的宝藏。这件稀世珍宝只会送给命中注定的人。虽然人类知道美人鱼的存在，但很少有人见过美人鱼。</p>
                                                        <p></p>
                                                        <p>一天，范西斯在海滩边捡贝壳，被远处的一道金光吸引了。他顺着灯走，发现一条闪闪发光的金鳞鱼被困在渔网里！当一道亮光突然闪现，让范西斯眼花缭乱时，他剪断了渔网，将鱼放生。当弗朗西斯再次睁开眼睛时，一条美丽的黑色长发美人鱼出现在他面前。这条美人鱼有一条金色鳞片的鱼尾。范西斯很震惊，但很快意识到她就是传说中的美人鱼公主。美人鱼公主送给范西斯一块天平作为礼物，并告诉范西斯，如果需要帮助，他可以用天平在海底的城堡里找到她。她还承诺会答应他的任何要求！</p>
                                                        <p></p>
                                                        <p>人鱼公主是一个5卷（第1卷1行，第2卷2行，第3卷3行，第4卷4行，第5卷5行）的视频老虎机，具有Sticky Wild符号，获胜倍数高达x10。当卷轴镜像时，在免费旋转功能中赢得更多-卷轴1中有5行，卷轴2中有4行，卷轴4中有2行，卷轴5中有1行！</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-c554462" data-id="c554462" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-b0815f0 elementor-widget elementor-widget-html" data-id="b0815f0" data-element_type="widget" data-widget_type="html.default">
                                    <div class="elementor-widget-container">
                                        <!-- iframe plugin v.5.2 wordpress.org/plugins/iframe/ -->
                                        <iframe src="https://m.pgsoft-games.com/102/index.html?language=zh&bet_type=1&operator_token=ca7094186b309ee149c55c8822e7ecf2&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com" width="100%" height="800" scrolling="no" frameborder="0" marginheight="0" allowfullscreen="true" class="iframe-class"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div v-if="isjnh" data-elementor-id="34002" class="elementor elementor-34002 elementor-location-popup" data-elementor-settings="{&quot;a11y_navigation&quot;:&quot;yes&quot;,&quot;timing&quot;:[]}" data-elementor-post-type="elementor_library">
                <section class="elementor-section elementor-top-section elementor-element elementor-element-d4ebd18 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="d4ebd18" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                    <!-- <div @click="isjnh=false" style="z-index:9999999;cursor: pointer;position: absolute;;right:0;width:20px;height:20px;color:#230046;background:#F374DE;font-size:15px;font-weight:bold;text-align:center;line-height:20px;" >X</div> -->
                    <div class="elementor-container elementor-column-gap-wider">
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-8c861ea" data-id="8c861ea" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-6eb7306 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="6eb7306" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-e27ca60" data-id="e27ca60" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-b596227 elementor-widget elementor-widget-image" data-id="b596227" data-element_type="widget" data-widget_type="image.default">
                                                    <div class="elementor-widget-container">
                                                        <img width="2084" height="1360" src="../assets/xboxOne/AnyConv.com__Game-Banner-BG-1_2x8278758.webp" class="attachment-large size-large wp-image-3418" alt="" srcset="https://www.pgsoftgaming.com/wp-content/uploads/AnyConv.com__Game-Banner-BG-1_2x8278758.webp 2084w, https://www.pgsoftgaming.com/wp-content/uploads/AnyConv.com__Game-Banner-BG-1_2x8278758-768x501.webp 768w, https://www.pgsoftgaming.com/wp-content/uploads/AnyConv.com__Game-Banner-BG-1_2x8278758-1536x1002.webp 1536w, https://www.pgsoftgaming.com/wp-content/uploads/AnyConv.com__Game-Banner-BG-1_2x8278758-2048x1337.webp 2048w" sizes="(max-width: 2084px) 100vw, 2084px"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-24700ae elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="24700ae" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-7bf5edd" data-id="7bf5edd" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-6f78e66 elementor-widget elementor-widget-text-editor" data-id="6f78e66" data-element_type="widget" data-widget_type="text-editor.default">
                                                    <div class="elementor-widget-container">
                                                        <p>威尼斯嘉年华是一个举世闻名的节日，最著名的是在嘉年华期间佩戴的复杂精美的面具。传说嘉年华被用来保护威尼斯人免受现在和未来的痛苦，确保他们在接下来的一年里健康和富有。嘉年华的亮点之一是最美丽面具的比赛。每年都有成千上万的人来参加比赛，希望赢得无法想象的财富和名声！戴上你的面具，加入面具嘉年华，立即赢得胜利吧！</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-0c18443" data-id="0c18443" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-09f748d elementor-widget elementor-widget-html" data-id="09f748d" data-element_type="widget" data-widget_type="html.default">
                                    <div class="elementor-widget-container">
                                        <!-- iframe plugin v.5.2 wordpress.org/plugins/iframe/ -->
                                        <iframe src="https://m.pgsoft-games.com/118/index.html?language=zh&bet_type=1&operator_token=ca7094186b309ee149c55c8822e7ecf2&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com" width="100%" height="800" scrolling="no" frameborder="0" marginheight="0" allowfullscreen="true" class="iframe-class"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div v-if="isttqj" data-elementor-id="34012" class="elementor elementor-34012 elementor-location-popup" data-elementor-settings="{&quot;a11y_navigation&quot;:&quot;yes&quot;,&quot;timing&quot;:[]}" data-elementor-post-type="elementor_library">
                <section class="elementor-section elementor-top-section elementor-element elementor-element-9039017 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="9039017" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                    <!-- <div @click="isttqj=false" style="z-index:9999999;cursor: pointer;position: absolute;;right:0;width:20px;height:20px;color:#230046;background:#F374DE;font-size:15px;font-weight:bold;text-align:center;line-height:20px;" >X</div> -->
                    <div class="elementor-container elementor-column-gap-wider">
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-d1e5581" data-id="d1e5581" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-0812cc9 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="0812cc9" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-1bd8158" data-id="1bd8158" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-d0fcb78 elementor-widget elementor-widget-image" data-id="d0fcb78" data-element_type="widget" data-widget_type="image.default">
                                                    <div class="elementor-widget-container">
                                                        <img width="2084" height="1360" src="../assets/xboxOne/AnyConv.com__Game-Banner-BG_2xdfcf461.webp" class="attachment-large size-large wp-image-3442" alt="" srcset="https://www.pgsoftgaming.com/wp-content/uploads/AnyConv.com__Game-Banner-BG_2xdfcf461.webp 2084w, https://www.pgsoftgaming.com/wp-content/uploads/AnyConv.com__Game-Banner-BG_2xdfcf461-768x501.webp 768w, https://www.pgsoftgaming.com/wp-content/uploads/AnyConv.com__Game-Banner-BG_2xdfcf461-1536x1002.webp 1536w, https://www.pgsoftgaming.com/wp-content/uploads/AnyConv.com__Game-Banner-BG_2xdfcf461-2048x1337.webp 2048w" sizes="(max-width: 2084px) 100vw, 2084px"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-acf0f2c elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="acf0f2c" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-76caee1" data-id="76caee1" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-8e903aa elementor-widget elementor-widget-text-editor" data-id="8e903aa" data-element_type="widget" data-widget_type="text-editor.default">
                                                    <div class="elementor-widget-container">
                                                        <div class="w-full border-b border-black/10 dark:border-gray-900/50 text-gray-800 dark:text-gray-100 group bg-gray-50 dark:bg-[#444654]">
                                                            <div class="text-base gap-4 md:gap-6 m-auto md:max-w-2xl lg:max-w-2xl xl:max-w-3xl p-4 md:py-6 flex lg:px-0">
                                                                <div class="relative flex w-[calc(100%-50px)] flex-col gap-1 md:gap-3 lg:w-[calc(100%-115px)]">
                                                                    <div class="flex flex-grow flex-col gap-3">
                                                                        <div class="min-h-[20px] flex flex-col items-start gap-4 whitespace-pre-wrap">
                                                                            <div class="markdown prose w-full break-words dark:prose-invert light">
                                                                                <p>图腾柱起源于北美土著民族，通常由雕刻着代表氏族的符号的纪念碑组成。土著民族将图腾视为守护神，因为他们相信图腾具有保护人民的力量。在一个名为五星的部落中，竖立着三根神秘的图腾柱，它们的位置呈三角形。这些柱子分别雕刻有老鹰、白狼和黑熊的图腾，代表着部落的三种灵魂动物。传说当金星、木星、水星、火星和土星排成一线时，三种灵魂动物将会祝福站在三角形中心的任何人拥有巨大的好运！</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4fbf9d6" data-id="4fbf9d6" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-b3c15ae elementor-widget elementor-widget-html" data-id="b3c15ae" data-element_type="widget" data-widget_type="html.default">
                                    <div class="elementor-widget-container">
                                        <!-- iframe plugin v.5.2 wordpress.org/plugins/iframe/ -->
                                        <iframe src="https://m.pgsoft-games.com/1338274/index.html?language=zh&bet_type=1&operator_token=ca7094186b309ee149c55c8822e7ecf2&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&btt=2&__refer=m.pg-redirect.net&or=static.pgsoft-games.com" width="100%" height="800" scrolling="no" frameborder="0" marginheight="0" allowfullscreen="true" class="iframe-class"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div id="colose" v-if="isttqj || isjnh || isrygz || islyqb || isryxc || isdj || isyqs || iszcm || iskl || isbscq || istys || isslzq || iswldd || ismj || ismj2 || issjnw || ishjyqs || isrz" @click="isttqj=false;isjnh=false;isrygz=false;islyqb=false;isryxc=false;isdj=false;isyqs=false;ismj=false;ismj2=false;issjnw=false;ishjyqs=false;isrz=false;iswldd=false;isslzq=false;istys=false;isbscq=false;iskl=false;iszcm=false;" style="z-index:9999999999999999;cursor: pointer;position: fixed;top:20px;right:45px;width:20px;height:20px;color:#230046;background:#F374DE;font-size:15px;font-weight:bold;text-align:center;line-height:20px;" >X</div>
        </div>    
        
        <div data-elementor-type="popup" data-elementor-id="235" class="elementor elementor-235 elementor-location-popup" data-elementor-settings="{&quot;entrance_animation&quot;:&quot;slideInRight&quot;,&quot;exit_animation&quot;:&quot;slideInRight&quot;,&quot;avoid_multiple_popups&quot;:&quot;yes&quot;,&quot;entrance_animation_duration&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0.5,&quot;sizes&quot;:[]},&quot;a11y_navigation&quot;:&quot;yes&quot;,&quot;triggers&quot;:[],&quot;timing&quot;:[]}" data-elementor-post-type="elementor_library">
            <section class="elementor-section elementor-top-section elementor-element elementor-element-16c9f3ef elementor-section-full_width elementor-section-height-min-height elementor-section-items-stretch elementor-section-content-middle elementor-section-height-default" data-id="16c9f3ef" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-container elementor-column-gap-no">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-0222bbd" data-id="0222bbd" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-background-overlay"></div>
                            <div class="elementor-element elementor-element-7b86483 elementor-widget elementor-widget-image" data-id="7b86483" data-element_type="widget" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img width="404" height="238" src="../assets/officialActivities/PGLOGO2024.png" class="attachment-large size-large wp-image-26901" alt=""/>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-7237f52 elementor-nav-menu--dropdown-none elementor-widget elementor-widget-nav-menu" data-id="7237f52" data-element_type="widget" data-settings="{&quot;layout&quot;:&quot;vertical&quot;,&quot;submenu_icon&quot;:{&quot;value&quot;:&quot;&lt;i class=\&quot;fas fa-caret-down\&quot;&gt;&lt;\/i&gt;&quot;,&quot;library&quot;:&quot;fa-solid&quot;}}" data-widget_type="nav-menu.default">
                                <div class="elementor-widget-container">
                                    <nav aria-label="Menu" class="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-vertical e--pointer-text e--animation-float">
                                        <ul id="menu-1-7237f52" class="elementor-nav-menu sm-vertical">
                                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-33614">
                                                <a href="https://www.pgsoftgaming.com/" class="elementor-item">首页</a>
                                            </li>
                                            <li class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-349 current_page_item menu-item-2091">
                                                <a href="https://www.pgsoftgaming.com/pggame/" aria-current="page" class="elementor-item elementor-item-active">游戏试玩</a>
                                            </li>
                                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-26894">
                                                <a href="https://www.pgsoftgaming.com/brand/" class="elementor-item">品牌介绍</a>
                                            </li>
                                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-143">
                                                <a href="https://www.pgsoftgaming.com/official/" class="elementor-item">官方活动</a>
                                            </li>
                                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-26898">
                                                <a href="https://www.pgsoftgaming.com/partner/" class="elementor-item">合作伙伴</a>
                                            </li>
                                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-26938">
                                                <a href="https://www.pgsoftgaming.com/pg-news/" class="elementor-item">动态消息</a>
                                            </li>
                                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2089">
                                                <a href="https://www.pgsoftgaming.com/download/" class="elementor-item">PG APP下载</a>
                                            </li>
                                        </ul>
                                    </nav>
                                    <nav class="elementor-nav-menu--dropdown elementor-nav-menu__container" aria-hidden="true">
                                        <ul id="menu-2-7237f52" class="elementor-nav-menu sm-vertical">
                                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-33614">
                                                <a href="https://www.pgsoftgaming.com/" class="elementor-item" tabindex="-1">首页</a>
                                            </li>
                                            <li class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-349 current_page_item menu-item-2091">
                                                <a href="https://www.pgsoftgaming.com/pggame/" aria-current="page" class="elementor-item elementor-item-active" tabindex="-1">游戏试玩</a>
                                            </li>
                                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-26894">
                                                <a href="https://www.pgsoftgaming.com/brand/" class="elementor-item" tabindex="-1">品牌介绍</a>
                                            </li>
                                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-143">
                                                <a href="https://www.pgsoftgaming.com/official/" class="elementor-item" tabindex="-1">官方活动</a>
                                            </li>
                                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-26898">
                                                <a href="https://www.pgsoftgaming.com/partner/" class="elementor-item" tabindex="-1">合作伙伴</a>
                                            </li>
                                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-26938">
                                                <a href="https://www.pgsoftgaming.com/pg-news/" class="elementor-item" tabindex="-1">动态消息</a>
                                            </li>
                                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2089">
                                                <a href="https://www.pgsoftgaming.com/download/" class="elementor-item" tabindex="-1">PG APP下载</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-2ef4bc6 elementor-widget elementor-widget-shortcode" data-id="2ef4bc6" data-element_type="widget" data-widget_type="shortcode.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-shortcode">
                                        <div data-elementor-type="section" data-elementor-id="33905" class="elementor elementor-33905 elementor-location-popup" data-elementor-post-type="elementor_library">
                                            <section class="elementor-section elementor-top-section elementor-element elementor-element-2547e90 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="2547e90" data-element_type="section">
                                                <div class="elementor-container elementor-column-gap-default">
                                                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b9c4a00" data-id="b9c4a00" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-ea97123 elementor-widget elementor-widget-button" data-id="ea97123" data-element_type="widget" data-widget_type="button.default">
                                                                <div class="elementor-widget-container">
                                                                    <div class="elementor-button-wrapper">
                                                                        <a class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="https://rr.top" target="_blank" rel="nofollow">
                                                                            <span class="elementor-button-content-wrapper">
                                                                                <span class="elementor-button-icon">
                                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                </span>
                                                                                <span class="elementor-button-text">2025 抢百万蛇我其谁</span>
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div data-elementor-type="popup" data-elementor-id="4503" class="elementor elementor-4503 elementor-location-popup" data-elementor-settings="{&quot;a11y_navigation&quot;:&quot;yes&quot;,&quot;triggers&quot;:[],&quot;timing&quot;:[]}" data-elementor-post-type="elementor_library">
            <section class="elementor-section elementor-top-section elementor-element elementor-element-9ecd11d elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="9ecd11d" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2b841ce" data-id="2b841ce" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-fd352fa elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="fd352fa" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-wide">
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-cea96ea" data-id="cea96ea" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-5d84b8d elementor-widget elementor-widget-image" data-id="5d84b8d" data-element_type="widget" data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <a href="http://e5557.com" target="_blank" rel="nofollow" nofollow="" noreferrer="" noopener="">
                                                        <img width="265" height="120" src="../assets/xboxOne/默认标题__2023-03-0314_15_17.png" class="attachment-large size-large wp-image-3282" alt="ESB LOGO"/>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-f56822c" data-id="f56822c" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-53ee41f elementor-widget elementor-widget-image" data-id="53ee41f" data-element_type="widget" data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <a href="#" target="_blank" rel="nofollow" nofollow="" noreferrer="" noopener="">
                                                        <img width="1280" height="420" src="../assets/xboxOne/0.jpg" class="attachment-large size-large wp-image-25169" alt="E世博新年活动-发百万红包"  sizes="(max-width: 1280px) 100vw, 1280px"/>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-e68390e elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="e68390e" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-wide">
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-cc2c8ef" data-id="cc2c8ef" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-9be0956 elementor-widget elementor-widget-image" data-id="9be0956" data-element_type="widget" data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <a href="#" target="_blank" rel="nofollow" nofollow="" noreferrer="" noopener="">
                                                        <img width="265" height="120" src="../assets/xboxOne/默认标题__2023-03-0414_39_01.png" class="attachment-large size-large wp-image-3295" alt="金博乐logo"/>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-f048474" data-id="f048474" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-c06af75 elementor-widget elementor-widget-image" data-id="c06af75" data-element_type="widget" data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <a href="#" target="_blank" rel="nofollow" nofollow="" noreferrer="" noopener="">
                                                        <img width="970" height="184" src="../assets/xboxOne/170706942063.jpg" class="attachment-large size-large wp-image-25251" alt=""  sizes="(max-width: 970px) 100vw, 970px"/>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-3624fad elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="3624fad" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-wide">
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-11596c9" data-id="11596c9" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-19dd667 elementor-widget elementor-widget-image" data-id="19dd667" data-element_type="widget" data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <a href="#" target="_blank" rel="nofollow" nofollow="" noreferrer="" noopener="">
                                                        <img width="265" height="120" src="https://www.pgsoftgaming.com/wp-content/uploads/GA黃金甲.webp" class="attachment-large size-large wp-image-3903" alt="GA黃金甲"/>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-ee6941c" data-id="ee6941c" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-93670cc elementor-widget elementor-widget-image" data-id="93670cc" data-element_type="widget" data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <a href="#" target="_blank" rel="nofollow" nofollow="" noreferrer="" noopener="">
                                                        <img width="1420" height="544" src="https://www.pgsoftgaming.com/wp-content/uploads/j8w8wcgiaKtoVuiMNyOOpEmm4X94RJ4QoxpRDXRP-1.webp" class="attachment-large size-large wp-image-25250" alt="" srcset="https://www.pgsoftgaming.com/wp-content/uploads/j8w8wcgiaKtoVuiMNyOOpEmm4X94RJ4QoxpRDXRP-1.webp 1420w, https://www.pgsoftgaming.com/wp-content/uploads/j8w8wcgiaKtoVuiMNyOOpEmm4X94RJ4QoxpRDXRP-1-768x294.webp 768w" sizes="(max-width: 1420px) 100vw, 1420px"/>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        
        <Footer></Footer>
    </div>
</template>

<script>
import Footer from "../components/footer.vue";
import Header from "../components/header.vue";

            // var ElementorProFrontendConfig = {
            //     "ajaxurl": "https:\/\/www.pgsoftgaming.com\/wp-admin\/admin-ajax.php",
            //     "nonce": "03be870244",
            //     "urls": {
            //         "assets": "https:\/\/www.pgsoftgaming.com\/wp-content\/plugins\/elementor-pro\/assets\/",
            //         "rest": "https:\/\/www.pgsoftgaming.com\/wp-json\/"
            //     },
            //     "settings": {
            //         "lazy_load_background_images": true
            //     },
            //     "popup": {
            //         "hasPopUps": true
            //     },
            //     "shareButtonsNetworks": {
            //         "facebook": {
            //             "title": "Facebook",
            //             "has_counter": true
            //         },
            //         "twitter": {
            //             "title": "Twitter"
            //         },
            //         "linkedin": {
            //             "title": "LinkedIn",
            //             "has_counter": true
            //         },
            //         "pinterest": {
            //             "title": "Pinterest",
            //             "has_counter": true
            //         },
            //         "reddit": {
            //             "title": "Reddit",
            //             "has_counter": true
            //         },
            //         "vk": {
            //             "title": "VK",
            //             "has_counter": true
            //         },
            //         "odnoklassniki": {
            //             "title": "OK",
            //             "has_counter": true
            //         },
            //         "tumblr": {
            //             "title": "Tumblr"
            //         },
            //         "digg": {
            //             "title": "Digg"
            //         },
            //         "skype": {
            //             "title": "Skype"
            //         },
            //         "stumbleupon": {
            //             "title": "StumbleUpon",
            //             "has_counter": true
            //         },
            //         "mix": {
            //             "title": "Mix"
            //         },
            //         "telegram": {
            //             "title": "Telegram"
            //         },
            //         "pocket": {
            //             "title": "Pocket",
            //             "has_counter": true
            //         },
            //         "xing": {
            //             "title": "XING",
            //             "has_counter": true
            //         },
            //         "whatsapp": {
            //             "title": "WhatsApp"
            //         },
            //         "email": {
            //             "title": "Email"
            //         },
            //         "print": {
            //             "title": "Print"
            //         },
            //         "x-twitter": {
            //             "title": "X"
            //         },
            //         "threads": {
            //             "title": "Threads"
            //         }
            //     },
            //     "facebook_sdk": {
            //         "lang": "zh_CN",
            //         "app_id": ""
            //     },
            //     "lottie": {
            //         "defaultAnimationUrl": "https:\/\/www.pgsoftgaming.com\/wp-content\/plugins\/elementor-pro\/modules\/lottie\/assets\/animations\/default.json"
            //     }
            // };
            // var elementorFrontendConfig = {
            //     "environmentMode": {
            //         "edit": false,
            //         "wpPreview": false,
            //         "isScriptDebug": false
            //     },
            //     "i18n": {
            //         "shareOnFacebook": "\u5728\u8138\u4e66\u4e0a\u5206\u4eab",
            //         "shareOnTwitter": "\u5206\u4eab\u5230Twitter",
            //         "pinIt": "\u9489\u4f4f",
            //         "download": "\u4e0b\u8f7d",
            //         "downloadImage": "\u4e0b\u8f7d\u56fe\u7247",
            //         "fullscreen": "\u5168\u5c4f",
            //         "zoom": "\u7f29\u653e",
            //         "share": "\u5206\u4eab",
            //         "playVideo": "\u64ad\u653e\u89c6\u9891",
            //         "previous": "\u4e0a\u4e00\u9875",
            //         "next": "\u4e0b\u4e00\u9875",
            //         "close": "\u5173\u95ed",
            //         "a11yCarouselPrevSlideMessage": "\u4e0a\u4e00\u5f20\u5e7b\u706f\u7247",
            //         "a11yCarouselNextSlideMessage": "\u4e0b\u4e00\u5f20\u5e7b\u706f\u7247",
            //         "a11yCarouselFirstSlideMessage": "\u8fd9\u662f\u7b2c\u4e00\u5f20\u5e7b\u706f\u7247",
            //         "a11yCarouselLastSlideMessage": "\u8fd9\u662f\u6700\u540e\u4e00\u5f20\u5e7b\u706f\u7247",
            //         "a11yCarouselPaginationBulletMessage": "\u8f6c\u5230\u5e7b\u706f\u7247"
            //     },
            //     "is_rtl": false,
            //     "breakpoints": {
            //         "xs": 0,
            //         "sm": 480,
            //         "md": 768,
            //         "lg": 1025,
            //         "xl": 1440,
            //         "xxl": 1600
            //     },
            //     "responsive": {
            //         "breakpoints": {
            //             "mobile": {
            //                 "label": "\u624b\u673a\u7eb5\u5411",
            //                 "value": 767,
            //                 "default_value": 767,
            //                 "direction": "max",
            //                 "is_enabled": true
            //             },
            //             "mobile_extra": {
            //                 "label": "\u624b\u673a\u6a2a\u5411",
            //                 "value": 880,
            //                 "default_value": 880,
            //                 "direction": "max",
            //                 "is_enabled": false
            //             },
            //             "tablet": {
            //                 "label": "\u5e73\u677f\u7535\u8111\u7eb5\u5411",
            //                 "value": 1024,
            //                 "default_value": 1024,
            //                 "direction": "max",
            //                 "is_enabled": true
            //             },
            //             "tablet_extra": {
            //                 "label": "\u5e73\u677f\u7535\u8111\u6a2a\u5411",
            //                 "value": 1200,
            //                 "default_value": 1200,
            //                 "direction": "max",
            //                 "is_enabled": false
            //             },
            //             "laptop": {
            //                 "label": "\u7b14\u8bb0\u672c\u7535\u8111",
            //                 "value": 1366,
            //                 "default_value": 1366,
            //                 "direction": "max",
            //                 "is_enabled": false
            //             },
            //             "widescreen": {
            //                 "label": "\u5168\u5bbd\u5c4f",
            //                 "value": 2400,
            //                 "default_value": 2400,
            //                 "direction": "min",
            //                 "is_enabled": false
            //             }
            //         },
            //         "hasCustomBreakpoints": false
            //     },
            //     "version": "3.27.6",
            //     "is_static": false,
            //     "experimentalFeatures": {
            //         "additional_custom_breakpoints": true,
            //         "e_swiper_latest": true,
            //         "e_onboarding": true,
            //         "theme_builder_v2": true,
            //         "hello-theme-header-footer": true,
            //         "home_screen": true,
            //         "landing-pages": true,
            //         "editor_v2": true,
            //         "link-in-bio": true,
            //         "floating-buttons": true
            //     },
            //     "urls": {
            //         "assets": "https:\/\/www.pgsoftgaming.com\/wp-content\/plugins\/elementor\/assets\/",
            //         "ajaxurl": "https:\/\/www.pgsoftgaming.com\/wp-admin\/admin-ajax.php",
            //         "uploadUrl": "http:\/\/www.pgsoftgaming.com\/wp-content\/uploads"
            //     },
            //     "nonces": {
            //         "floatingButtonsClickTracking": "260bdb20af"
            //     },
            //     "swiperClass": "swiper",
            //     "settings": {
            //         "page": [],
            //         "editorPreferences": []
            //     },
            //     "kit": {
            //         "body_background_background": "classic",
            //         "active_breakpoints": ["viewport_mobile", "viewport_tablet"],
            //         "global_image_lightbox": "yes",
            //         "lightbox_enable_counter": "yes",
            //         "lightbox_enable_fullscreen": "yes",
            //         "lightbox_enable_zoom": "yes",
            //         "lightbox_enable_share": "yes",
            //         "lightbox_title_src": "title",
            //         "lightbox_description_src": "description",
            //         "hello_header_logo_type": "logo",
            //         "hello_header_menu_layout": "horizontal",
            //         "hello_footer_logo_type": "logo"
            //     },
            //     "post": {
            //         "id": 349,
            //         "title": "PG%E6%B8%B8%E6%88%8F%E8%AF%95%E7%8E%A9%20%7C%20PG%E7%94%B5%E5%AD%90%E5%AE%98%E6%96%B9%E7%BD%91%E7%AB%99",
            //         "excerpt": "",
            //         "featuredImage": false
            //     }
            // };
            (function() {
                function c() {
                    var b = a.contentDocument || a.contentWindow.document;
                    if (b) {
                        var d = b.createElement('script');
                        d.innerHTML = "window.__CF$cv$params={r:'91461ea9dd22156b',t:'MTczOTk2NjY3MS4wMDAwMDA='};var a=document.createElement('script');a.nonce='';a.src='/cdn-cgi/challenge-platform/scripts/jsd/main.js';document.getElementsByTagName('head')[0].appendChild(a);";
                        b.getElementsByTagName('head')[0].appendChild(d)
                    }
                }
                if (document.body) {
                    var a = document.createElement('iframe');
                    a.height = 1;
                    a.width = 1;
                    a.style.position = 'absolute';
                    a.style.top = 0;
                    a.style.left = 0;
                    a.style.border = 'none';
                    a.style.visibility = 'hidden';
                    document.body.appendChild(a);
                    if ('loading' !== document.readyState)
                        c();
                    else if (window.addEventListener)
                        document.addEventListener('DOMContentLoaded', c);
                    else {
                        var e = document.onreadystatechange || function() {}
                        ;
                        document.onreadystatechange = function(b) {
                            e(b);
                            'loading' !== document.readyState && (document.onreadystatechange = e,
                            c())
                        }
                    }
                }
            }
            )();
export default {
    name:'xboxOneBox',

    components: {
        Footer,
        Header
    },
    data(){
        return{
            ismj:false,
            ismj2:false,
            issjnw:false,
            ishjyqs:false,
            isrz:false,
            iswldd:false,
            isslzq:false,
            istys:false,
            isbscq:false,
            iskl:false,
            iszcm:false,
            isyqs:false,
            isdj:false,
            isryxc:false,
            islyqb:false,
            isrygz:false,
            isjnh:false,
            isttqj:false
        }
    },
    mounted() {
        const scripts = [
            // 'https://www.pgsoftgaming.com/wp-content/themes/hello-elementor/assets/js/hello-frontend.min.js?ver=3.1.1',
            "./js/hello-frontend.min.js",
            // "https://www.pgsoftgaming.com/wp-content/plugins/elementor-pro/assets/lib/sticky/jquery.sticky.min.js?ver=3.27.4",
            "./js/jquery.sticky.min.js",
            // "https://www.pgsoftgaming.com/wp-content/plugins/elementor-pro/assets/lib/smartmenus/jquery.smartmenus.min.js?ver=1.2.1",
            "./js/jquery.smartmenus.min.js",
            // "https://www.pgsoftgaming.com/wp-content/plugins/elementor-pro/assets/js/webpack-pro.runtime.min.js?ver=3.27.4",
            "./js/webpack-pro.runtime.min.js",
            // "https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/js/webpack.runtime.min.js?ver=3.27.6",
            "./js/webpack.runtime.min.js",
            // "https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/js/frontend-modules.min.js?ver=3.27.6",
            "./js/frontend-modules.min.js",
            // "https://www.pgsoftgaming.com/wp-includes/js/dist/hooks.min.js?ver=4d63a3d491d11ffd8ac6",
            "./js/hooks.min.js",
            // "https://www.pgsoftgaming.com/wp-includes/js/dist/i18n.min.js?ver=5e580eb46a90c2b997e6",
            "./js/i18n.min.js",
            // "https://www.pgsoftgaming.com/wp-content/plugins/elementor-pro/assets/js/frontend.min.js?ver=3.27.4",
            "./js/frontend.min.js",
            // "https://www.pgsoftgaming.com/wp-includes/js/jquery/ui/core.min.js?ver=1.13.3",
            "./js/core.min.js",
            // "https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/js/frontend.min.js?ver=3.27.6",
            "./js/frontend.min1.js",
            // "https://www.pgsoftgaming.com/wp-content/plugins/elementor-pro/assets/js/elements-handlers.min.js?ver=3.27.4",
            "./js/elements-handlers.min.js",
            // "https://static.cloudflareinsights.com/beacon.min.js/vcd15cbe7772f49c399c6a5babf22c1241717689176015"
            "./js/vcd15cbe7772f49c399c6a5babf22c1241717689176015.js"
        ];
        this.loadScripts(scripts, () => {
            console.log('所有脚本加载完成');
            this.loadCSS([
                // "https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-33905.css?ver=1736761290",
                "./css/post-33905.css",
                // "https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-34162.css?ver=1734676933",
                "./css/post-34162.css",
                // "https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/lib/animations/styles/e-animation-shrink.min.css?ver=3.27.6",
                "./css/e-animation-shrink.min.css",
                // 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-34147.css?ver=1734676933',
                "./css/post-34147.css",
                // 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-34153.css?ver=1734676933',
                "./css/post-34153.css",
                // 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-34170.css?ver=1734676933',
                "./css/post-34170.css",
                // 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-34176.css?ver=1734676933',
                "./css/post-34176.css",
                // 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-34209.css?ver=1734676933',
                "./css/post-34209.css",
                // 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-34216.css?ver=1734676933',
                "./css/post-34216.css",
                // 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-34233.css?ver=1734676933',
                "./css/post-34233.css",
                // 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-34023.css?ver=1734676933',
                "./css/post-34023.css",
                // 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-34033.css?ver=1734676933',
                "./css/post-34033.css",
                // 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-34046.css?ver=1734676933',
                "./css/post-34046.css",
                // 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-34064.css?ver=1734676933',
                "./css/post-34064.css",
                // 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-34073.css?ver=1734676933',
                "./css/post-34073.css",
                // 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-34078.css?ver=1734676934',
                "./css/post-34078.css",
                // 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-34077.css?ver=1734676934',
                "./css/post-34077.css",
                // 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-33967.css?ver=1734676934',
                "./css/post-33967.css",
                // 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-33989.css?ver=1734676934',
                "./css/post-33989.css",
                // 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-34002.css?ver=1734676934',
                "./css/post-34002.css",
                // 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-34012.css?ver=1734676934',
                "./css/post-34012.css"
            ],false)
        });
    },
    methods: {
        loadCSS(urls, isCache = false) {
            // 如果传入的是单个 URL，则转换为数组
            if (typeof urls === "string") {
                urls = [urls];
            }

            // 遍历 URL 数组，逐个加载 CSS 文件
            urls.forEach(url => {
                const element = document.createElement("link");
                element.setAttribute("rel", "stylesheet");
                element.setAttribute("type", "text/css");

                // 根据是否启用缓存设置 href 属性
                if (isCache) {
                    element.setAttribute("href", `${url}?t=${new Date().getTime()}`);
                } else {
                    element.setAttribute("href", url);
                }

                // 将 <link> 元素添加到 <head> 中
                document.head.appendChild(element);
            });
        },
        loadScripts(scripts, callback) {
            const loadNext = (index) => {
            if (index >= scripts.length) {
                callback(); // 所有脚本加载完成后执行回调
                return;
            }
            const script = document.createElement('script');
            script.src = scripts[index];
            script.onload = () => loadNext(index + 1); // 加载下一个脚本
            script.onerror = () => console.error(`Failed to load script: ${scripts[index]}`);
            document.body.appendChild(script);
            };
            loadNext(0); // 开始加载第一个脚本
        }
    }
}
</script>

<style lang="less" scoped>
// @import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/css/frontend.min.css?ver=3.27.6';
@import "./css/frontend.min.css";
// @import 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-5.css?ver=1734676771';
@import "./css/post-5.css";
// @import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor-pro/assets/css/conditionals/popup.min.css?ver=3.27.4';
@import "./css/popup.min.css";
// @import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/lib/animations/styles/fadeInLeft.min.css?ver=3.27.6';
@import "./css/fadeInLeft.min.css";
// @import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/lib/swiper/v8/css/swiper.min.css?ver=8.4.5';
@import "./css/swiper.min.css";
// @import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/css/widget-divider.min.css?ver=3.27.6';
@import "./css/widget-divider.min.css";
// @import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/css/widget-spacer.min.css?ver=3.27.6';
@import "./css/widget-spacer.min.css";
// @import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/lib/animations/styles/e-animation-grow.min.css?ver=3.27.6';
@import "./css/e-animation-grow.min.css";
// @import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/lib/animations/styles/fadeInUp.min.css?ver=3.27.6';
@import "./css/fadeInUp.min.css";
// @import 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-349.css?ver=1734676933';
@import "./css/post-349.css";

#app {
    position: relative;

    .elementor-34147 {
        width: 96%;
        height:96%;
        position: fixed;
        z-index:99999999999;
        top: 50%;
        left:50%;
        transform: translate(-50%,-50%);
        background-image: url("https://www.pgsoftgaming.com/wp-content/uploads/AnyConv.com__865ea4b5-6203-43ee-8977-aeafae4fb071.webp");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 16px 16px 16px 16px;
        overflow: auto;
        background-color: #230046;
        border-radius: 16px 16px 16px 16px;
        box-shadow: 2px 8px 23px 3px rgba(0, 0, 0, 0.2);
        padding: 8px;
        box-sizing: border-box;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .elementor-34153 {
        width: 96%;
        height:96%;
        position: fixed;
        z-index:99999999999;
        top: 50%;
        left:50%;
        transform: translate(-50%,-50%);
        background-image: url(https://www.pgsoftgaming.com/wp-content/uploads/AnyConv.com__108a9e1a-c617-4427-bf2b-fc0f9bb05ab4.webp);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 16px 16px 16px 16px;
        overflow: auto;
        background-color: #230046;
        border-radius: 16px 16px 16px 16px;
        box-shadow: 2px 8px 23px 3px rgba(0, 0, 0, 0.2);
        padding: 8px;
        box-sizing: border-box;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .elementor-34170 {
        width: 96%;
        height:96%;
        position: fixed;
        z-index:99999999999;
        top: 50%;
        left:50%;
        transform: translate(-50%,-50%);
        background-image: url(../assets/xboxOne/3e155d40-496e-4980-aa7f-79b66547b669.jpg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 16px 16px 16px 16px;
        overflow: auto;
        background-color: #230046;
        border-radius: 16px 16px 16px 16px;
        box-shadow: 2px 8px 23px 3px rgba(0, 0, 0, 0.2);
        padding: 8px;
        box-sizing: border-box;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .elementor-34176 {
        width: 96%;
        height:96%;
        position: fixed;
        z-index:99999999999;
        top: 50%;
        left:50%;
        transform: translate(-50%,-50%);
        background-image: url(https://www.pgsoftgaming.com/wp-content/uploads/AnyConv.com__9904a979-8bdc-4809-ba53-e85efb14bcf8.webp);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 16px 16px 16px 16px;
        overflow: auto;
        background-color: #230046;
        border-radius: 16px 16px 16px 16px;
        box-shadow: 2px 8px 23px 3px rgba(0, 0, 0, 0.2);
        padding: 8px;
        box-sizing: border-box;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .elementor-34209 {
        width: 96%;
        height:96%;
        position: fixed;
        z-index:99999999999;
        top: 50%;
        left:50%;
        transform: translate(-50%,-50%);
        background-image: url(https://www.pgsoftgaming.com/wp-content/uploads/AnyConv.com__eb3ea1ab-e8a6-48f0-95a5-b66790dfb7a8.webp);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 16px 16px 16px 16px;
        overflow: auto;
        background-color: #230046;
        border-radius: 16px 16px 16px 16px;
        box-shadow: 2px 8px 23px 3px rgba(0, 0, 0, 0.2);
        padding: 8px;
        box-sizing: border-box;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .elementor-34216 {
        width: 96%;
        height:96%;
        position: fixed;
        z-index:99999999999;
        top: 50%;
        left:50%;
        transform: translate(-50%,-50%);
        background-image: url(https://www.pgsoftgaming.com/wp-content/uploads/AnyConv.com__c77fd90a-1957-4eeb-8209-d5f83bcff154.webp);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 16px 16px 16px 16px;
        overflow: auto;
        background-color: #230046;
        border-radius: 16px 16px 16px 16px;
        box-shadow: 2px 8px 23px 3px rgba(0, 0, 0, 0.2);
        padding: 8px;
        box-sizing: border-box;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .elementor-34233 {
        width: 96%;
        height:96%;
        position: fixed;
        z-index:99999999999;
        top: 50%;
        left:50%;
        transform: translate(-50%,-50%);
        background-image: url(https://www.pgsoftgaming.com/wp-content/uploads/少林足球.jpg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 16px 16px 16px 16px;
        overflow: auto;
        background-color: #230046;
        border-radius: 16px 16px 16px 16px;
        box-shadow: 2px 8px 23px 3px rgba(0, 0, 0, 0.2);
        padding: 8px;
        box-sizing: border-box;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .elementor-34023 {
        width: 96%;
        height:96%;
        position: fixed;
        z-index:99999999999;
        top: 50%;
        left:50%;
        transform: translate(-50%,-50%);
        background-image: url(https://www.pgsoftgaming.com/wp-content/uploads/17b2663d-ff89-4dad-bfe6-26ce0b1de7df.jpg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 16px 16px 16px 16px;
        overflow: auto;
        background-color: #230046;
        border-radius: 16px 16px 16px 16px;
        box-shadow: 2px 8px 23px 3px rgba(0, 0, 0, 0.2);
        padding: 8px;
        box-sizing: border-box;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .elementor-34033 {
        width: 96%;
        height:96%;
        position: fixed;
        z-index:99999999999;
        top: 50%;
        left:50%;
        transform: translate(-50%,-50%);
        background-image: url(../assets/xboxOne/17b2663d-ff89-4dad-bfe6-26ce0b1de7df.jpg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 16px 16px 16px 16px;
        overflow: auto;
        background-color: #230046;
        border-radius: 16px 16px 16px 16px;
        box-shadow: 2px 8px 23px 3px rgba(0, 0, 0, 0.2);
        padding: 8px;
        box-sizing: border-box;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .elementor-34046 {
        width: 96%;
        height:96%;
        position: fixed;
        z-index:99999999999;
        top: 50%;
        left:50%;
        transform: translate(-50%,-50%);
        background-image: url(../assets/xboxOne/efd5f904-35d6-463a-a649-7af4408fa375.jpg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 16px 16px 16px 16px;
        overflow: auto;
        background-color: #230046;
        border-radius: 16px 16px 16px 16px;
        box-shadow: 2px 8px 23px 3px rgba(0, 0, 0, 0.2);
        padding: 8px;
        box-sizing: border-box;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .elementor-34064 {
        width: 96%;
        height:96%;
        position: fixed;
        z-index:99999999999;
        top: 50%;
        left:50%;
        transform: translate(-50%,-50%);
        background-image: url(../assets/xboxOne/69c3b427-57b9-4901-9cf1-e237603a9459.jpg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 16px 16px 16px 16px;
        overflow: auto;
        background-color: #230046;
        border-radius: 16px 16px 16px 16px;
        box-shadow: 2px 8px 23px 3px rgba(0, 0, 0, 0.2);
        padding: 8px;
        box-sizing: border-box;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .elementor-34073 {
        width: 96%;
        height:96%;
        position: fixed;
        z-index:99999999999;
        top: 50%;
        left:50%;
        transform: translate(-50%,-50%);
        background-image: url(../assets/xboxOne/AnyConv.com__a69cbb57-193f-4932-9b90-fc2374aa86d4.webp);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 16px 16px 16px 16px;
        overflow: auto;
        background-color: #230046;
        border-radius: 16px 16px 16px 16px;
        box-shadow: 2px 8px 23px 3px rgba(0, 0, 0, 0.2);
        padding: 8px;
        box-sizing: border-box;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .elementor-34078 {
        width: 96%;
        height:96%;
        position: fixed;
        z-index:99999999999;
        top: 50%;
        left:50%;
        transform: translate(-50%,-50%);
        background-image: url(../assets/xboxOne/rooster-rumble_background_landscape2.jpg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 16px 16px 16px 16px;
        overflow: auto;
        background-color: #230046;
        border-radius: 16px 16px 16px 16px;
        box-shadow: 2px 8px 23px 3px rgba(0, 0, 0, 0.2);
        padding: 8px;
        box-sizing: border-box;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .elementor-34077 {
        width: 96%;
        height:96%;
        position: fixed;
        z-index:99999999999;
        top: 50%;
        left:50%;
        transform: translate(-50%,-50%);
        background-image: url(../assets/xboxOne/831c06db-98ea-40eb-9d62-2f4df7b4ff32.jpg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 16px 16px 16px 16px;
        overflow: auto;
        background-color: #230046;
        border-radius: 16px 16px 16px 16px;
        box-shadow: 2px 8px 23px 3px rgba(0, 0, 0, 0.2);
        padding: 8px;
        box-sizing: border-box;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .elementor-33967 {
        width: 96%;
        height:96%;
        position: fixed;
        z-index:99999999999;
        top: 50%;
        left:50%;
        transform: translate(-50%,-50%);
        background-image: url(../assets/xboxOne/aa3aa89a-4e03-451e-9e0a-834c20bf0817.jpg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 16px 16px 16px 16px;
        overflow: auto;
        background-color: #230046;
        border-radius: 16px 16px 16px 16px;
        box-shadow: 2px 8px 23px 3px rgba(0, 0, 0, 0.2);
        padding: 8px;
        box-sizing: border-box;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .elementor-33989 {
        width: 96%;
        height:96%;
        position: fixed;
        z-index:99999999999;
        top: 50%;
        left:50%;
        transform: translate(-50%,-50%);
        background-image: url(../assets/xboxOne/240d432d-8ff2-4e1c-837d-ad976d81c743.jpg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 16px 16px 16px 16px;
        overflow: auto;
        background-color: #230046;
        border-radius: 16px 16px 16px 16px;
        box-shadow: 2px 8px 23px 3px rgba(0, 0, 0, 0.2);
        padding: 8px;
        box-sizing: border-box;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .elementor-34002 {
        width: 96%;
        height:96%;
        position: fixed;
        z-index:99999999999;
        top: 50%;
        left:50%;
        transform: translate(-50%,-50%);
        background-image: url(../assets/xboxOne/AnyConv.com__a246ab67-8fd1-457a-8269-8a389b031a8c.webp);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 16px 16px 16px 16px;
        overflow: auto;
        background-color: #230046;
        border-radius: 16px 16px 16px 16px;
        box-shadow: 2px 8px 23px 3px rgba(0, 0, 0, 0.2);
        padding: 8px;
        box-sizing: border-box;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .elementor-34012 {
        width: 96%;
        height:96%;
        position: fixed;
        z-index:99999999999;
        top: 50%;
        left:50%;
        transform: translate(-50%,-50%);
        background-image: url(../assets/xboxOne/AnyConv.com__4d65e88a-b5ad-45e3-98d2-bb1fc2aba0f0.webp);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 16px 16px 16px 16px;
        overflow: auto;
        background-color: #230046;
        border-radius: 16px 16px 16px 16px;
        box-shadow: 2px 8px 23px 3px rgba(0, 0, 0, 0.2);
        padding: 8px;
        box-sizing: border-box;
        &::-webkit-scrollbar {
            display: none;
        }
    }
}


</style>
<style>

.swiper-pointer-events.swiper-vertical {
    display: none !important;
}
@media only screen and (max-width: 768px) {
  /* 样式规则 */
  #imgBox {
    object-fit: none
  }
  #colose {
    top: 30px !important;
    right:25px !important;
  }
}
</style>
