<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2025-02-02 11:47:00
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2025-02-16 15:18:31
 * @FilePath: \sw_project\src\App.vue
 * @Description: 首页
-->
<template>
  <div id="app">
    <!-- <Index v-load-domendscripts></Index> -->
     <router-view></router-view>
  </div>
</template>

<script>
import Index from "./index/index";
export default {
  name: 'App',
  components: {
    Index
  },
  data() {
    return {
      
    }
  },
  mounted() {
  
  },
  methods: {

  }
}
</script>

<style scoped lang="less">
body {
  margin: 0 !important;
    padding:0;
}
#app {
 
}
</style>


