<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2025-02-16 15:04:06
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2025-02-16 15:34:12
 * @FilePath: \sw_project\src\brandIntroduction\brandIntroduction.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div id="app" class="page-template page-template-elementor_header_footer page page-id-1634 wp-custom-logo unselectable elementor-default elementor-template-full-width elementor-kit-5 elementor-page elementor-page-1634">
         <!-- 头部及弹窗 -->
         <Header></Header>
        <div data-elementor-type="wp-page" data-elementor-id="1634" class="elementor elementor-1634" data-elementor-post-type="page">
            <section class="elementor-section elementor-top-section elementor-element elementor-element-72d22f03 elementor-section-full_width elementor-section-height-min-height elementor-section-height-default elementor-section-items-middle" data-id="72d22f03" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;background_motion_fx_opacity_effect&quot;:&quot;yes&quot;,&quot;background_motion_fx_opacity_range&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:{&quot;start&quot;:&quot;51&quot;,&quot;end&quot;:&quot;76&quot;}},&quot;background_motion_fx_motion_fx_scrolling&quot;:&quot;yes&quot;,&quot;background_motion_fx_opacity_direction&quot;:&quot;in-out&quot;,&quot;background_motion_fx_scale_effect&quot;:&quot;yes&quot;,&quot;background_motion_fx_scale_direction&quot;:&quot;in-out&quot;,&quot;background_motion_fx_scale_speed&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:2.5,&quot;sizes&quot;:[]},&quot;animation&quot;:&quot;none&quot;,&quot;background_motion_fx_opacity_level&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:10,&quot;sizes&quot;:[]},&quot;background_motion_fx_scale_range&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:{&quot;start&quot;:20,&quot;end&quot;:80}},&quot;background_motion_fx_devices&quot;:[&quot;desktop&quot;,&quot;tablet&quot;,&quot;mobile&quot;]}">
                <div class="elementor-background-overlay"></div>
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-36467e0" data-id="36467e0" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-3a6d989 elementor-invisible elementor-widget elementor-widget-heading" data-id="3a6d989" data-element_type="widget" data-settings="{&quot;motion_fx_motion_fx_scrolling&quot;:&quot;yes&quot;,&quot;motion_fx_translateY_effect&quot;:&quot;yes&quot;,&quot;motion_fx_translateY_speed&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;0.5&quot;,&quot;sizes&quot;:[]},&quot;motion_fx_translateY_affectedRange&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:{&quot;start&quot;:&quot;0&quot;,&quot;end&quot;:&quot;50&quot;}},&quot;motion_fx_translateY_direction&quot;:&quot;negative&quot;,&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;motion_fx_devices&quot;:[&quot;desktop&quot;,&quot;tablet&quot;,&quot;mobile&quot;]}" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h2 class="elementor-heading-title elementor-size-default">Pocket Games Soft</h2>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-5e9356a elementor-invisible elementor-widget elementor-widget-heading" data-id="5e9356a" data-element_type="widget" data-settings="{&quot;motion_fx_motion_fx_scrolling&quot;:&quot;yes&quot;,&quot;motion_fx_opacity_effect&quot;:&quot;yes&quot;,&quot;motion_fx_opacity_range&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:{&quot;start&quot;:&quot;0&quot;,&quot;end&quot;:&quot;50&quot;}},&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;_animation_delay&quot;:200,&quot;motion_fx_opacity_direction&quot;:&quot;out-in&quot;,&quot;motion_fx_opacity_level&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:10,&quot;sizes&quot;:[]},&quot;motion_fx_devices&quot;:[&quot;desktop&quot;,&quot;tablet&quot;,&quot;mobile&quot;]}" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h4 class="elementor-heading-title elementor-size-default">DIFFERENCE MAKES
                </br>THE DIFFERENCE</h4></div></div></div></div></div>
            </section>
            <section class="elementor-section elementor-top-section elementor-element elementor-element-32006374 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-invisible" data-id="32006374" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:400}">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-159c8b45" data-id="159c8b45" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-174b615 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="174b615" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-536cada5" data-id="536cada5" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-3327e35f elementor-widget elementor-widget-counter" data-id="3327e35f" data-element_type="widget" data-widget_type="counter.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-counter">
                                                        <div class="elementor-counter-title">游戏</div>
                                                        <div class="elementor-counter-number-wrapper">
                                                            <span class="elementor-counter-number-prefix">+</span>
                                                            <span class="elementor-counter-number" data-duration="2000" data-to-value="1007" data-from-value="0" data-delimiter=",">0</span>
                                                            <span class="elementor-counter-number-suffix"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-a284e91" data-id="a284e91" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-289f9907 elementor-widget elementor-widget-counter" data-id="289f9907" data-element_type="widget" data-widget_type="counter.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-counter">
                                                        <div class="elementor-counter-title">玩家</div>
                                                        <div class="elementor-counter-number-wrapper">
                                                            <span class="elementor-counter-number-prefix">+</span>
                                                            <span class="elementor-counter-number" data-duration="2000" data-to-value="6030" data-from-value="0" data-delimiter=",">0</span>
                                                            <span class="elementor-counter-number-suffix"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-73872c92" data-id="73872c92" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-35b51204 elementor-widget elementor-widget-counter" data-id="35b51204" data-element_type="widget" data-widget_type="counter.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-counter">
                                                        <div class="elementor-counter-title">团队</div>
                                                        <div class="elementor-counter-number-wrapper">
                                                            <span class="elementor-counter-number-prefix"></span>
                                                            <span class="elementor-counter-number" data-duration="2000" data-to-value="74" data-from-value="0" data-delimiter=",">0</span>
                                                            <span class="elementor-counter-number-suffix"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-3a34e7ba" data-id="3a34e7ba" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-aa9057e elementor-widget elementor-widget-counter" data-id="aa9057e" data-element_type="widget" data-widget_type="counter.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-counter">
                                                        <div class="elementor-counter-title">爆率</div>
                                                        <div class="elementor-counter-number-wrapper">
                                                            <span class="elementor-counter-number-prefix"></span>
                                                            <span class="elementor-counter-number" data-duration="2000" data-to-value="97.38" data-from-value="0" data-delimiter=",">0</span>
                                                            <span class="elementor-counter-number-suffix">%</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <section class="elementor-section elementor-top-section elementor-element elementor-element-51ee4e25 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="51ee4e25" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-f6a1fa4 elementor-invisible" data-id="f6a1fa4" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;fadeInLeft&quot;}">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-c9ed7b elementor-widget elementor-widget-heading" data-id="c9ed7b" data-element_type="widget" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h2 class="elementor-heading-title elementor-size-default">引领亚洲市场的先驱</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-31fa0321 elementor-invisible" data-id="31fa0321" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;fadeInLeft&quot;,&quot;animation_delay&quot;:200}">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-478d60d7 elementor-widget elementor-widget-text-editor" data-id="478d60d7" data-element_type="widget" data-widget_type="text-editor.default">
                                <div class="elementor-widget-container">
                                    <p>Pocket Games Soft凭借着扣人心弦的游戏成为推动亚洲市场的先驱。我们致力于提供各种新颖和多元化的电子游戏，并以制作精品游戏做为最主要的研发宗旨。</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="elementor-section elementor-top-section elementor-element elementor-element-67b60ba2 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="67b60ba2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-48a0f8e" data-id="48a0f8e" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-2c8d7c2d elementor-invisible elementor-widget elementor-widget-heading" data-id="2c8d7c2d" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h2 class="elementor-heading-title elementor-size-default">關於 PG SOFT™</h2>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-5905f07a elementor-invisible elementor-widget elementor-widget-text-editor" data-id="5905f07a" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;_animation_delay&quot;:200}" data-widget_type="text-editor.default">
                                <div class="elementor-widget-container">
                                    <p>PG SOFT™ 是一家位於馬耳他瓦萊塔的手機遊戲開發公司。公司成立於2015年，目前已發展成一支擁有200名員工的強大團隊，並在歐洲、北美和亞洲設有辦公室。結合創始人的豐富專業知識，以及由獲獎藝術家和創作者組成的團隊，PG SOFT™ 以前所未有的遊戲玩法和驚人的圖形讓人們感到驚豔。他們在2017年倫敦ICE Totally Gaming展會上的首次亮相大獲成功。儘管是新進者，PG SOFT™ 已在視頻老虎機行業中留下了顯著印象。遊戲將於2017年第四季度正式發布。</p>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-06384c6 elementor-invisible elementor-widget elementor-widget-heading" data-id="06384c6" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;_animation_delay&quot;:400}" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h2 class="elementor-heading-title elementor-size-default">關於 PG 團隊</h2>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-5d46ca2 elementor-invisible elementor-widget elementor-widget-text-editor" data-id="5d46ca2" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;_animation_delay&quot;:400}" data-widget_type="text-editor.default">
                                <div class="elementor-widget-container">
                                    <p>團隊由經驗豐富的專業人士和業界老手組成，曾為電視和電影業的知名品牌創作備受讚譽的作品。與來自牛津和劍橋大學的數學畢業生協同工作，PG SOFT™ 也擁有一支充滿熱情、致力於不斷突破的年輕創作者團隊。PG SOFT™ 重視創意、創新和人才，每一款產品都是真正體現整個團隊能力的成果。</p>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-c14bb53 elementor-invisible elementor-widget elementor-widget-heading" data-id="c14bb53" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;_animation_delay&quot;:400}" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h2 class="elementor-heading-title elementor-size-default">PG SOFT™ 願景</h2>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-6071c9f elementor-invisible elementor-widget elementor-widget-text-editor" data-id="6071c9f" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;_animation_delay&quot;:400}" data-widget_type="text-editor.default">
                                <div class="elementor-widget-container">
                                    <p>PG SOFT™ 的目標是通過我們多樣化的專屬遊戲解決方案，徹底改變移動應用程序的遊戲化。我們的遊戲以令人驚嘆的美學和引人入勝的遊戲玩法為代表，為所有手機老虎機玩家帶來無與倫比的遊戲娛樂。</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="elementor-section elementor-top-section elementor-element elementor-element-5f4d7329 elementor-section-height-min-height elementor-section-items-top elementor-section-boxed elementor-section-height-default" data-id="5f4d7329" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-background-overlay"></div>
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2892d83" data-id="2892d83" data-element_type="column">
                        <div class="elementor-widget-wrap"></div>
                    </div>
                </div>
            </section>
            <section class="elementor-section elementor-top-section elementor-element elementor-element-62d55d4 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="62d55d4" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e0a6b62" data-id="e0a6b62" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-118343d elementor-invisible elementor-widget elementor-widget-heading" data-id="118343d" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h2 class="elementor-heading-title elementor-size-default">遊戲設計</h2>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-2bae307 elementor-invisible elementor-widget elementor-widget-heading" data-id="2bae307" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;_animation_delay&quot;:200}" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h4 class="elementor-heading-title elementor-size-default">重新定義規則</h4>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-c34e7a7 elementor-invisible elementor-widget elementor-widget-text-editor" data-id="c34e7a7" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;_animation_delay&quot;:400}" data-widget_type="text-editor.default">
                                <div class="elementor-widget-container">
                                    <p>我們的遊戲設計師團隊通過編織創新的3D遊戲玩法和AAA級別的藝術作品，打破了虛擬世界的邊界，成為我們遊戲成功的主要推動力。精心設計，他們帶領玩家進入充滿刺激的迷宮階段。結合想像力豐富的宇宙、遊戲動態和前所未有的遊戲玩法，我們承諾為玩家帶來令人振奮的動作場面。</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="elementor-section elementor-top-section elementor-element elementor-element-3cebc049 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle" data-id="3cebc049" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-background-overlay"></div>
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b448ff7" data-id="b448ff7" data-element_type="column">
                        <div class="elementor-widget-wrap"></div>
                    </div>
                </div>
            </section>
            <section class="elementor-section elementor-top-section elementor-element elementor-element-553500f elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="553500f" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-368f208" data-id="368f208" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-7839a63 elementor-invisible elementor-widget elementor-widget-heading" data-id="7839a63" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h2 class="elementor-heading-title elementor-size-default">藝術</h2>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-642cb65 elementor-invisible elementor-widget elementor-widget-heading" data-id="642cb65" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;_animation_delay&quot;:200}" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h4 class="elementor-heading-title elementor-size-default">我們的崛起時刻</h4>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-6a6af4e elementor-invisible elementor-widget elementor-widget-text-editor" data-id="6a6af4e" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;_animation_delay&quot;:400}" data-widget_type="text-editor.default">
                                <div class="elementor-widget-container">
                                    <p>PG SOFT™ 是一家獲獎的國際設計師、藝術家、動畫師和製作人工作室，為視頻遊戲、電影、電視、廣告和在線娛樂領域的知名品牌創作內容。我們是一群充滿動力和目標導向的個人，對我們的工藝充滿熱情。本質上，我們想像我們自己會喜愛的遊戲，並將其實現。從一開始，PG SOFT™ 就重視創意、創新和人才，因此每一款我們創造的遊戲都是真正體現我們整個團隊能力的作品。回顧來看，這是我們成為世界級工作室的起點。</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="elementor-section elementor-top-section elementor-element elementor-element-2acf975 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle" data-id="2acf975" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-background-overlay"></div>
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4ee496b" data-id="4ee496b" data-element_type="column">
                        <div class="elementor-widget-wrap"></div>
                    </div>
                </div>
            </section>
            <section class="elementor-section elementor-top-section elementor-element elementor-element-250f87c elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="250f87c" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-866e859" data-id="866e859" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-cf1e82d elementor-invisible elementor-widget elementor-widget-heading" data-id="cf1e82d" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h2 class="elementor-heading-title elementor-size-default">技術​</h2>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-021f469 elementor-invisible elementor-widget elementor-widget-heading" data-id="021f469" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;_animation_delay&quot;:200}" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h4 class="elementor-heading-title elementor-size-default">探索數位領域的新前沿​</h4>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-2c408dc elementor-invisible elementor-widget elementor-widget-text-editor" data-id="2c408dc" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;_animation_delay&quot;:400}" data-widget_type="text-editor.default">
                                <div class="elementor-widget-container">
                                    <p>在 PG SOFT™，協作的遠見者將不可能變為現實。他們解決技術上的難題，並深入研究協調數百萬行代碼的艱鉅任務。我們的開發團隊不斷追求創造高標準的遊戲內容。他們重寫算法規則並運行多次迭代，旨在提供快速、不間斷且身臨其境的電影級遊戲事件。這些努力讓玩家能夠在瀏覽器、iOS、Android、MacOS 和 Windows 設備上，在同一個廣闊的遊戲世界中競技。</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="elementor-section elementor-top-section elementor-element elementor-element-28efeff elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle" data-id="28efeff" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-background-overlay"></div>
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2ed0d99" data-id="2ed0d99" data-element_type="column">
                        <div class="elementor-widget-wrap"></div>
                    </div>
                </div>
            </section>
            <section class="elementor-section elementor-top-section elementor-element elementor-element-dc522b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="dc522b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-27e17ac" data-id="27e17ac" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-e5646a3 elementor-invisible elementor-widget elementor-widget-heading" data-id="e5646a3" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h2 class="elementor-heading-title elementor-size-default">遊戲音樂</h2>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-75b7d16 elementor-invisible elementor-widget elementor-widget-heading" data-id="75b7d16" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;_animation_delay&quot;:200}" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h4 class="elementor-heading-title elementor-size-default">為無與倫比的遊戲體驗精心策劃聲音交響曲</h4>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-8bfa263 elementor-invisible elementor-widget elementor-widget-text-editor" data-id="8bfa263" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;_animation_delay&quot;:400}" data-widget_type="text-editor.default">
                                <div class="elementor-widget-container">
                                    <p>我們擁有一支專門的聲學工程師、音效設計師和一小群音樂製作人，致力於打造與遊戲玩法和藝術風格協同工作的多元音樂組合。我們深知，吸引人的遊戲體驗與動態音效密不可分。隨著玩家在遊戲空間中的探索，精確調整的音頻頻率隨著等級的變化、脈動和擴展，確保了真正令人滿意的遊戲體驗。</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
         <!-- 底部 -->
         <Footer></Footer>
    </div>
</template>

<script>
import Footer from "../components/footer.vue";
import Header from "../components/header.vue";

var elementorFrontendConfig = {
                "environmentMode": {
                    "edit": false,
                    "wpPreview": false,
                    "isScriptDebug": false
                },
                "i18n": {
                    "shareOnFacebook": "\u5728\u8138\u4e66\u4e0a\u5206\u4eab",
                    "shareOnTwitter": "\u5206\u4eab\u5230Twitter",
                    "pinIt": "\u9489\u4f4f",
                    "download": "\u4e0b\u8f7d",
                    "downloadImage": "\u4e0b\u8f7d\u56fe\u7247",
                    "fullscreen": "\u5168\u5c4f",
                    "zoom": "\u7f29\u653e",
                    "share": "\u5206\u4eab",
                    "playVideo": "\u64ad\u653e\u89c6\u9891",
                    "previous": "\u4e0a\u4e00\u9875",
                    "next": "\u4e0b\u4e00\u9875",
                    "close": "\u5173\u95ed",
                    "a11yCarouselPrevSlideMessage": "\u4e0a\u4e00\u5f20\u5e7b\u706f\u7247",
                    "a11yCarouselNextSlideMessage": "\u4e0b\u4e00\u5f20\u5e7b\u706f\u7247",
                    "a11yCarouselFirstSlideMessage": "\u8fd9\u662f\u7b2c\u4e00\u5f20\u5e7b\u706f\u7247",
                    "a11yCarouselLastSlideMessage": "\u8fd9\u662f\u6700\u540e\u4e00\u5f20\u5e7b\u706f\u7247",
                    "a11yCarouselPaginationBulletMessage": "\u8f6c\u5230\u5e7b\u706f\u7247"
                },
                "is_rtl": false,
                "breakpoints": {
                    "xs": 0,
                    "sm": 480,
                    "md": 768,
                    "lg": 1025,
                    "xl": 1440,
                    "xxl": 1600
                },
                "responsive": {
                    "breakpoints": {
                        "mobile": {
                            "label": "\u624b\u673a\u7eb5\u5411",
                            "value": 767,
                            "default_value": 767,
                            "direction": "max",
                            "is_enabled": true
                        },
                        "mobile_extra": {
                            "label": "\u624b\u673a\u6a2a\u5411",
                            "value": 880,
                            "default_value": 880,
                            "direction": "max",
                            "is_enabled": false
                        },
                        "tablet": {
                            "label": "\u5e73\u677f\u7535\u8111\u7eb5\u5411",
                            "value": 1024,
                            "default_value": 1024,
                            "direction": "max",
                            "is_enabled": true
                        },
                        "tablet_extra": {
                            "label": "\u5e73\u677f\u7535\u8111\u6a2a\u5411",
                            "value": 1200,
                            "default_value": 1200,
                            "direction": "max",
                            "is_enabled": false
                        },
                        "laptop": {
                            "label": "\u7b14\u8bb0\u672c\u7535\u8111",
                            "value": 1366,
                            "default_value": 1366,
                            "direction": "max",
                            "is_enabled": false
                        },
                        "widescreen": {
                            "label": "\u5168\u5bbd\u5c4f",
                            "value": 2400,
                            "default_value": 2400,
                            "direction": "min",
                            "is_enabled": false
                        }
                    },
                    "hasCustomBreakpoints": false
                },
                "version": "3.27.4",
                "is_static": false,
                "experimentalFeatures": {
                    "additional_custom_breakpoints": true,
                    "e_swiper_latest": true,
                    "e_onboarding": true,
                    "theme_builder_v2": true,
                    "hello-theme-header-footer": true,
                    "home_screen": true,
                    "landing-pages": true,
                    "editor_v2": true,
                    "link-in-bio": true,
                    "floating-buttons": true
                },
                "urls": {
                    "assets": "https:\/\/www.pgsoftgaming.com\/wp-content\/plugins\/elementor\/assets\/",
                    "ajaxurl": "https:\/\/www.pgsoftgaming.com\/wp-admin\/admin-ajax.php",
                    "uploadUrl": "http:\/\/www.pgsoftgaming.com\/wp-content\/uploads"
                },
                "nonces": {
                    "floatingButtonsClickTracking": "e96c9ccae6"
                },
                "swiperClass": "swiper",
                "settings": {
                    "page": [],
                    "editorPreferences": []
                },
                "kit": {
                    "body_background_background": "classic",
                    "active_breakpoints": ["viewport_mobile", "viewport_tablet"],
                    "global_image_lightbox": "yes",
                    "lightbox_enable_counter": "yes",
                    "lightbox_enable_fullscreen": "yes",
                    "lightbox_enable_zoom": "yes",
                    "lightbox_enable_share": "yes",
                    "lightbox_title_src": "title",
                    "lightbox_description_src": "description",
                    "hello_header_logo_type": "logo",
                    "hello_header_menu_layout": "horizontal",
                    "hello_footer_logo_type": "logo"
                },
                "post": {
                    "id": 1634,
                    "title": "%E5%93%81%E7%89%8C%E4%BB%8B%E7%BB%8D%20%7C%20PG%E7%94%B5%E5%AD%90%E5%AE%98%E6%96%B9%E7%BD%91%E7%AB%99",
                    "excerpt": "",
                    "featuredImage": false
                }
            };
        
export default {
    components: {
        Footer,
        Header
    },
    data() {
        return {
            
        }
    },
    mounted() {
        const scripts = [
            'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/lib/jquery-numerator/jquery-numerator.min.js?ver=0.2.1',
            "https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/js/webpack.runtime.min.js?ver=3.27.4",
            "https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/js/frontend-modules.min.js?ver=3.27.4",
            "https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/js/frontend.min.js?ver=3.27.4",
           
        ];
        this.loadScripts(scripts, () => {
            console.log('所有脚本加载完成');
            this.loadCSS('https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-33905.css?ver=1736761290',false)
        });

    },
    methods:{
        loadCSS(url, isCache) {
            const element = document.createElement("link");
            element.setAttribute("rel", "stylesheet");
            element.setAttribute("type", "text/css");
            if (isCache) {
            element.setAttribute("href", url + "?t=" + new Date().getTime());
            } else {
            element.setAttribute("href", url);
            }
            document.head.appendChild(element);
        },
        loadScripts(scripts, callback) {
            const loadNext = (index) => {
            if (index >= scripts.length) {
                callback(); // 所有脚本加载完成后执行回调
                return;
            }
            const script = document.createElement('script');
            script.src = scripts[index];
            script.onload = () => loadNext(index + 1); // 加载下一个脚本
            script.onerror = () => console.error(`Failed to load script: ${scripts[index]}`);
            document.body.appendChild(script);
            };
            loadNext(0); // 开始加载第一个脚本
        }
    }
}
</script>

<style lang="less" scoped>
@import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/css/frontend.min.css?ver=3.27.4';
@import 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-5.css?ver=1734676771';
@import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/lib/animations/styles/fadeInUp.min.css?ver=3.27.4';
@import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/css/widget-counter.min.css?ver=3.27.4';
@import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/lib/animations/styles/fadeInLeft.min.css?ver=3.27.4';
@import 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-1634.css?ver=1734678540';
@import 'https://fonts.googleapis.com/css?family=Noto+Sans+SC%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CRacing+Sans+One%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CMontserrat%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CRoboto%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic&#038;display=auto&#038;ver=6.7.2';
</style>