/*
 * @Author: 亦云 yiyundev@163.com
 * @Date: 2025-02-06 09:24:08
 * @LastEditTime: 2025-02-25 20:11:02
 * @FilePath: \sw_project\src\main.js
 * @Description: 
 */
/* eslint-disable */
import Vue from 'vue';
import App from './App.vue';
import '@fortawesome/fontawesome-free/css/all.css';
import Router from '@/router/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

Vue.config.productionTip = false;

Vue.prototype.$bus = new Vue();



// 首页预加载jqueryjs文件
// Vue.directive('load-scripts', {
//   bind(el, binding, vnode) {
//     const scriptUrls = [
//       'https://www.pgsoftgaming.com/wp-includes/js/jquery/jquery.min.js?ver=3.7.1',
//       'https://www.pgsoftgaming.com/wp-includes/js/jquery/jquery-migrate.min.js?ver=3.4.1'
//     ];

//     function loadScript(url) {
//       return new Promise((resolve, reject) => {
//         const script = document.createElement('script');
//         script.src = url;
//         script.onload = () => resolve();
//         script.onerror = () => reject(new Error(`Failed to load script ${url}`));
//         document.head.appendChild(script);
//       });
//     }

//     async function loadAllScripts() {
//       try {
//         await Promise.all(scriptUrls.map(loadScript));
//         console.log('All scripts have been successfully loaded.');
//       } catch (error) {
//         console.error(error.message);
//       }
//     }

//     // Load scripts when the element is bound
//     loadAllScripts();
//   }
// });


// index jsdom加载完成
Vue.directive('load-domendscripts', {
  inserted(el, binding, vnode) {
    const scriptUrls = [
      'https://www.pgsoftgaming.com/wp-content/plugins/elementor-pro/assets/js/webpack-pro.runtime.min.js?ver=3.27.3',
      'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/js/webpack.runtime.min.js?ver=3.27.3',
      'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/js/frontend-modules.min.js?ver=3.27.3',
      'https://www.pgsoftgaming.com/wp-content/plugins/elementor-pro/assets/js/frontend.min.js?ver=3.27.3',
      'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/js/frontend.min.js?ver=3.27.3',
      'https://www.pgsoftgaming.com/wp-content/plugins/elementor-pro/assets/js/elements-handlers.min.js?ver=3.27.3'
    ];

    function loadScript(url) {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = url;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Failed to load script ${url}`));
        document.head.appendChild(script);
      });
    }

    async function loadAllScripts() {
      try {
        await Promise.all(scriptUrls.map(loadScript));
        console.log('All scripts have been successfully loaded.');
      } catch (error) {
        console.error(error.message);
      }
    }

    // Load scripts when the element is inserted into the DOM
    loadAllScripts();
  }
});

// Vue.directive('load-stylesheets', {
//   // 首页预加载样式文件
//   bind(el, binding, vnode) {
//     const cssUrls = [
//       'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/css/frontend.min.css?ver=3.27.3',
//       'https://www.pgsoftgaming.com/wp-content/plugins/elementor-pro/assets/css/modules/motion-fx.min.css?ver=3.27.3',
//       'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/lib/animations/styles/fadeInUp.min.css?ver=3.27.3',
//       'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/css/widget-divider.min.css?ver=3.27.3',
//       'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/css/widget-spacer.min.css?ver=3.27.3',
//       'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-33263.css?ver=1734676771',
//       'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-261.css?ver=1734676771',
//       'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-269.css?ver=1734676771',
//       'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-5.css?ver=1734676771',
//       'https://www.pgsoftgaming.com/wp-content/plugins/elementor-pro/assets/css/widget-nav-menu.min.css?ver=3.27.3',
//       'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/lib/animations/styles/slideInRight.min.css?ver=3.27.3',
//       'https://www.pgsoftgaming.com/wp-content/plugins/elementor-pro/assets/css/conditionals/popup.min.css?ver=3.27.3',
//       'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-235.css?ver=1734676771',
//       'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-33905.css?ver=1736761290'
//     ];

//     function loadStylesheet(url) {
//       return new Promise((resolve, reject) => {
//         const link = document.createElement('link');
//         link.rel = 'stylesheet';
//         link.href = url;
//         link.onload = () => resolve();
//         link.onerror = () => reject(new Error(`Failed to load stylesheet ${url}`));
//         document.head.appendChild(link);
//       });
//     }

//     async function loadAllStylesheets() {
//       try {
//         await Promise.all(cssUrls.map(loadStylesheet));
//         console.log('All stylesheets have been successfully loaded.');
//       } catch (error) {
//         console.error(error.message);
//       }
//     }

//     // Load stylesheets when the element is bound
//     loadAllStylesheets();
//   }
// });

new Vue({
  render: h => h(App),
  router:Router
}).$mount('#app')
